// В этом файле должны быть стили для БЭМ-блока ametist, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.ametist {

  $block-name:                &; // #{$block-name}__element

  a:hover, a:focus {
    color: #3A3D8A;
  }

  .header__logo {
    img {
      width: 142px;
      height: 36px;
    }
  }

  .m-header-top {
    &__clinic {
      color: #0C0C1D;
    }

    &__item {
      color: #8C8C8E;
    }
  }

  .m-header {
    .m-nav-services {
      &__title {
        color: #0C0C1D;
      }

      &__link {
        color: #2B251D;
      }   
    }

    &-burger {
      background-image: url(../img/ametist/m-burger.svg);

      &--active {
        background-image: url(../img/ametist/m-burger-active.svg);
      }
    } 
  }

  .site-panel__btn {
    &:first-child {
      background-color: #3A3D8A;
    }

    &:last-child {
      background-color: #515157;
    }
  }

  .header-clinics__item {
    color: #8C8C8E;
    &--active {
      color: #2B251D;
    }
  }

  .header-menu {
    &__link {
      color: #2B251D;

      &--active {
        color: #3A3D8A;
      }

      &--service {
        &:before {
          background-image: url(../img/ametist/burger.svg);
        }
      }
    }
  }

  .header {
    .service-menu {
      &__item {
        color: #2B251D;
        &:hover {
          color: #3A3D8A;
          &:after {
            background-color: #3A3D8A;
          }
        }
      }  
    }
    .service-submenu {
      &__link {
        color: #2B251D;

        &--sale {
          &:before {
            background-image: url(../img/ametist/i-sale.svg);
          }
        }
      }
    }  
  }

  .header-phone {
    color: #2B251D;
  }

  .header-record {
    color: #3A3D8A;

    &:before {
      background-image: url(../img/ametist/plus.svg);
    }
  }

  .page-footer {
    &__address {
      color: #515157;
    }
    &__time {
      color: #8C8C8E;
    }
    &__phone {
      color: #515157;
    }

    .footer-menu {
      &-title {
        color: #515157;

        @media (min-width: $screen-lg) {
          color: #8C8C8E;
        }
      }

      &__link {
        color: #515157;

        &:hover, &:focus {
          color: #8C8C8E;
        }
      }
    }

    .footer-copy {
      &__link {
        color: #8C8C8E;

        &:hover, &:focus {
          color: #8C8C8E;
        }
      }
      &__text {
        p {
          color: #8C8C8E;
        }
      }
    }

    .link-callback {
      color: #8C8C8E;
    }

    .footer-review {
      &__link {
        color: #515157;
      }
    }
  }

  .btn-callback {
    background-color: #3A3D8A;

    &:hover {
      background-color: #EB8128;
      color: #FFFFFF;
    }
  }

  .btn-review {
    color: #515157;
  }

  .promo-slider {
    .slick-dots {
      button {
        background-image: url(../img/ametist/dot.svg);
      }

      .slick-active {
        button {
          background-image: url(../img/ametist/dot-active.svg);
        }
      }
    }

    .promo-slide {
      &__title {
        color: #0C0C1D;
      }

      .subtitle {
        color: #493E31;
      }

      &__link {
        color: #0C0C1D;

        &:after {
          background-image: url(../img/ametist/link.svg);
        }
      }
    }
  }

  .section {
    h2, .section-title h2 {
      color: #0C0C1D;
    }

    &-header {
      h2, .section-title h2 {
        color: #0C0C1D;
      }
      &__more {
        color: #0C0C1D;

        &:after {
          background-image: url(../img/ametist/link.svg);
        }
      }
    }
    &-arrows {

      .section-arrow__prev {
        background-image: url(../img/ametist/arrow-prev.svg);
      }

      .section-arrow__next {
        background-image: url(../img/ametist/arrow-next.svg);
      }
    }
  }
  .services {
    background-color: #CDBDDB;
    .section-header h2, .ametist .section-header .section-title h2 {
      color: #FFFFFF;
    }

    @media (min-width: ($screen-lg)) {
      background-color: transparent;
    }

    &__inner {
      @media (min-width: ($screen-lg)) {
        background-color: #CDBDDB;
      }
    }
  }

  .service {
    background-color: #FAF9FB;

    &__title {
      color: #3A3D8A;
    }

    &__count {
      color: #8C8C8E;
    }

    &__link {
      color: #0C0C1D;

      &:after {
        background-image: url(../img/ametist/link.svg);
      }
    }
  }

  .text-picture {
    background-color: #fff;

    h2 {
      color: #0C0C1D;
    }

    .subtitle {
      color: #0C0C1D;

      &:before {
        background: #3A3D8A;
      }
    }

    p {
      color: #493E31;
    }

    &__link {
      color: #0C0C1D;

      &:after {
        background-image: url(../img/ametist/link.svg);
      }
    }
  }

  .text {
    background-color: #fff;

    h2 {
      color: #0C0C1D;
    }

    .subtitle {
      color: #0C0C1D;

      &:before {
        background: #3A3D8A;
      }
    }

    p {
      color: #493E31;
    }

    &__link {
      color: #0C0C1D;

      &:after {
        background-image: url(../img/ametist/link.svg);
      }
    }
  }

  .numbers {
    .number {
      &__value {
        color: #3A3D8A;
      }

      &__name {
        color: #8C8C8E;
      }
    }
  }

  .features {
    @media (min-width: $screen-lg) {
      background-image: linear-gradient(to top, $white 40%, $white 40% 40%), linear-gradient(to right,$white 40%, #CDBDDB 40% 100%);
    }

    @media (min-width: $screen-xxl) {
      background-image: linear-gradient(to top, $white 40%, $white 40% 40%), linear-gradient(to right,$white 34%, #CDBDDB 34% 100%);
    }

    h2 {
      color: #0C0C1D;
    }

    .subtitle {
      color: #0C0C1D;

      &:before {
        background-color: #3A3D8A;
      }
    }

    p {
      color: #493E31;
    }

    &__title {
      color: #3A3D8A;
    }

    &__desc {
      color: #8C8C8E;
    }

    &__item {
      background-color: #FAF9FB;
    }

    &__icon {
      background-image: url(../img/ametist/f-icon.svg);
    }

    &__column {
      &:nth-child(2) {
        background: linear-gradient(to bottom, #CDBDDB 50%, $white 50% 100%);

        @media (min-width: ($screen-md)) {
          padding-top: 38px;
          background: linear-gradient(to bottom, #CDBDDB 30%, $white 30% 100%);
        }
        @media (min-width: ($screen-lg)) {
          background: none;
        }
      }
    }
  }

  .doctor {
    &__photo {
      background-color: #FAF9FB;
    }

    &__name {
      color: #0C0C1D;
    }

    &__desc {
      color: #8C8C8E;
    }

    &__link {
      color: #0C0C1D;

      &:after {
        background-image: url(../img/ametist/link.svg);
      }
    }
  }

  .promotion {
    background-color: #CDBDDB;
    color: #fff;

    @media (min-width: $screen-lg) {
      background-image: url(../img/ametist/bg-promotion-min.svg);
    }

    @media (min-width: $screen-xl) {
      background-image: url(../img/ametist/bg-promotion-xl.svg);
    }

    @media (min-width: $screen-xxl) {
      background-image: url(../img/ametist/bg-promotion-xxl.svg);
    }

    &:hover, &:focus {
      color: #fff;
    }

    .subtitle {
      color: #FAF9FB;
    }

    &__link {
      color: #0C0C1D;

      &:after {
        background-image: url(../img/ametist/link.svg);
      }
    }
  }

  .reviews {
    .reviews-pagination {
      &__link {
        color: #8C8C8E;
      }

      &__link--active {
        color: #0C0C1D;
        border: 2px solid #3A3D8A;
      }
    }
  }

  .reviews-slider {
    .slick-dots {
      .slick-active {
        border: 2px solid #3A3D8A;
        a {
          color: #0C0C1D;
        }
      }
      li {
        a {
          color: #8C8C8E;
        }
      }
    }
  }

  .order {
    background-color: #CDBDDB;

    &__inner {
      @media (min-width: $screen-lg) {
        background-color: #CDBDDB;
      }
    }

    &__sale {
      background-color: #BEADCC;
      color: #3A3D8A;
    }

    &-form {
      &__group {
        label {
          color: #F7F7F6;
        }
      }

      &__info {
        color: #F7F7F6;
      }

      &__input-text:focus ~ label, &__input-text:valid ~ label {
        color: #F7F7F6;
        span {
          color: #F7F7F6;
        }
      }
    }
  }

  .promo {
    background-color: #CDBDDB;

    &__back {
      background-image: url(../img/ametist/back.svg);
    }

    &-doctor {
      &__inner {
      @media (min-width: $screen-xl) {
        background: linear-gradient(to right, #FBF8F4 50%, #BEADCC 50% 100%);
        }
      }
      .promo-quote {
        color: #FBF8F4;
      }
      h1 {
        color: #0C0C1D;
      }
      .subtitle {
        color: #493E31;
      }

      &__column {
        &:nth-child(1) {
          background-color: #FBF8F4;
        }
        &:nth-child(2) {
          background-color: #BEADCC;
          background-image: none;
        }
      }
    }
  }

  .prices {
    h2 {
      color: #0C0C1D;
    }

    &__title {
      color: #515157;
    }

    &__value {
      color: #515157;
      span {
        color: #8C8C8E;
      }
    }

    .tooltip {
      &__icon {
        background-image: url(../img/ametist/tooltip.svg);
      }

      &__desc {
        color: #515157;
      }
    }
  }

  .submenu {
    &__link {
      color: #0C0C1D;

      &--active {
        color: #3A3D8A;
        &:after {
          background-color: #3A3D8A;
        }
      }
    }
  }

  .breadcrumbs {
    a {
      color: #fff;
    }
    span {
      color: rgba(255, 255, 255, 0.7);
    }
    &--black span {
      color: #8C8C8E;
    }
    &--black a {
      color: #493E31;
    }
  }

  .documents {
    background-color: #fff;

    h2 {
      color: #0C0C1D;
    }

    &__desc {
      color: #493E31;
    }

    &__icon {
      background-color: #BEADCC;
    }
  }

  .slider-photo {
    &__prev {
      background-image: url(../img/ametist/left-s.svg);
    }

    &__next {
      background-image: url(../img/ametist/right-s.svg);
    }
  }

  .contact {
    &__address {
      color: #515157;
    }
    &__time {
      color: #8C8C8E;
    }
    &__email {
      color: #8C8C8E;
    }
    &__phone {
      color: #515157;
    }
    &__back {
      background-image: url(../img/ametist/back.svg);
    }
  }

  .modal__content {
    background-color: #CDBDDB;
    background-image: none;
  }

  .modal__body {
    background-color: #CDBDDB;
    background-image: none;
  }

  .text--page {
    a {
      color: #0C0C1D;
    }
    p {
      color: #515157;
    }
    ul {
      li {
        color: #515157;
      }
    }
  }
}
