// В этом файле должны быть стили для БЭМ-блока promo-slider, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.promo-slider {

  $block-name:                &; // #{$block-name}__element
  position: relative;
  background: linear-gradient(180.68deg,#e6e8e7 .96%,#e2e3e5 99.79%);
  @media (min-width: ($screen-xxl)) {
    margin: auto;
    max-width: 1520px;
  }

  .slick-dots {
    padding-left: 0;
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    bottom: 1%;
    text-align: center;

    @media (min-width: ($screen-md)) {
      bottom: 3%;
    }

    li {
      list-style: none;
      display: inline-block;
    }

    button {
      width: 16px;
      height: 16px;
      background-color: transparent;
      background-image: url(../img/dot.svg);
      background-size: 16px 16px;
      background-repeat: no-repeat;
      font-size: 0;
      border: none;
    }

    .slick-active {
      button {
        background-image: url(../img/dot-active.svg);
      }
    }
  }

  .promo-slide {
    background: linear-gradient(180.68deg, #E6E8E7 0.96%, #E2E3E5 99.79%);

    &__inner {
      padding-top: 56px;
      max-width: $container-sm;
      margin: auto;

      @media (min-width: ($screen-md)) {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        max-width: $container-md;
      }

      @media (min-width: ($screen-lg)) {
        padding-top: 0;
        margin: 0 0 0 10.3%;
        max-width: 89.7%;
      }

      @media (min-width: ($screen-xl)) {
        margin: 0 0 0 11.1%;
        max-width: 88.9%;
      }

      @media (min-width: ($screen-xxl)) {
        margin: 0 0 0 169px;
        max-width: 100%;
      }
    }

    &__title {
      font-family: $font-family--heading-sans;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 44px;
      line-height: 44px;
      color: $color-black-text;
      min-height: 90px;

      @media (min-width: ($screen-lg)) {
        margin-bottom: 24px;
        font-size: 64px;
        line-height: 60px;
        max-width: 367px; 
      }

      @media (min-width: ($screen-xl)) {
        max-width: 486px; 
      }

      @media (min-width: ($screen-xxl)) {
        font-size: 80px;
        line-height: 74px;
        max-width: 486px; 
      }
    }

    .subtitle {
      margin-bottom: 24px;
      min-height: 60px;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      color: $color-black-light-text;

      @media (min-width: ($screen-lg)) {
        margin-bottom: 32px;
        font-size: 20px;
        line-height: 26px;
        max-width: 367px; 
      }

      @media (min-width: ($screen-xl)) {
        max-width: 384px; 
      }

      @media (min-width: ($screen-xxl)) {
        max-width: 458px; 
      }
    }

    &__link {
      margin-bottom: 24px;
      display: flex;
      align-items: end;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      color: $color-black-text;
      text-decoration: none;

      @media (min-width: ($screen-md)) {
        margin-bottom: 0;
      }

      &:after {
        content: "";
        display: flex;
        width: 24px;
        height: 18px;
        margin-left: 12px;
        background-image: url(../img/link.svg);
        background-repeat: no-repeat;
        background-size: 24px 18px;
      }
    }

    img {
      margin: auto;
      display: block;

      @media (min-width: ($screen-md)) {
        margin: 0;
        width: 294px;
        height: 336px;
      }

      @media (min-width: ($screen-lg)) {
        width: 468px;
        height: 450px;
      }

      @media (min-width: ($screen-xl)) {
        width: 628px;
        height: 540px;
      }

      @media (min-width: ($screen-xxl)) {
        width: 700px;
        height: 640px;
      }
    }

    &__column {
      @media (min-width: ($screen-md)) {
        width: 50%;
      }

      &:first-child {
        @media (min-width: ($screen-md)) {
          padding-top: 80px;
          padding-bottom: 56px;
        }
        @media (min-width: ($screen-lg)) {
          padding-top: 172px;
        }
        @media (min-width: ($screen-xl)) {
          padding-top: 224px;
          padding-bottom: 82px;
        }
        @media (min-width: ($screen-xxl)) {
          padding-top: 294px;
          padding-bottom: 96px;
        }
      }

      &:last-child {
        @media (min-width: ($screen-md)) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
