// В этом файле должны быть стили для БЭМ-блока doctor, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.doctor {

  $block-name:                &; // #{$block-name}__element
  margin-bottom: 24px;
  text-decoration: none;
  width: 206px;
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md) {
    margin-right: 24px;
    width: 216px; 
  }

  @media (min-width: $screen-lg) {
    width: 212px; 
  }

  @media (min-width: $screen-xl) {
    width: 282px; 
  }

  @media (min-width: $screen-xxl) {
    width: 338px; 
  }

  &-inner {
    flex-direction: column;

    @media (min-width: $screen-md) {
      flex-direction: row;
      max-width: 604px;
    }

    @media (min-width: $screen-lg) {
      padding-left: 0;
      max-width: 875px;
    }

    @media (min-width: $screen-xl) {
      max-width: 1098px;
    }

    @media (min-width: $screen-xxl) {
      margin: 0;
      max-width: 1314px;
    }
  }
  &__photo {
    margin-bottom: 20px;
    background-color: $color-khaki-light;
    background-image: url(../img/bg-doc.png);
    background-size: 85px 143px;
    background-repeat: no-repeat;
    background-position: 100% 0;
  }

  &__name {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $color-black-text;
  }

  &__desc {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: $color-gray-text;
  }

  &__body {
    max-width: 180px;

    @media (min-width: $screen-md) {
      max-width: 200px;
    }

    @media (min-width: $screen-xl) {
      max-width: 236px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 242px;
    }
  }

  &__link {
    display: flex;
    align-items: end;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: $color-black-text;
    text-decoration: none;

    &:after {
      content: "";
      display: flex;
      width: 24px;
      height: 18px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px;
    }
  }

  img {
    display: block;
    max-width: 134px;
    max-height: 184px;

    @media (min-width: $screen-md) {
      max-width: 140px;
      max-height: 193px;
    }

    @media (min-width: $screen-lg) {
      max-width: 138px;
      max-height: 189px;
    }

    @media (min-width: $screen-xl) {
      max-width: 180px;
      max-height: 252px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 216px;
      max-height: 302px;
    }
  }
}

.doctors {
  @media (min-width: $screen-xxl) {
    max-width: 1520px;
    padding-left: 169px;
    margin: auto;
  }
}