.a-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  text-decoration: none;
}

.btn {
  &-callback {
    background-color: $color-red;
    color: $white;
    cursor: pointer;

    &:hover, &:focus {
      color: $white;
      background-color: #EB8128;
    }
  }

  &-review {
    border: 1px solid #8C8C8E;
    background-color: transparent;
    color: $color-black-light-text;

    &:hover, &:focus {
      color: $white;
      background-color: #EB8128;
    }    
  }
}