// В этом файле должны быть стили для БЭМ-блока modal, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.modal {

  $block-name:                &; // #{$block-name}__element

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  &__dialog {
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    max-width: 480px;
    min-height: calc(100% - 4em); // центровка, удвоенный margin, указанный чуть ниже
    margin: 2em auto;
    pointer-events: none;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 40px;
    pointer-events: auto;
    background-color: $color-khaki-dark;
    background-image: linear-gradient(93.17deg, rgba(196, 176, 151, 0.63) 0%, rgba(223, 211, 197, 0.63) 134.99%);
    outline: 0;
  }

  &__header {
    position: relative;
    flex-shrink: 0;
    padding: 16px 40px 16px 16px;
    border-bottom: 1px solid $gray-lighten;
  }

  h2 {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;
    color: $white;

    @media (min-width: ($screen-lg)) {
      margin-bottom: 40px;
      max-width: 367px;
      font-size: 52px;
      line-height: 1;
    }

    @media (min-width: ($screen-xl)) {
      max-width: 487px;
    }

    @media (min-width: ($screen-xxl)) {
      margin-bottom: 48px;
      max-width: 580px;
      font-size: 64px;
      line-height: 1;
    }
  }

  &__info {
    font-size: 18px;
    line-height: 26px;
    color: #F7F7F6;
  }

  &__title {
    margin: 0;
    font-size: $font-size-h4;
    font-weight: 400;
  }

  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
    outline: 0;
  }

  &__body {
    position: relative;
    flex: 1 1 auto;
    padding: 24px;
    background-color: $color-khaki-dark;
    background-image: linear-gradient(93.17deg, rgba(196, 176, 151, 0.63) 0%, rgba(223, 211, 197, 0.63) 134.99%);

  }

  .order-form__inner {
    @media (min-width: $screen-xl) {
      display: block;
    }
  }

  .order-form__group {
    @media (min-width: $screen-xl) {
      margin-bottom: 32px;
      margin-right: 0;
    }
  }

  .order-form__column {
    @media (min-width: $screen-xl) {
      width: 100%;
    }

    @media (min-width: $screen-xxl) {
      width: 100%;
    }
  }

  &-search {
    .order-form__inner {
      @media (min-width: $screen-xl) {
        display: flex;
      }
    }

    .order-form__group {
      @media (min-width: $screen-xl) {
        margin-bottom: 0;
        margin-right: 24px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid $gray-lighten;

    > * {
      margin-left: 8px;
    }
  }

  &--show {

    #{$block-name}__dialog {
      animation: modal-dialog 0.3s 1 ease-out forwards;
    }
  }
}



.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}



.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  animation: modal-backdrop 0.3s 1 ease-out forwards;
}



@keyframes modal-backdrop {

  from {
    opacity: 0;
  }

  to {
    opacity: 0.6;
  }
}

@keyframes modal-dialog {

  from {
    transform: translate(0, -2em);
  }

  to {
    transform: translate(0, 0);
  }
}
