// В этом файле должны быть стили для БЭМ-блока header, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.header {

  $block-name:                &; // #{$block-name}__element
  display: none;
  position: fixed;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  z-index: 10;
  background-color: $white;
  box-shadow: 13px 12px 40px rgba(0, 0, 0, 0.07);

  @media (min-width: $screen-lg) {
    display: block;
  }

  &__inner {
    justify-content: space-between;
    align-items: flex-end;
  }

  &__logo {
    img {
      width: 119px;
      height: 36px;
    }
  }

  &__column {
    &:nth-child(1) {
      width: 14.8%;
      margin-right: 2.3%;

      @media (min-width: $screen-xl) {
        width: 10.3%;
        margin-right: 3.5%;
      }

      @media (min-width: $screen-xxl) {
        width: 220px;
        margin-right: 0;
      }
    }
    &:nth-child(2) {
      width: 59.6%;
      margin-right: 5%;

      @media (min-width: $screen-xl) {
        width: 48.3%;
        margin-right: 0;
      }

      @media (min-width: $screen-xxl) {
        width: 726px;
        margin-right: 24px;
      }
    }
    &:nth-child(3) {
      width: 18.1%;
      @media (min-width: $screen-xl) {
        width: 36%;
      }

      @media (min-width: $screen-xxl) {
        width: 454px;
      }
    }
  }

  &-clinics {
    display: flex;
    margin-bottom: 4px;

    &__item {
      margin-right: 12px;
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $color-gray-text;
      text-decoration: none;

      &:last-child {
        margin-right: 0;
      }

      &--active {
        color: $color-black-text;
        text-decoration: underline;
      }
    }
  }

  &-menu {
    ul {
      display: flex;
      align-items: center;
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      margin-right: 16px;
      list-style: none;

      &:last-child {
        margin-right: 0;
      }
    }

    &__link {
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      color: $color-black-text;
      text-decoration: none;

      &--active {
        color: $color-red;
      }

      &--service {
        display: flex;
        cursor: pointer;
        align-items: center;
        &:before {
          content: '';
          display: flex;
          margin-right: 8px;
          width: 12px;
          height: 10px;
          background-image: url(../img/header/burger.svg);
          background-size: 12px 10px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  &-search {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/i-search.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &-version {
    display: none;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/i-vision.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    @media (min-width: $screen-lg) {
      display: block;
    }
  }

  &-phone {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/phone.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 0;

    @media (min-width: $screen-xl) {
      width: auto;
      height: auto;
      background-image: none;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      color: $color-black-text;
    }
  }

  &-record {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/record.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 0;

    @media (min-width: $screen-xl) {
      display: flex;
      align-items: baseline;
      width: auto;
      height: auto;
      background-image: none;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      color: $color-red;
      text-decoration: none;

      &:before {
        margin-right: 8px;
        content: '';
        display: flex;
        width: 12px;
        height: 12px;
        background-image: url(../img/header/plus.svg);
        background-size: 12px 12px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
    }
  }

  &-actions {
    display: flex;

    @media (min-width: $screen-xl) {
      align-items: flex-end;
      justify-content: flex-end; 
    }

    &__column {
      margin-right: 12px;

      &:nth-child(2) {
        @media (min-width: $screen-xl) {
          margin-right: 23%;
        }
      }

      &:nth-child(3) {
        @media (min-width: $screen-xl) {
          margin-right: 24px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .service-menu {
    display: none;
    padding-top: 20px;
    background-color: $white;

    a {
      text-decoration: none;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      list-style: none;
    }

    &__inner {
      display: flex;
      margin-left: 18.7%;
      width: 78.6%;

      @media (min-width: $screen-xl) {
        margin-left: 16.7%;
        width: 81.6%;
      }

      @media (min-width: $screen-xxl) {
        width: 82.5%;
        margin-left: 17.4%;
      }

      @media (min-width: '1700px') {
        margin: 0 auto;
        width: 82.5%;
        padding-left: 17.4%;
      }
    }

    &__column {
      &:nth-child(1) {
        width: 256px;
        height: 100%;
        border-right: 1px solid rgba(43, 37, 29, .1);
      }
      &:nth-child(2) {
        margin-left: 42px;
        flex-basis: 260px;
        min-width: 260px;
      }
      &:nth-child(3) {
        display: none;
        @media (min-width: $screen-xl) {
          display: block;
          margin-left: 88px;
        }
        @media (min-width: $screen-xxl) {
          margin-left: 148px;
        }
      }
    }

    &__item {
      display: block;
      position: relative;
      padding-top: 12px;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      color: $color-black-text;
      cursor: pointer;

      @media (min-width: $screen-xxl) {
        font-size: 16px;
      }

      &:hover {
        color: $color-red;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 7px;
          background-color: $color-red;
          width: 100%;
          height: 1px;
        }
      }
    }
  }
  .service-submenu {
    display: none;
    padding-top: 12px;

    &__link {
      display: block;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      color: $color-black-text;
      text-decoration: none;

      @media (min-width: $screen-xxl) {
        font-size: 15px;
      }

      &--sale {
        margin-top: 8px;
        display: flex;
        align-items: center;
        font-size: 15px;
        &:before {
          display: flex;
          content: "";
          margin-right: 8px;
          background-image: url(../img/header/i-sale.svg);
          background-size: 10px 11px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          flex-basis: 10px;
          width: 10px;
          height: 11px;
        }
      }
    }
  }
}
