@charset "UTF-8";
/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
@font-face {
  font-family: 'KudryashevDisplay';
  src: url("../fonts/KudryashevDisplay.woff2") format("woff2"), url("../fonts/KudryashevDisplay.woff") format("woff"); }

@font-face {
  font-family: 'KudryashevDisplay-Sans';
  src: url("../fonts/KudryashevDisplay-Sans.woff2") format("woff2"), url("../fonts/KudryashevDisplay-Sans.woff") format("woff"); }

@font-face {
  font-family: 'Golos UI';
  src: url("../fonts/Golosuimedium.woff2") format("woff2"), url("../fonts/Golosuimedium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Golos UI';
  src: url("../fonts/Golosuibold.woff2") format("woff2"), url("../fonts/Golosuibold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Golos UI';
  src: url("../fonts/Golosui.woff2") format("woff2"), url("../fonts/Golosui.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

.a-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  text-decoration: none; }

.btn-callback {
  background-color: #b8232d;
  color: white;
  cursor: pointer; }
  .btn-callback:hover, .btn-callback:focus {
    color: white;
    background-color: #EB8128; }

.btn-review {
  border: 1px solid #8C8C8E;
  background-color: transparent;
  color: #493e31; }
  .btn-review:hover, .btn-review:focus {
    color: white;
    background-color: #EB8128; }

.vk {
  background-image: url(../img/vk.svg); }

.inst {
  background-image: url(../img/inst.svg); }

.fb {
  background-image: url(../img/fb.svg); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.inner {
  max-width: 87.5%;
  margin: auto;
  margin: 0 auto; }
  @media (min-width: 656px) {
    .inner {
      max-width: 92.7%; } }
  @media (min-width: 956px) {
    .inner {
      max-width: 94.9%; } }
  @media (min-width: 1280px) {
    .inner {
      max-width: 93.7%; } }
  @media (min-width: 1520px) {
    .inner {
      max-width: 1424px; } }
  .inner-flex {
    display: flex;
    flex-wrap: wrap;
    max-width: 87.5%;
    margin: auto; }
    @media (min-width: 656px) {
      .inner-flex {
        max-width: 92.7%; } }
    @media (min-width: 956px) {
      .inner-flex {
        max-width: 94.9%; } }
    @media (min-width: 1280px) {
      .inner-flex {
        max-width: 93.7%; } }
    @media (min-width: 1520px) {
      .inner-flex {
        max-width: 1424px; } }
  .inner-page {
    padding-top: 81px; }
    @media (min-width: 956px) {
      .inner-page {
        padding-top: 81.65px; } }
  .inner-section {
    margin-bottom: 56px; }
    @media (min-width: 956px) {
      .inner-section {
        margin-bottom: 64px; } }
    @media (min-width: 1280px) {
      .inner-section {
        margin-bottom: 80px; } }
    @media (min-width: 1520px) {
      .inner-section {
        margin-bottom: 88px; } }

.section-title {
  display: flex;
  align-items: baseline; }
  .section-title--long {
    flex-direction: column; }
    @media (min-width: 656px) {
      .section-title--long {
        flex-direction: row; } }
    .section-title--long .section-header__more {
      margin-left: 0; }
      @media (min-width: 656px) {
        .section-title--long .section-header__more {
          margin-left: 18px; } }
      @media (min-width: 956px) {
        .section-title--long .section-header__more {
          margin-left: 24px; } }
  .section-title h2 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 38px;
    line-height: 1;
    color: #2a251d; }
    @media (min-width: 956px) {
      .section-title h2 {
        font-size: 52px; } }
    @media (min-width: 1520px) {
      .section-title h2 {
        font-size: 64px; } }

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 32px; }
  .section-header__more {
    display: flex;
    align-items: end;
    margin-top: 32px;
    margin-left: 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: #2a251d;
    text-decoration: none; }
    @media (min-width: 956px) {
      .section-header__more {
        margin-left: 24px; } }
    .section-header__more:after {
      content: "";
      display: flex;
      width: 24px;
      height: 24px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px; }

.section-arrows {
  display: none; }
  @media (min-width: 956px) {
    .section-arrows {
      display: flex; } }
  .section-arrows button {
    background-color: transparent;
    border: none;
    cursor: pointer; }
  .section-arrows .section-arrow__prev {
    width: 22px;
    height: 15px;
    margin-right: 20px;
    background-image: url(../img/arrow-prev.svg);
    background-size: 22px 15px;
    background-repeat: no-repeat; }
  .section-arrows .section-arrow__next {
    width: 22px;
    height: 15px;
    background-image: url(../img/arrow-next.svg);
    background-size: 22px 15px;
    background-repeat: no-repeat; }

.service {
  display: block;
  width: 206px;
  padding: 16px;
  background-color: #fbf8f4;
  box-shadow: 13px 12px 40px rgba(0, 0, 0, 0.07);
  background-image: url(../img/service-bg.svg);
  background-size: 132px 184px;
  background-repeat: no-repeat;
  background-position: 100% 0;
  text-decoration: none; }
  @media (min-width: 656px) {
    .service {
      width: 216px; } }
  @media (min-width: 956px) {
    .service {
      width: 212px; } }
  @media (min-width: 1280px) {
    .service {
      padding: 32px;
      width: 282px;
      background-image: url(../img/service-bg-max.svg);
      background-size: 218px 232px; } }
  @media (min-width: 1520px) {
    .service {
      width: 338px; } }
  .service__title {
    margin-bottom: 4px;
    min-height: 47px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #b8232d;
    hyphens: auto; }
    @media (min-width: 956px) {
      .service__title {
        margin-bottom: 6px; } }
    @media (min-width: 1280px) {
      .service__title {
        margin-bottom: 8px; } }
    @media (min-width: 1520px) {
      .service__title {
        min-height: 50px;
        font-weight: 500;
        line-height: 1;
        font-size: 26px; } }
  .service__count {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #8b8b8d;
    min-height: 18px; }
  .service__header {
    margin-bottom: 70px; }
    @media (min-width: 656px) {
      .service__header {
        margin-bottom: 79px; } }
    @media (min-width: 956px) {
      .service__header {
        margin-bottom: 73px; } }
    @media (min-width: 1280px) {
      .service__header {
        margin-bottom: 118px; } }
    @media (min-width: 1520px) {
      .service__header {
        margin-bottom: 162px; } }
  .service__link {
    display: flex;
    align-items: end;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: #2a251d;
    text-decoration: none; }
    @media (min-width: 1520px) {
      .service__link {
        font-size: 16px; } }
    .service__link:after {
      content: "";
      display: flex;
      width: 24px;
      height: 18px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px; }

.promotion {
  position: relative;
  display: block;
  width: 100%;
  padding: 24px 24px 32px;
  background-color: #c4b097;
  color: white;
  text-decoration: none; }
  .promotion:hover, .promotion:focus {
    color: white; }
  @media (min-width: 956px) {
    .promotion {
      min-height: 272px;
      padding: 0;
      background-image: url(../img/bg-promotion-min.svg);
      background-size: 155px 272px;
      background-repeat: no-repeat;
      background-position: 100% 100%; } }
  @media (min-width: 1280px) {
    .promotion {
      min-height: 364px;
      background-image: url(../img/bg-promotion-xl.svg);
      background-size: 282px 364px;
      background-repeat: no-repeat;
      background-position: 100% 100%; } }
  @media (min-width: 1520px) {
    .promotion {
      background-image: url(../img/bg-promotion-xxl.svg);
      background-size: 337px 434px;
      background-repeat: no-repeat;
      background-position: 100% 100%;
      min-height: 434px; } }
  .promotion__img {
    display: none; }
    @media (min-width: 956px) {
      .promotion__img {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0; } }
  @media (min-width: 956px) {
    .promotion img {
      display: block;
      width: 155px;
      height: 272px; } }
  @media (min-width: 1280px) {
    .promotion img {
      width: 282px;
      height: 364px; } }
  @media (min-width: 1520px) {
    .promotion img {
      width: 336px;
      height: 434px; } }
  .promotion__body {
    max-width: 232px; }
    @media (min-width: 656px) {
      .promotion__body {
        max-width: 246px; } }
    @media (min-width: 956px) {
      .promotion__body {
        padding: 32px 0 32px 24px; } }
    @media (min-width: 1280px) {
      .promotion__body {
        max-width: 290px;
        padding: 52px 0 52px 40px; } }
  .promotion__type {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase; }
    @media (min-width: 1280px) {
      .promotion__type {
        margin-bottom: 24px; } }
  @media (min-width: 956px) {
    .promotion__group {
      margin-bottom: 32px; } }
  @media (min-width: 1280px) {
    .promotion__group {
      margin-bottom: 94px; } }
  @media (min-width: 1520px) {
    .promotion__group {
      min-height: 143px;
      margin-bottom: 146px; } }
  .promotion__title {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    min-height: 50px; }
    @media (min-width: 1280px) {
      .promotion__title {
        margin-bottom: 16px; } }
    @media (min-width: 1520px) {
      .promotion__title {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 30px; } }
  .promotion .subtitle {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #fbf8f4;
    min-height: 129px; }
  .promotion__link {
    display: flex;
    align-items: end;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    text-decoration: none; }
    .promotion__link:after {
      content: "";
      display: flex;
      width: 24px;
      height: 18px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px; }

.search-list {
  padding-left: 0;
  margin-bottom: 0; }
  .search-list li {
    list-style: none;
    margin-bottom: 24px; }
  .search-list a {
    text-decoration: none; }
  .search-list__title {
    font-weight: 700;
    line-height: 1;
    text-decoration: underline;
    padding-bottom: 14px; }

.ametist a:hover, .ametist a:focus {
  color: #3A3D8A; }

.ametist .header__logo img {
  width: 142px;
  height: 36px; }

.ametist .m-header-top__clinic {
  color: #0C0C1D; }

.ametist .m-header-top__item {
  color: #8C8C8E; }

.ametist .m-header .m-nav-services__title {
  color: #0C0C1D; }

.ametist .m-header .m-nav-services__link {
  color: #2B251D; }

.ametist .m-header-burger {
  background-image: url(../img/ametist/m-burger.svg); }
  .ametist .m-header-burger--active {
    background-image: url(../img/ametist/m-burger-active.svg); }

.ametist .site-panel__btn:first-child {
  background-color: #3A3D8A; }

.ametist .site-panel__btn:last-child {
  background-color: #515157; }

.ametist .header-clinics__item {
  color: #8C8C8E; }
  .ametist .header-clinics__item--active {
    color: #2B251D; }

.ametist .header-menu__link {
  color: #2B251D; }
  .ametist .header-menu__link--active {
    color: #3A3D8A; }
  .ametist .header-menu__link--service:before {
    background-image: url(../img/ametist/burger.svg); }

.ametist .header .service-menu__item {
  color: #2B251D; }
  .ametist .header .service-menu__item:hover {
    color: #3A3D8A; }
    .ametist .header .service-menu__item:hover:after {
      background-color: #3A3D8A; }

.ametist .header .service-submenu__link {
  color: #2B251D; }
  .ametist .header .service-submenu__link--sale:before {
    background-image: url(../img/ametist/i-sale.svg); }

.ametist .header-phone {
  color: #2B251D; }

.ametist .header-record {
  color: #3A3D8A; }
  .ametist .header-record:before {
    background-image: url(../img/ametist/plus.svg); }

.ametist .page-footer__address {
  color: #515157; }

.ametist .page-footer__time {
  color: #8C8C8E; }

.ametist .page-footer__phone {
  color: #515157; }

.ametist .page-footer .footer-menu-title {
  color: #515157; }
  @media (min-width: 956px) {
    .ametist .page-footer .footer-menu-title {
      color: #8C8C8E; } }

.ametist .page-footer .footer-menu__link {
  color: #515157; }
  .ametist .page-footer .footer-menu__link:hover, .ametist .page-footer .footer-menu__link:focus {
    color: #8C8C8E; }

.ametist .page-footer .footer-copy__link {
  color: #8C8C8E; }
  .ametist .page-footer .footer-copy__link:hover, .ametist .page-footer .footer-copy__link:focus {
    color: #8C8C8E; }

.ametist .page-footer .footer-copy__text p {
  color: #8C8C8E; }

.ametist .page-footer .link-callback {
  color: #8C8C8E; }

.ametist .page-footer .footer-review__link {
  color: #515157; }

.ametist .btn-callback {
  background-color: #3A3D8A; }
  .ametist .btn-callback:hover {
    background-color: #EB8128;
    color: #FFFFFF; }

.ametist .btn-review {
  color: #515157; }

.ametist .promo-slider .slick-dots button {
  background-image: url(../img/ametist/dot.svg); }

.ametist .promo-slider .slick-dots .slick-active button {
  background-image: url(../img/ametist/dot-active.svg); }

.ametist .promo-slider .promo-slide__title {
  color: #0C0C1D; }

.ametist .promo-slider .promo-slide .subtitle {
  color: #493E31; }

.ametist .promo-slider .promo-slide__link {
  color: #0C0C1D; }
  .ametist .promo-slider .promo-slide__link:after {
    background-image: url(../img/ametist/link.svg); }

.ametist .section h2, .ametist .section .section-title h2 {
  color: #0C0C1D; }

.ametist .section-header h2, .ametist .section-header .section-title h2 {
  color: #0C0C1D; }

.ametist .section-header__more {
  color: #0C0C1D; }
  .ametist .section-header__more:after {
    background-image: url(../img/ametist/link.svg); }

.ametist .section-arrows .section-arrow__prev {
  background-image: url(../img/ametist/arrow-prev.svg); }

.ametist .section-arrows .section-arrow__next {
  background-image: url(../img/ametist/arrow-next.svg); }

.ametist .services {
  background-color: #CDBDDB; }
  .ametist .services .section-header h2, .ametist .services .ametist .section-header .section-title h2 {
    color: #FFFFFF; }
  @media (min-width: 956px) {
    .ametist .services {
      background-color: transparent; } }
  @media (min-width: 956px) {
    .ametist .services__inner {
      background-color: #CDBDDB; } }

.ametist .service {
  background-color: #FAF9FB; }
  .ametist .service__title {
    color: #3A3D8A; }
  .ametist .service__count {
    color: #8C8C8E; }
  .ametist .service__link {
    color: #0C0C1D; }
    .ametist .service__link:after {
      background-image: url(../img/ametist/link.svg); }

.ametist .text-picture {
  background-color: #fff; }
  .ametist .text-picture h2 {
    color: #0C0C1D; }
  .ametist .text-picture .subtitle {
    color: #0C0C1D; }
    .ametist .text-picture .subtitle:before {
      background: #3A3D8A; }
  .ametist .text-picture p {
    color: #493E31; }
  .ametist .text-picture__link {
    color: #0C0C1D; }
    .ametist .text-picture__link:after {
      background-image: url(../img/ametist/link.svg); }

.ametist .text {
  background-color: #fff; }
  .ametist .text h2 {
    color: #0C0C1D; }
  .ametist .text .subtitle {
    color: #0C0C1D; }
    .ametist .text .subtitle:before {
      background: #3A3D8A; }
  .ametist .text p {
    color: #493E31; }
  .ametist .text__link {
    color: #0C0C1D; }
    .ametist .text__link:after {
      background-image: url(../img/ametist/link.svg); }

.ametist .numbers .number__value {
  color: #3A3D8A; }

.ametist .numbers .number__name {
  color: #8C8C8E; }

@media (min-width: 956px) {
  .ametist .features {
    background-image: linear-gradient(to top, white 40%, white 40% 40%), linear-gradient(to right, white 40%, #CDBDDB 40% 100%); } }

@media (min-width: 1520px) {
  .ametist .features {
    background-image: linear-gradient(to top, white 40%, white 40% 40%), linear-gradient(to right, white 34%, #CDBDDB 34% 100%); } }

.ametist .features h2 {
  color: #0C0C1D; }

.ametist .features .subtitle {
  color: #0C0C1D; }
  .ametist .features .subtitle:before {
    background-color: #3A3D8A; }

.ametist .features p {
  color: #493E31; }

.ametist .features__title {
  color: #3A3D8A; }

.ametist .features__desc {
  color: #8C8C8E; }

.ametist .features__item {
  background-color: #FAF9FB; }

.ametist .features__icon {
  background-image: url(../img/ametist/f-icon.svg); }

.ametist .features__column:nth-child(2) {
  background: linear-gradient(to bottom, #CDBDDB 50%, white 50% 100%); }
  @media (min-width: 656px) {
    .ametist .features__column:nth-child(2) {
      padding-top: 38px;
      background: linear-gradient(to bottom, #CDBDDB 30%, white 30% 100%); } }
  @media (min-width: 956px) {
    .ametist .features__column:nth-child(2) {
      background: none; } }

.ametist .doctor__photo {
  background-color: #FAF9FB; }

.ametist .doctor__name {
  color: #0C0C1D; }

.ametist .doctor__desc {
  color: #8C8C8E; }

.ametist .doctor__link {
  color: #0C0C1D; }
  .ametist .doctor__link:after {
    background-image: url(../img/ametist/link.svg); }

.ametist .promotion {
  background-color: #CDBDDB;
  color: #fff; }
  @media (min-width: 956px) {
    .ametist .promotion {
      background-image: url(../img/ametist/bg-promotion-min.svg); } }
  @media (min-width: 1280px) {
    .ametist .promotion {
      background-image: url(../img/ametist/bg-promotion-xl.svg); } }
  @media (min-width: 1520px) {
    .ametist .promotion {
      background-image: url(../img/ametist/bg-promotion-xxl.svg); } }
  .ametist .promotion:hover, .ametist .promotion:focus {
    color: #fff; }
  .ametist .promotion .subtitle {
    color: #FAF9FB; }
  .ametist .promotion__link {
    color: #0C0C1D; }
    .ametist .promotion__link:after {
      background-image: url(../img/ametist/link.svg); }

.ametist .reviews .reviews-pagination__link {
  color: #8C8C8E; }

.ametist .reviews .reviews-pagination__link--active {
  color: #0C0C1D;
  border: 2px solid #3A3D8A; }

.ametist .reviews-slider .slick-dots .slick-active {
  border: 2px solid #3A3D8A; }
  .ametist .reviews-slider .slick-dots .slick-active a {
    color: #0C0C1D; }

.ametist .reviews-slider .slick-dots li a {
  color: #8C8C8E; }

.ametist .order {
  background-color: #CDBDDB; }
  @media (min-width: 956px) {
    .ametist .order__inner {
      background-color: #CDBDDB; } }
  .ametist .order__sale {
    background-color: #BEADCC;
    color: #3A3D8A; }
  .ametist .order-form__group label {
    color: #F7F7F6; }
  .ametist .order-form__info {
    color: #F7F7F6; }
  .ametist .order-form__input-text:focus ~ label, .ametist .order-form__input-text:valid ~ label {
    color: #F7F7F6; }
    .ametist .order-form__input-text:focus ~ label span, .ametist .order-form__input-text:valid ~ label span {
      color: #F7F7F6; }

.ametist .promo {
  background-color: #CDBDDB; }
  .ametist .promo__back {
    background-image: url(../img/ametist/back.svg); }
  @media (min-width: 1280px) {
    .ametist .promo-doctor__inner {
      background: linear-gradient(to right, #FBF8F4 50%, #BEADCC 50% 100%); } }
  .ametist .promo-doctor .promo-quote {
    color: #FBF8F4; }
  .ametist .promo-doctor h1 {
    color: #0C0C1D; }
  .ametist .promo-doctor .subtitle {
    color: #493E31; }
  .ametist .promo-doctor__column:nth-child(1) {
    background-color: #FBF8F4; }
  .ametist .promo-doctor__column:nth-child(2) {
    background-color: #BEADCC;
    background-image: none; }

.ametist .prices h2 {
  color: #0C0C1D; }

.ametist .prices__title {
  color: #515157; }

.ametist .prices__value {
  color: #515157; }
  .ametist .prices__value span {
    color: #8C8C8E; }

.ametist .prices .tooltip__icon {
  background-image: url(../img/ametist/tooltip.svg); }

.ametist .prices .tooltip__desc {
  color: #515157; }

.ametist .submenu__link {
  color: #0C0C1D; }
  .ametist .submenu__link--active {
    color: #3A3D8A; }
    .ametist .submenu__link--active:after {
      background-color: #3A3D8A; }

.ametist .breadcrumbs a {
  color: #fff; }

.ametist .breadcrumbs span {
  color: rgba(255, 255, 255, 0.7); }

.ametist .breadcrumbs--black span {
  color: #8C8C8E; }

.ametist .breadcrumbs--black a {
  color: #493E31; }

.ametist .documents {
  background-color: #fff; }
  .ametist .documents h2 {
    color: #0C0C1D; }
  .ametist .documents__desc {
    color: #493E31; }
  .ametist .documents__icon {
    background-color: #BEADCC; }

.ametist .slider-photo__prev {
  background-image: url(../img/ametist/left-s.svg); }

.ametist .slider-photo__next {
  background-image: url(../img/ametist/right-s.svg); }

.ametist .contact__address {
  color: #515157; }

.ametist .contact__time {
  color: #8C8C8E; }

.ametist .contact__email {
  color: #8C8C8E; }

.ametist .contact__phone {
  color: #515157; }

.ametist .contact__back {
  background-image: url(../img/ametist/back.svg); }

.ametist .modal__content {
  background-color: #CDBDDB;
  background-image: none; }

.ametist .modal__body {
  background-color: #CDBDDB;
  background-image: none; }

.ametist .text--page a {
  color: #0C0C1D; }

.ametist .text--page p {
  color: #515157; }

.ametist .text--page ul li {
  color: #515157; }

.breadcrumbs {
  display: flex;
  padding-left: 0;
  margin-bottom: 0; }
  .breadcrumbs__item {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    list-style: none; }
    .breadcrumbs__item:after {
      content: "";
      display: flex;
      width: 4px;
      height: 4px;
      margin-right: 8px;
      margin-left: 8px;
      background-image: url(../img/bc-white.svg);
      background-repeat: no-repeat; }
    .breadcrumbs__item:last-child:after {
      display: none; }
    .breadcrumbs__item a {
      text-decoration: none;
      color: white; }
    .breadcrumbs__item span {
      color: rgba(255, 255, 255, 0.7); }
  .breadcrumbs--black .breadcrumbs__item:after {
    background-image: url(../img/bc-black.svg); }
  .breadcrumbs--black a {
    color: #2a251d; }
  .breadcrumbs--black span {
    color: #8b8b8d; }

.close {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  padding: 0;
  background: transparent;
  user-select: none;
  cursor: pointer; }
  .close span {
    position: relative;
    display: block;
    width: 60%;
    height: 2px;
    font-size: 0;
    color: transparent;
    background: transparent; }
    .close span:before, .close span:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #666666;
      transform-origin: 50% 50%; }
    .close span:before {
      transform: rotate3d(0, 0, 1, 45deg); }
    .close span:after {
      transform: rotate3d(0, 0, 1, -45deg); }

@media (min-width: 1520px) {
  .contact {
    max-width: 1560px;
    margin: auto; } }

@media (min-width: 1520px) {
  .contact .inner-breadcrumbs {
    padding-left: 169px; } }

@media (min-width: 1280px) {
  .contact__inner {
    display: flex; } }

@media (min-width: 1520px) {
  .contact__inner {
    margin: 0;
    max-width: 100%; } }

.contact .breadcrumbs {
  margin-bottom: 56px; }
  @media (min-width: 656px) {
    .contact .breadcrumbs {
      margin-bottom: 38px; } }
  @media (min-width: 1280px) {
    .contact .breadcrumbs {
      margin-bottom: 68px; } }
  @media (min-width: 1520px) {
    .contact .breadcrumbs {
      margin-bottom: 78px; } }

.contact h1 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 44px;
  line-height: 44px; }
  @media (min-width: 956px) {
    .contact h1 {
      max-width: 367px;
      font-size: 72px;
      line-height: 66px; } }
  @media (min-width: 1280px) {
    .contact h1 {
      max-width: 487px; } }
  @media (min-width: 1520px) {
    .contact h1 {
      max-width: 580px;
      font-size: 80px;
      line-height: 74px; } }

.contact .page__title {
  position: relative;
  margin-bottom: 32px; }

.contact__address {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #493e31; }
  @media (min-width: 956px) {
    .contact__address {
      font-size: 24px; } }

.contact__time {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #8b8b8d; }

.contact .map {
  width: 100%;
  height: 320px;
  background-image: url(../img/map-sm.png);
  background-size: contain;
  background-repeat: no-repeat; }
  @media (min-width: 656px) {
    .contact .map {
      background-image: url(../img/map-md.png);
      height: 416px; } }
  @media (min-width: 956px) {
    .contact .map {
      background-image: url(../img/map-lg.png);
      height: 536px; } }
  @media (min-width: 1280px) {
    .contact .map {
      background-image: url(../img/map-xl.png);
      height: 645px;
      min-width: 730px; } }
  @media (min-width: 1280px) {
    .contact .map {
      background-image: url(../img/map-xxl.png);
      height: 645px; } }
  .contact .map--ametist {
    background-image: url(../img/ametist/map-a-sm.png); }
    @media (min-width: 656px) {
      .contact .map--ametist {
        background-image: url(../img/ametist/map-a-md.png); } }
    @media (min-width: 956px) {
      .contact .map--ametist {
        background-image: url(../img/ametist/map-a-lg.png); } }
    @media (min-width: 1280px) {
      .contact .map--ametist {
        background-image: url(../img/ametist/map-a-xl.png); } }
    @media (min-width: 1280px) {
      .contact .map--ametist {
        background-image: url(../img/ametist/map-a-xxl.png); } }
  .contact .map--rubin {
    background-image: url(../img/map-r-sm.png); }
    @media (min-width: 656px) {
      .contact .map--rubin {
        background-image: url(../img/map-r-md.png); } }
    @media (min-width: 956px) {
      .contact .map--rubin {
        background-image: url(../img/map-r-lg.png); } }
    @media (min-width: 1280px) {
      .contact .map--rubin {
        background-image: url(../img/map-r-xl.png); } }
    @media (min-width: 1280px) {
      .contact .map--rubin {
        background-image: url(../img/map-r-xxl.png); } }

.contact__column {
  padding-top: 32px;
  padding-bottom: 32px; }

.contact .contact-list {
  border-bottom: 1px solid rgba(42, 37, 29, 0.1); }
  @media (min-width: 956px) {
    .contact .contact-list {
      display: flex; } }
  @media (min-width: 1280px) {
    .contact .contact-list {
      flex-direction: column; } }
  .contact .contact-list__column {
    padding-bottom: 32px; }
    @media (min-width: 956px) {
      .contact .contact-list__column {
        padding-bottom: 40px; } }
    .contact .contact-list__column:nth-child(1) {
      margin-right: 97px; }

.contact__email {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #8b8b8d;
  text-decoration: none; }

.contact__phone {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #493e31;
  text-decoration: none; }
  @media (min-width: 956px) {
    .contact__phone {
      font-size: 24px; } }
  .contact__phone-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px; }

.contact__back {
  display: none; }
  @media (min-width: 956px) {
    .contact__back {
      display: block;
      position: absolute;
      top: 21%;
      left: -9%;
      width: 22px;
      height: 15px;
      background-image: url(../img/back.svg);
      background-size: 22px 15px;
      background-repeat: no-repeat; } }

@media (min-width: 956px) {
  .contact__wrapper {
    padding-left: 8.4%; } }

@media (min-width: 1280px) {
  .contact__wrapper {
    padding-right: 102px; } }

@media (min-width: 1520px) {
  .contact__wrapper {
    padding-left: 169px; } }

.contact .qr {
  padding-top: 32px; }
  @media (min-width: 956px) {
    .contact .qr {
      padding-top: 40px; } }

.contact__whatsapp {
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  background-image: url(../img/w.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin-left: 22px; }
  .contact__whatsapp:before {
    content: '';
    display: flex;
    width: 1px;
    height: 15px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: rgba(42, 37, 29, 0.1); }

.doctor {
  margin-bottom: 24px;
  text-decoration: none;
  width: 206px;
  display: flex;
  flex-direction: column; }
  @media (min-width: 656px) {
    .doctor {
      margin-right: 24px;
      width: 216px; } }
  @media (min-width: 956px) {
    .doctor {
      width: 212px; } }
  @media (min-width: 1280px) {
    .doctor {
      width: 282px; } }
  @media (min-width: 1520px) {
    .doctor {
      width: 338px; } }
  .doctor-inner {
    flex-direction: column; }
    @media (min-width: 656px) {
      .doctor-inner {
        flex-direction: row;
        max-width: 604px; } }
    @media (min-width: 956px) {
      .doctor-inner {
        padding-left: 0;
        max-width: 875px; } }
    @media (min-width: 1280px) {
      .doctor-inner {
        max-width: 1098px; } }
    @media (min-width: 1520px) {
      .doctor-inner {
        margin: 0;
        max-width: 1314px; } }
  .doctor__photo {
    margin-bottom: 20px;
    background-color: #fbf8f4;
    background-image: url(../img/bg-doc.png);
    background-size: 85px 143px;
    background-repeat: no-repeat;
    background-position: 100% 0; }
  .doctor__name {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2a251d; }
  .doctor__desc {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #8b8b8d; }
  .doctor__body {
    max-width: 180px; }
    @media (min-width: 656px) {
      .doctor__body {
        max-width: 200px; } }
    @media (min-width: 1280px) {
      .doctor__body {
        max-width: 236px; } }
    @media (min-width: 1520px) {
      .doctor__body {
        max-width: 242px; } }
  .doctor__link {
    display: flex;
    align-items: end;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: #2a251d;
    text-decoration: none; }
    .doctor__link:after {
      content: "";
      display: flex;
      width: 24px;
      height: 18px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px; }
  .doctor img {
    display: block;
    max-width: 134px;
    max-height: 184px; }
    @media (min-width: 656px) {
      .doctor img {
        max-width: 140px;
        max-height: 193px; } }
    @media (min-width: 956px) {
      .doctor img {
        max-width: 138px;
        max-height: 189px; } }
    @media (min-width: 1280px) {
      .doctor img {
        max-width: 180px;
        max-height: 252px; } }
    @media (min-width: 1520px) {
      .doctor img {
        max-width: 216px;
        max-height: 302px; } }

@media (min-width: 1520px) {
  .doctors {
    max-width: 1520px;
    padding-left: 169px;
    margin: auto; } }

@media (min-width: 1520px) {
  .doctors-slider {
    max-width: 1520px;
    margin: auto; } }

.doctors-slider__inner {
  max-width: 87.5%;
  margin: auto; }
  @media (min-width: 656px) {
    .doctors-slider__inner {
      max-width: 92.7%; } }
  @media (min-width: 956px) {
    .doctors-slider__inner {
      max-width: 89.4%;
      margin: 0 0 0 10.6%; } }
  @media (min-width: 1280px) {
    .doctors-slider__inner {
      max-width: 89.4%;
      margin: 0 0 0 10.6%; } }
  @media (min-width: 1520px) {
    .doctors-slider__inner {
      max-width: 1472px;
      margin: 0 0 0 142px; } }

.doctors-slider__wrapper {
  width: 100%; }
  @media (min-width: 956px) {
    .doctors-slider__wrapper {
      padding-right: 11.9%; } }
  @media (min-width: 1280px) {
    .doctors-slider__wrapper {
      padding-right: 12.4%; } }
  @media (min-width: 1520px) {
    .doctors-slider__wrapper {
      padding-right: 169px; } }

.doctors-slider .slick-slide {
  margin-right: 16px;
  width: 206px; }
  @media (min-width: 656px) {
    .doctors-slider .slick-slide {
      margin-right: 24px;
      width: 216px; } }
  @media (min-width: 956px) {
    .doctors-slider .slick-slide {
      width: 212px; } }
  @media (min-width: 1280px) {
    .doctors-slider .slick-slide {
      width: 282px; } }
  @media (min-width: 1520px) {
    .doctors-slider .slick-slide {
      width: 338px; } }

.documents {
  background-color: #fff; }
  @media (min-width: 656px) {
    .documents .inner {
      max-width: 608px; } }
  @media (min-width: 956px) {
    .documents .inner {
      min-width: 754px; } }
  @media (min-width: 1280px) {
    .documents .inner {
      max-width: 996px; } }
  @media (min-width: 1520px) {
    .documents .inner {
      max-width: 1182px; } }
  .documents h2 {
    margin-bottom: 32px;
    font-size: 38px;
    line-height: 1;
    color: #2a251d; }
    @media (min-width: 656px) {
      .documents h2 {
        max-width: 530px; } }
    @media (min-width: 956px) {
      .documents h2 {
        font-size: 52px;
        max-width: 600px; } }
    @media (min-width: 1280px) {
      .documents h2 {
        margin-bottom: 40px;
        max-width: 100%; } }
    @media (min-width: 1520px) {
      .documents h2 {
        font-size: 64px;
        max-width: 820px; } }
  .documents__list {
    padding-left: 0;
    margin-bottom: 32px;
    list-style: none; }
    @media (min-width: 956px) {
      .documents__list {
        margin-bottom: 40px; } }
  .documents__item {
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid rgba(42, 37, 29, 0.1);
    border-bottom: 1px solid rgba(42, 37, 29, 0.1); }
    @media (min-width: 956px) {
      .documents__item {
        max-width: 754px; } }
    @media (min-width: 1280px) {
      .documents__item {
        max-width: 100%; } }
  .documents__desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #2a251d; }
    @media (min-width: 656px) {
      .documents__desc {
        max-width: 450px; } }
    @media (min-width: 956px) {
      .documents__desc {
        max-width: 574px; } }
    @media (min-width: 1280px) {
      .documents__desc {
        max-width: 792px; } }
    @media (min-width: 1520px) {
      .documents__desc {
        max-width: 940px; } }
  .documents__link {
    display: flex;
    text-decoration: none; }
  .documents__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 58px;
    min-width: 58px;
    height: 64px;
    margin-right: 16px;
    background-color: #c4b097; }
    @media (min-width: 656px) {
      .documents__icon {
        margin-right: 22px; } }
    @media (min-width: 956px) {
      .documents__icon {
        flex-basis: 78px;
        width: 78px;
        height: 88px;
        margin-right: 24px; } }
    @media (min-width: 1520px) {
      .documents__icon {
        flex-basis: 96px;
        width: 96px;
        height: 108px; } }
    .documents__icon img {
      width: 34px;
      height: 14px; }
      @media (min-width: 956px) {
        .documents__icon img {
          width: 46px;
          height: 18px; } }
      @media (min-width: 1520px) {
        .documents__icon img {
          width: 57px;
          height: 22px; } }
  .documents .a-btn {
    height: 44px; }
    @media (min-width: 656px) {
      .documents .a-btn {
        width: 139px;
        margin: auto; } }

.features {
  position: relative; }
  @media (min-width: 956px) {
    .features {
      background: linear-gradient(to top, white 40%, white 40% 40%), linear-gradient(to right, white 40%, #c4b097 40% 100%);
      background-size: 100% 20%, 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 100%, 0 0; } }
  @media (min-width: 1520px) {
    .features {
      max-width: 1520px;
      margin: auto;
      background: linear-gradient(to top, white 40%, white 40% 40%), linear-gradient(to right, white 34%, #c4b097 34% 100%);
      background-size: 100% 20%, 99.9% 99.9%;
      background-repeat: no-repeat;
      background-position: 0 100%, 0 0; } }
  .features h2 {
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 1;
    color: #2a251d; }
    @media (min-width: 956px) {
      .features h2 {
        font-size: 52px;
        max-width: 484px; } }
    @media (min-width: 1280px) {
      .features h2 {
        margin-bottom: 40px;
        max-width: 100%; } }
    @media (min-width: 1520px) {
      .features h2 {
        font-size: 64px;
        max-width: 579px; } }
  @media (min-width: 956px) {
    .features__inner {
      display: flex;
      justify-content: center;
      position: relative; } }
  .features__header {
    max-width: 87.5%;
    margin: auto; }
    @media (min-width: 656px) {
      .features__header {
        max-width: 92.7%; } }
    @media (min-width: 956px) {
      .features__header {
        max-width: 78.9%; } }
  .features__column:nth-child(1) {
    padding-bottom: 32px; }
    @media (min-width: 956px) {
      .features__column:nth-child(1) {
        flex-basis: 212px;
        width: 212px;
        margin-right: 20px; } }
    @media (min-width: 1280px) {
      .features__column:nth-child(1) {
        flex-basis: 282px;
        width: 282px;
        margin-right: 24px; } }
    @media (min-width: 1520px) {
      .features__column:nth-child(1) {
        flex-basis: 338px;
        width: 338px;
        margin-right: 24px; } }
  .features__column:nth-child(2) {
    padding-top: 56px;
    background: linear-gradient(to bottom, #c4b097 50%, #fbf8f4 50% 100%); }
    @media (min-width: 656px) {
      .features__column:nth-child(2) {
        padding-top: 38px;
        background: linear-gradient(to bottom, #c4b097 30%, #fbf8f4 30% 100%); } }
    @media (min-width: 956px) {
      .features__column:nth-child(2) {
        background: none;
        padding-left: 78px; } }
    @media (min-width: 1280px) {
      .features__column:nth-child(2) {
        padding-left: 120px; } }
  .features__wrapper {
    max-width: 87.5%;
    margin: auto; }
    @media (min-width: 656px) {
      .features__wrapper {
        max-width: 92.7%; } }
    @media (min-width: 956px) {
      .features__wrapper {
        max-width: 100%;
        margin: 0; } }
  .features .subtitle {
    display: flex;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    color: #2a251d; }
    .features .subtitle:before {
      content: "";
      margin-right: 8px;
      display: flex;
      width: 4px;
      background: #b8232d; }
      @media (min-width: 956px) {
        .features .subtitle:before {
          margin-right: 16px; } }
      @media (min-width: 1280px) {
        .features .subtitle:before {
          margin-right: 20px; } }
    @media (min-width: 956px) {
      .features .subtitle {
        font-size: 20px;
        line-height: 26px; } }
    .features .subtitle span {
      width: 95.8%; }
  .features p {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    color: #493e31; }
  .features__title {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #b8232d; }
  .features__desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #8b8b8d; }
  .features__icon {
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
    background-image: url(../img/f-icon.svg);
    background-size: 48px 48px; }
    @media (min-width: 1520px) {
      .features__icon {
        margin-bottom: 32px; } }
  .features__item {
    margin-bottom: 20px;
    width: 280px;
    padding: 32px;
    background-color: #fbf8f4; }
    @media (min-width: 656px) {
      .features__item {
        width: 294px;
        margin-right: 20px; } }
    @media (min-width: 956px) {
      .features__item {
        width: 212px;
        padding: 20px; } }
    @media (min-width: 1280px) {
      .features__item {
        width: 282px;
        padding: 32px; } }
    @media (min-width: 1520px) {
      .features__item {
        width: 338px;
        padding: 40px; } }
  @media (min-width: 656px) {
    .features-list {
      display: flex; } }
  .features-list__column:nth-child(2) {
    margin-top: 24px; }

.footer-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  padding-left: 0; }
  @media (min-width: 1280px) {
    .footer-icons {
      justify-content: flex-end; } }
  .footer-icons__item {
    margin-right: 8px;
    list-style: none; }
    .footer-icons__item:last-child {
      margin-right: 0; }
    @media (min-width: 956px) {
      .footer-icons__item {
        margin-right: 12px; } }
  .footer-icons a {
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px; }
    @media (min-width: 956px) {
      .footer-icons a {
        width: 32px;
        height: 32px;
        background-size: 32px; } }

.header {
  display: none;
  position: fixed;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  z-index: 10;
  background-color: white;
  box-shadow: 13px 12px 40px rgba(0, 0, 0, 0.07); }
  @media (min-width: 956px) {
    .header {
      display: block; } }
  .header__inner {
    justify-content: space-between;
    align-items: flex-end; }
  .header__logo img {
    width: 119px;
    height: 36px; }
  .header__column:nth-child(1) {
    width: 14.8%;
    margin-right: 2.3%; }
    @media (min-width: 1280px) {
      .header__column:nth-child(1) {
        width: 10.3%;
        margin-right: 3.5%; } }
    @media (min-width: 1520px) {
      .header__column:nth-child(1) {
        width: 220px;
        margin-right: 0; } }
  .header__column:nth-child(2) {
    width: 59.6%;
    margin-right: 5%; }
    @media (min-width: 1280px) {
      .header__column:nth-child(2) {
        width: 48.3%;
        margin-right: 0; } }
    @media (min-width: 1520px) {
      .header__column:nth-child(2) {
        width: 726px;
        margin-right: 24px; } }
  .header__column:nth-child(3) {
    width: 18.1%; }
    @media (min-width: 1280px) {
      .header__column:nth-child(3) {
        width: 36%; } }
    @media (min-width: 1520px) {
      .header__column:nth-child(3) {
        width: 454px; } }
  .header-clinics {
    display: flex;
    margin-bottom: 4px; }
    .header-clinics__item {
      margin-right: 12px;
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #8b8b8d;
      text-decoration: none; }
      .header-clinics__item:last-child {
        margin-right: 0; }
      .header-clinics__item--active {
        color: #2a251d;
        text-decoration: underline; }
  .header-menu ul {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0; }
  .header-menu li {
    margin-right: 16px;
    list-style: none; }
    .header-menu li:last-child {
      margin-right: 0; }
  .header-menu__link {
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: #2a251d;
    text-decoration: none; }
    .header-menu__link--active {
      color: #b8232d; }
    .header-menu__link--service {
      display: flex;
      cursor: pointer;
      align-items: center; }
      .header-menu__link--service:before {
        content: '';
        display: flex;
        margin-right: 8px;
        width: 12px;
        height: 10px;
        background-image: url(../img/header/burger.svg);
        background-size: 12px 10px;
        background-position: 50% 50%;
        background-repeat: no-repeat; }
  .header-search {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/i-search.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat; }
  .header-version {
    display: none;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/i-vision.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat; }
    @media (min-width: 956px) {
      .header-version {
        display: block; } }
  .header-phone {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/phone.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 0; }
    @media (min-width: 1280px) {
      .header-phone {
        width: auto;
        height: auto;
        background-image: none;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #2a251d; } }
  .header-record {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/record.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 0; }
    @media (min-width: 1280px) {
      .header-record {
        display: flex;
        align-items: baseline;
        width: auto;
        height: auto;
        background-image: none;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #b8232d;
        text-decoration: none; }
        .header-record:before {
          margin-right: 8px;
          content: '';
          display: flex;
          width: 12px;
          height: 12px;
          background-image: url(../img/header/plus.svg);
          background-size: 12px 12px;
          background-position: 50% 50%;
          background-repeat: no-repeat; } }
  .header-actions {
    display: flex; }
    @media (min-width: 1280px) {
      .header-actions {
        align-items: flex-end;
        justify-content: flex-end; } }
    .header-actions__column {
      margin-right: 12px; }
      @media (min-width: 1280px) {
        .header-actions__column:nth-child(2) {
          margin-right: 23%; } }
      @media (min-width: 1280px) {
        .header-actions__column:nth-child(3) {
          margin-right: 24px; } }
      .header-actions__column:last-child {
        margin-right: 0; }
  .header .service-menu {
    display: none;
    padding-top: 20px;
    background-color: white; }
    .header .service-menu a {
      text-decoration: none; }
    .header .service-menu ul {
      padding-left: 0;
      margin-bottom: 0; }
    .header .service-menu li {
      list-style: none; }
    .header .service-menu__inner {
      display: flex;
      margin-left: 18.7%;
      width: 78.6%; }
      @media (min-width: 1280px) {
        .header .service-menu__inner {
          margin-left: 16.7%;
          width: 81.6%; } }
      @media (min-width: 1520px) {
        .header .service-menu__inner {
          width: 82.5%;
          margin-left: 17.4%; } }
      @media (min-width: 1700px) {
        .header .service-menu__inner {
          margin: 0 auto;
          width: 82.5%;
          padding-left: 17.4%; } }
    .header .service-menu__column:nth-child(1) {
      width: 256px;
      height: 100%;
      border-right: 1px solid rgba(43, 37, 29, 0.1); }
    .header .service-menu__column:nth-child(2) {
      margin-left: 42px;
      flex-basis: 260px;
      min-width: 260px; }
    .header .service-menu__column:nth-child(3) {
      display: none; }
      @media (min-width: 1280px) {
        .header .service-menu__column:nth-child(3) {
          display: block;
          margin-left: 88px; } }
      @media (min-width: 1520px) {
        .header .service-menu__column:nth-child(3) {
          margin-left: 148px; } }
    .header .service-menu__item {
      display: block;
      position: relative;
      padding-top: 12px;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      color: #2a251d;
      cursor: pointer; }
      @media (min-width: 1520px) {
        .header .service-menu__item {
          font-size: 16px; } }
      .header .service-menu__item:hover {
        color: #b8232d; }
        .header .service-menu__item:hover:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 7px;
          background-color: #b8232d;
          width: 100%;
          height: 1px; }
  .header .service-submenu {
    display: none;
    padding-top: 12px; }
    .header .service-submenu__link {
      display: block;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      color: #2a251d;
      text-decoration: none; }
      @media (min-width: 1520px) {
        .header .service-submenu__link {
          font-size: 15px; } }
      .header .service-submenu__link--sale {
        margin-top: 8px;
        display: flex;
        align-items: center;
        font-size: 15px; }
        .header .service-submenu__link--sale:before {
          display: flex;
          content: "";
          margin-right: 8px;
          background-image: url(../img/header/i-sale.svg);
          background-size: 10px 11px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          flex-basis: 10px;
          width: 10px;
          height: 11px; }

.m-header {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: white;
  box-shadow: 13px 12px 40px rgba(0, 0, 0, 0.07); }
  @media (min-width: 956px) {
    .m-header {
      display: none; } }
  .m-header-top-list {
    display: none; }
  .m-header-top__inner {
    border-bottom: 1px solid rgba(43, 37, 29, 0.1); }
  .m-header-top__clinic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: #2a251d; }
    .m-header-top__clinic:after {
      display: flex;
      content: '';
      background-image: url(../img/arrow-down.svg);
      width: 24px;
      height: 24px;
      background-position: 50% 50%;
      background-repeat: no-repeat; }
      @media (min-width: 956px) {
        .m-header-top__clinic:after {
          display: none; } }
    .m-header-top__clinic--active:after {
      background-image: url(../img/arrow-up.svg); }
  .m-header-top__item {
    margin-bottom: 8px;
    display: block;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;
    color: #8b8b8d; }
    .m-header-top__item:last-child {
      margin-bottom: 0; }
  .m-header-top-list {
    padding: 16px 0; }
  .m-header-body {
    padding-top: 12px;
    padding-bottom: 12px; }
    .m-header-body__inner {
      align-items: center;
      justify-content: space-between; }
  .m-header-burger {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/m-burger.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat; }
    .m-header-burger--active {
      background-image: url(../img/header/m-burger-active.svg); }
  .m-header-phone {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/phone.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat; }
  .m-header-logo img {
    width: 80px;
    height: 24px; }
  .m-header .m-nav {
    display: none;
    overflow-y: auto; }
    .m-header .m-nav-services {
      margin-bottom: 8px; }
      .m-header .m-nav-services__list {
        display: none; }
      .m-header .m-nav-services__title {
        padding-top: 12px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(43, 37, 29, 0.1);
        font-weight: 500;
        font-size: 15px;
        line-height: 1;
        cursor: pointer;
        color: #2a251d;
        text-decoration: none; }
        .m-header .m-nav-services__title:first-child {
          border-top: 1px solid rgba(43, 37, 29, 0.1); }
        .m-header .m-nav-services__title:after {
          display: flex;
          content: '';
          background-image: url(../img/arrow-down.svg);
          width: 24px;
          height: 24px;
          background-position: 50% 50%;
          background-repeat: no-repeat; }
          @media (min-width: 956px) {
            .m-header .m-nav-services__title:after {
              display: none; } }
        .m-header .m-nav-services__title--active:after {
          background-image: url(../img/arrow-up.svg); }
      .m-header .m-nav-services li {
        list-style: none; }
      .m-header .m-nav-services__link {
        display: block;
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2a251d;
        text-decoration: none; }
        .m-header .m-nav-services__link--sale {
          margin-top: 8px;
          display: flex;
          align-items: center;
          font-size: 15px; }
          .m-header .m-nav-services__link--sale:before {
            display: flex;
            content: "";
            margin-right: 8px;
            background-image: url(../img/header/i-sale.svg);
            background-size: 10px 11px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            flex-basis: 10px;
            width: 10px;
            height: 11px; }
      .m-header .m-nav-services__list {
        padding-top: 8px;
        padding-bottom: 20px; }
      .m-header .m-nav-services ul {
        padding-left: 0;
        margin-bottom: 0; }
    .m-header .m-nav-menu {
      padding-top: 32px;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(43, 37, 29, 0.1); }
      .m-header .m-nav-menu ul {
        padding-left: 0;
        margin-bottom: 0; }
      .m-header .m-nav-menu li {
        display: block;
        margin-bottom: 12px; }
        .m-header .m-nav-menu li:last-child {
          margin-bottom: 0; }
      .m-header .m-nav-menu__link {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #2a251d;
        text-decoration: none; }
    .m-header .m-nav-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0; }
      .m-header .m-nav-footer__column:nth-child(1) {
        display: flex; }
      .m-header .m-nav-footer .m-nav-search {
        display: block;
        margin-right: 12px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background-image: url(../img/header/i-search.svg);
        background-size: 32px 32px;
        background-position: 50% 50%;
        background-repeat: no-repeat; }
      .m-header .m-nav-footer .m-nav-version {
        display: block;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background-image: url(../img/header/i-vision.svg);
        background-size: 32px 32px;
        background-position: 50% 50%;
        background-repeat: no-repeat; }
        @media (min-width: 956px) {
          .m-header .m-nav-footer .m-nav-version {
            display: none; } }
      .m-header .m-nav-footer .m-nav-phone {
        font-size: 15px;
        font-weight: bold;
        line-height: 1;
        color: #2a251d;
        text-decoration: none; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }
  .modal__dialog {
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    max-width: 480px;
    min-height: calc(100% - 4em);
    margin: 2em auto;
    pointer-events: none; }
  .modal__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 40px;
    pointer-events: auto;
    background-color: #b39a7a;
    background-image: linear-gradient(93.17deg, rgba(196, 176, 151, 0.63) 0%, rgba(223, 211, 197, 0.63) 134.99%);
    outline: 0; }
  .modal__header {
    position: relative;
    flex-shrink: 0;
    padding: 16px 40px 16px 16px;
    border-bottom: 1px solid #b3b3b3; }
  .modal h2 {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;
    color: white; }
    @media (min-width: 956px) {
      .modal h2 {
        margin-bottom: 40px;
        max-width: 367px;
        font-size: 52px;
        line-height: 1; } }
    @media (min-width: 1280px) {
      .modal h2 {
        max-width: 487px; } }
    @media (min-width: 1520px) {
      .modal h2 {
        margin-bottom: 48px;
        max-width: 580px;
        font-size: 64px;
        line-height: 1; } }
  .modal__info {
    font-size: 18px;
    line-height: 26px;
    color: #F7F7F6; }
  .modal__title {
    margin: 0;
    font-size: 18px;
    font-weight: 400; }
  .modal__close {
    position: absolute;
    top: 4px;
    right: 4px;
    outline: 0; }
  .modal__body {
    position: relative;
    flex: 1 1 auto;
    padding: 24px;
    background-color: #b39a7a;
    background-image: linear-gradient(93.17deg, rgba(196, 176, 151, 0.63) 0%, rgba(223, 211, 197, 0.63) 134.99%); }
  @media (min-width: 1280px) {
    .modal .order-form__inner {
      display: block; } }
  @media (min-width: 1280px) {
    .modal .order-form__group {
      margin-bottom: 32px;
      margin-right: 0; } }
  @media (min-width: 1280px) {
    .modal .order-form__column {
      width: 100%; } }
  @media (min-width: 1520px) {
    .modal .order-form__column {
      width: 100%; } }
  @media (min-width: 1280px) {
    .modal-search .order-form__inner {
      display: flex; } }
  @media (min-width: 1280px) {
    .modal-search .order-form__group {
      margin-bottom: 0;
      margin-right: 24px; } }
  .modal__footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid #b3b3b3; }
    .modal__footer > * {
      margin-left: 8px; }
  .modal--show .modal__dialog {
    animation: modal-dialog 0.3s 1 ease-out forwards; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: black;
  animation: modal-backdrop 0.3s 1 ease-out forwards; }

@keyframes modal-backdrop {
  from {
    opacity: 0; }
  to {
    opacity: 0.6; } }

@keyframes modal-dialog {
  from {
    transform: translate(0, -2em); }
  to {
    transform: translate(0, 0); } }

.numbers {
  padding-bottom: 32px; }
  @media (min-width: 656px) {
    .numbers {
      padding-top: 16px;
      padding-bottom: 24px; } }
  @media (min-width: 1520px) {
    .numbers {
      padding-bottom: 44px; } }
  @media (min-width: 956px) {
    .numbers .inner {
      padding-left: 8.4%;
      padding-right: 8.4%; } }
  @media (min-width: 1520px) {
    .numbers .inner {
      padding-left: 121px;
      padding-right: 121px; } }
  .numbers .number {
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(42, 37, 29, 0.1);
    list-style: none; }
    .numbers .number__value {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      color: #b8232d; }
      @media (min-width: 656px) {
        .numbers .number__value {
          margin-bottom: 12px; } }
      @media (min-width: 956px) {
        .numbers .number__value {
          margin-bottom: 16px;
          font-size: 24px; } }
      @media (min-width: 1280px) {
        .numbers .number__value {
          font-size: 24px; } }
      @media (min-width: 1520px) {
        .numbers .number__value {
          font-size: 32px; } }
      .numbers .number__value b {
        font-size: 58px;
        line-height: 1; }
        @media (min-width: 956px) {
          .numbers .number__value b {
            font-size: 72px; } }
        @media (min-width: 1280px) {
          .numbers .number__value b {
            font-size: 88px; } }
        @media (min-width: 1520px) {
          .numbers .number__value b {
            font-size: 100px; } }
    .numbers .number__name {
      max-width: 218px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #8b8b8d; }
      @media (min-width: 956px) {
        .numbers .number__name {
          max-width: 225px; } }
      @media (min-width: 1520px) {
        .numbers .number__name {
          font-size: 18px;
          max-width: 308px; } }
    .numbers .number:last-child {
      margin-right: 0; }
      @media (min-width: 656px) {
        .numbers .number:last-child {
          border-right: none;
          padding-right: 0; } }
    @media (min-width: 656px) {
      .numbers .number:first-child {
        padding-left: 0; } }
    @media (min-width: 656px) {
      .numbers .number {
        padding-top: 0;
        padding-right: 26px;
        padding-bottom: 0;
        padding-left: 26px;
        border-right: 1px solid rgba(42, 37, 29, 0.1);
        border-bottom: 0; } }
    @media (min-width: 956px) {
      .numbers .number {
        padding-right: 48px;
        padding-left: 48px; } }
    @media (min-width: 1280px) {
      .numbers .number {
        padding-right: 58px;
        padding-left: 58px; } }
    @media (min-width: 1520px) {
      .numbers .number {
        padding-right: 78px;
        padding-left: 78px; } }
  .numbers__inner {
    margin-bottom: 0;
    padding-left: 0; }
    @media (min-width: 656px) {
      .numbers__inner {
        display: flex; } }

.order {
  background-color: #c4b097;
  color: white;
  background-image: url(../img/order-bg.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 320px 273px; }
  @media (min-width: 956px) {
    .order {
      background-color: transparent;
      background-image: none; } }
  @media (min-width: 1520px) {
    .order {
      margin: auto;
      padding-left: 48px;
      width: 1520px; } }
  .order__inner {
    padding-top: 64px;
    padding-bottom: 72px; }
    @media (min-width: 656px) {
      .order__inner {
        display: flex; } }
    @media (min-width: 956px) {
      .order__inner {
        max-width: 97.5%;
        margin: 0 0 0 2.5%;
        padding-left: 8%;
        padding-right: 10.6%;
        background-color: #c4b097;
        background-image: url(../img/order-bg-max.svg);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 639px 372px; } }
    @media (min-width: 1280px) {
      .order__inner {
        flex-direction: column;
        padding-bottom: 80px;
        max-width: 96.9%;
        margin: 0 0 0 3.1%;
        padding-left: 7.9%;
        padding-right: 11.1%; } }
    @media (min-width: 1520px) {
      .order__inner {
        padding-bottom: 88px;
        max-width: 1472px;
        width: 1472px;
        margin: 0; } }
  @media (min-width: 656px) {
    .order__column {
      width: 48.4%; } }
  @media (min-width: 656px) {
    .order__column:nth-child(1) {
      margin-right: 3.2%; } }
  @media (min-width: 956px) {
    .order__column:nth-child(1) {
      margin-bottom: 36px;
      width: 50%;
      max-width: 589px;
      margin-right: 10%; } }
  @media (min-width: 1280px) {
    .order__column:nth-child(1) {
      width: 100%;
      margin-right: 0; } }
  @media (min-width: 1520px) {
    .order__column:nth-child(1) {
      max-width: 700px;
      margin-bottom: 44px; } }
  @media (min-width: 656px) {
    .order__column:nth-child(2) {
      padding-top: 44px; } }
  @media (min-width: 956px) {
    .order__column:nth-child(2) {
      padding-top: 55px;
      width: 39%; } }
  @media (min-width: 1280px) {
    .order__column:nth-child(2) {
      padding-top: 0;
      width: 100%; } }
  .order__sale {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 87px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    line-height: 0;
    background-color: #b39a7a;
    color: #b8232d;
    letter-spacing: 0.05em;
    text-transform: uppercase; }
    @media (min-width: 956px) {
      .order__sale {
        margin-bottom: 28px; } }
  .order__title {
    font-family: "KudryashevDisplay-Sans";
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;
    color: white; }
    @media (min-width: 956px) {
      .order__title {
        margin-bottom: 16px;
        font-size: 52px;
        line-height: 1; } }
    @media (min-width: 1520px) {
      .order__title {
        margin-bottom: 12px;
        font-size: 64px;
        line-height: 1; } }
  .order .subtitle {
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: white; }
    @media (min-width: 656px) {
      .order .subtitle {
        margin-bottom: 0; } }
    @media (min-width: 956px) {
      .order .subtitle {
        font-size: 20px;
        line-height: 26px; } }
    @media (min-width: 1520px) {
      .order .subtitle {
        max-width: 579px; } }

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent !important; } }
  .order input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both; }
  @media (min-width: 1280px) {
    .order-form__inner {
      display: flex;
      align-items: baseline;
      margin-bottom: 24px; } }
  @media (min-width: 1280px) {
    .order-form__column {
      flex-basis: 260px;
      width: 260px; } }
  @media (min-width: 1520px) {
    .order-form__column {
      flex-basis: 320px;
      width: 320px; } }
  .order-form__group {
    position: relative;
    margin-bottom: 28px; }
    @media (min-width: 1280px) {
      .order-form__group {
        margin-bottom: 0;
        margin-right: 24px; } }
    .order-form__group label {
      position: absolute;
      pointer-events: none;
      left: 0;
      bottom: 12px;
      transition: 0.2s ease all;
      font-weight: 500;
      font-size: 18px;
      line-height: 1;
      color: #f8f8f7; }
  .order-form__buttons {
    width: 100%;
    margin-bottom: 20px;
    padding-top: 18px; }
    @media (min-width: 1280px) {
      .order-form__buttons {
        margin-bottom: 0;
        padding-top: 0; } }
  .order-form__button {
    display: block;
    margin: auto;
    width: 280px;
    height: 44px;
    border: none;
    color: white; }
    @media (min-width: 656px) {
      .order-form__button {
        margin: 0;
        width: 294px; } }
    @media (min-width: 1280px) {
      .order-form__button {
        width: 128px; } }
  .order-form__info {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #f8f8f7; }
  .order-form__input-text {
    display: block;
    font-family: "Golos UI";
    width: 100%;
    padding: 12px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #f8f8f7;
    border: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.7); }
    .order-form__input-text:focus {
      outline: none; }
  .order-form__input-text:focus ~ label, .order-form__input-text:valid ~ label {
    color: #f8f8f7;
    font-size: 12px;
    top: -5px; }
    .order-form__input-text:focus ~ label span, .order-form__input-text:valid ~ label span {
      color: #f8f8f7; }

@-ms-viewport {
  width: device-width; }

/* stylelint-disable */
/* stylelint-enable */
.page {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-width: 320px;
  min-height: 100%; }
  .page__inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh; }
  .page__content {
    flex-grow: 1; }
  .page__footer-wrapper {
    flex-shrink: 0; }
  .page-breadcrumbs {
    margin: auto;
    margin-bottom: 32px; }
    @media (min-width: 956px) {
      .page-breadcrumbs {
        padding-left: 6%;
        padding-right: 6%; } }
    @media (min-width: 1520px) {
      .page-breadcrumbs {
        padding-left: 7.8%;
        padding-right: 6.2%;
        max-width: 1442px;
        margin: 0 auto 40px auto; } }

*,
*:before,
*:after {
  box-sizing: inherit; }

* {
  -webkit-tap-highlight-color: transparent; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  word-break: break-word;
  font-family: "Golos UI";
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #1a1a1a;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[tabindex='-1']:focus {
  outline: none !important; }

[role='button'] {
  cursor: pointer; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0; }

button,
input {
  overflow: visible;
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

svg {
  fill: currentColor; }
  a svg,
  button svg {
    pointer-events: none; }
  svg:not(:root) {
    overflow: hidden; }

img {
  max-width: 100%;
  height: auto; }

a {
  color: #0275d9;
  background-color: transparent;
  transition: color 0.3s; }
  a:hover, a:focus {
    color: #b8232d; }
  @media (min-width: 1280px) {
    a[href^='tel:'] {
      white-space: nowrap;
      pointer-events: none;
      text-decoration: inherit;
      color: inherit; } }

hr {
  box-sizing: content-box;
  height: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid #b3b3b3;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-family: "KudryashevDisplay-Sans";
  font-weight: 500;
  line-height: 1.2;
  color: currentColor; }

h1 {
  font-size: 72px;
  line-height: 66px; }

h2 {
  font-size: 52px;
  line-height: 52px; }

h3 {
  font-size: 24px;
  line-height: 28px; }

h4 {
  font-size: 18px;
  line-height: 24px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 16px; }

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 1em; }

p {
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%; }

address {
  font-style: normal; }

ul,
ol {
  margin-bottom: 1em;
  padding-left: 1.5em; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 0;
    margin-bottom: 0; }

li {
  line-height: inherit; }

small {
  font-size: 0.75em; }

sub,
sup {
  font-size: 0.75em; }

b,
strong {
  font-weight: bolder; }

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0; }

code,
kbd,
pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace; }

code {
  padding: 0.06em 0.3em;
  color: #1a1a1a;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 3px; }

kbd {
  padding: 0.06em 0.3em;
  color: #1a1a1a;
  background-color: #e6e6e6;
  border-radius: 3px; }
  kbd kbd {
    padding: 0; }

pre {
  display: block;
  width: 100%;
  margin-bottom: 1em;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(0, 0, 0, 0.08); }
  pre code {
    background: transparent; }

.subtitle {
  font-family: "Golos UI";
  font-size: 20px;
  font-weight: 400;
  line-height: 26px; }

p {
  font-family: "Golos UI";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; }

.p2 {
  font-family: "Golos UI";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }

.p3 {
  font-family: "Golos UI";
  font-size: 13px;
  font-weight: 500;
  line-height: 18px; }

.caps {
  font-family: "Golos UI";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase; }

.nav1 {
  font-family: "Golos UI";
  font-size: 15px;
  font-weight: 500;
  line-height: 20px; }

.nav2 {
  font-family: "Golos UI";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; }

.page-footer {
  padding-top: 40px;
  background-color: #f8f8f7;
  background-image: url(../img/footer-bg.svg);
  background-size: 120px 116px;
  background-position: 100% 100%;
  background-repeat: no-repeat; }
  @media (min-width: 956px) {
    .page-footer {
      background-size: 178px 174px; } }
  @media (min-width: 1520px) {
    .page-footer {
      padding-top: 32px; } }
  .page-footer__header {
    padding-bottom: 24px; }
    @media (min-width: 656px) {
      .page-footer__header {
        padding-bottom: 32px; } }
    @media (min-width: 1280px) {
      .page-footer__header {
        padding-bottom: 30px; } }
  .page-footer .p-f-header__column {
    width: 100%; }
    @media (min-width: 656px) {
      .page-footer .p-f-header__column:nth-child(1) {
        width: 48.3%;
        margin-right: 3.4%; } }
    @media (min-width: 956px) {
      .page-footer .p-f-header__column:nth-child(1) {
        width: 43.1%;
        margin-right: 7.9%; } }
    @media (min-width: 1280px) {
      .page-footer .p-f-header__column:nth-child(1) {
        width: 40.5%;
        margin-right: 2%; } }
    @media (min-width: 1520px) {
      .page-footer .p-f-header__column:nth-child(1) {
        width: 33.9%;
        margin-right: 8.4%; } }
    @media (min-width: 656px) {
      .page-footer .p-f-header__column:nth-child(2) {
        width: 30.2%;
        margin-right: 3.4%; } }
    @media (min-width: 956px) {
      .page-footer .p-f-header__column:nth-child(2) {
        width: 25.5%;
        margin-right: 10.1%; } }
    @media (min-width: 1280px) {
      .page-footer .p-f-header__column:nth-child(2) {
        width: 40.5%;
        margin-right: 2%; } }
    @media (min-width: 1520px) {
      .page-footer .p-f-header__column:nth-child(2) {
        width: 32.2%;
        margin-right: 10.1%; } }
    .page-footer .p-f-header__column:nth-child(3) {
      margin-bottom: 24px; }
      @media (min-width: 656px) {
        .page-footer .p-f-header__column:nth-child(3) {
          display: flex;
          justify-content: center;
          order: 4;
          width: 100%;
          margin-bottom: 0;
          padding-top: 32px; } }
      @media (min-width: 956px) {
        .page-footer .p-f-header__column:nth-child(3) {
          display: none; } }
    @media (min-width: 656px) {
      .page-footer .p-f-header__column:nth-child(4) {
        width: 14.7%;
        order: 3; } }
    @media (min-width: 956px) {
      .page-footer .p-f-header__column:nth-child(4) {
        width: 13.4%; } }
    @media (min-width: 1280px) {
      .page-footer .p-f-header__column:nth-child(4) {
        width: 15%; } }
    @media (min-width: 1520px) {
      .page-footer .p-f-header__column:nth-child(4) {
        width: 15.2%; } }
  .page-footer .p-f-body {
    margin-bottom: 32px; }
    @media (min-width: 956px) {
      .page-footer .p-f-body {
        margin-bottom: 20px;
        padding: 40px 0;
        border-top: 1px solid rgba(43, 37, 29, 0.1);
        border-bottom: 1px solid rgba(43, 37, 29, 0.1); } }
    .page-footer .p-f-body__column {
      width: 100%;
      border-bottom: 1px solid rgba(43, 37, 29, 0.1); }
      @media (min-width: 956px) {
        .page-footer .p-f-body__column {
          border-bottom: 0; } }
      .page-footer .p-f-body__column:nth-child(1) {
        border-top: 1px solid rgba(43, 37, 29, 0.1); }
        @media (min-width: 956px) {
          .page-footer .p-f-body__column:nth-child(1) {
            width: 41.9%;
            margin-right: 9%;
            border-top: 0; } }
        @media (min-width: 1280px) {
          .page-footer .p-f-body__column:nth-child(1) {
            width: 34%;
            margin-right: 8.5%; } }
        @media (min-width: 1520px) {
          .page-footer .p-f-body__column:nth-child(1) {
            width: 36.2%;
            margin-right: 6%; } }
      @media (min-width: 956px) {
        .page-footer .p-f-body__column:nth-child(2) {
          width: 31.8%;
          margin-right: 4.3%;
          border-top: 0; } }
      @media (min-width: 1280px) {
        .page-footer .p-f-body__column:nth-child(2) {
          width: 23.5%;
          margin-right: 13.5%; } }
      @media (min-width: 1520px) {
        .page-footer .p-f-body__column:nth-child(2) {
          width: 33.9%;
          margin-right: 6.3%; } }
      .page-footer .p-f-body__column:nth-child(3) {
        display: none; }
        @media (min-width: 956px) {
          .page-footer .p-f-body__column:nth-child(3) {
            display: block;
            width: 13%; } }
        @media (min-width: 1280px) {
          .page-footer .p-f-body__column:nth-child(3) {
            width: 20.5%; } }
        @media (min-width: 1520px) {
          .page-footer .p-f-body__column:nth-child(3) {
            width: 17.6%; } }
  .page-footer__address {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #493e31; }
    @media (min-width: 656px) {
      .page-footer__address {
        text-align: left; } }
    @media (min-width: 956px) {
      .page-footer__address {
        margin-bottom: 4px;
        font-size: 24px;
        line-height: 28px; } }
  .page-footer__time {
    margin-bottom: 24px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #8b8b8d; }
    @media (min-width: 656px) {
      .page-footer__time {
        text-align: left;
        margin-bottom: 0; } }
  .page-footer__phone {
    display: block;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    color: #493e31; }
    @media (min-width: 656px) {
      .page-footer__phone {
        text-align: left; } }
    @media (min-width: 956px) {
      .page-footer__phone {
        margin-bottom: 4px;
        font-size: 24px;
        line-height: 28px; } }
  .page-footer .a-btn {
    margin: auto;
    width: 280px; }
    @media (min-width: 656px) {
      .page-footer .a-btn {
        width: 294px;
        margin: 0; } }
  .page-footer .btn-callback {
    margin: 0 auto 16px; }
    @media (min-width: 656px) {
      .page-footer .btn-callback {
        margin: 0 3.4% 0 0; } }
  .page-footer .footer-menu {
    display: none;
    padding-bottom: 24px; }
    @media (min-width: 956px) {
      .page-footer .footer-menu {
        display: flex; } }
    .page-footer .footer-menu ul {
      margin-bottom: 0;
      padding-left: 0; }
      @media (min-width: 956px) {
        .page-footer .footer-menu ul {
          margin-right: 24px; } }
      @media (min-width: 1280px) {
        .page-footer .footer-menu ul {
          margin-right: 42px; } }
      @media (min-width: 1520px) {
        .page-footer .footer-menu ul {
          margin-right: 79px; } }
    .page-footer .footer-menu li {
      padding-bottom: 12px;
      list-style: none; }
      .page-footer .footer-menu li:last-child {
        padding-bottom: 0; }
    .page-footer .footer-menu-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      cursor: pointer;
      color: #493e31; }
      @media (min-width: 956px) {
        .page-footer .footer-menu-title {
          padding-top: 0;
          padding-bottom: 20px;
          color: #8b8b8d; } }
      .page-footer .footer-menu-title:after {
        display: flex;
        content: '';
        background-image: url(../img/arrow-down.svg);
        width: 24px;
        height: 24px;
        background-size: 24px;
        background-position: 50%; }
        @media (min-width: 956px) {
          .page-footer .footer-menu-title:after {
            display: none; } }
      .page-footer .footer-menu-title--active:after {
        background-image: url(../img/arrow-up.svg); }
    .page-footer .footer-menu__link {
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #493e31;
      text-decoration: none; }
      .page-footer .footer-menu__link:hover, .page-footer .footer-menu__link:focus {
        color: #8b8b8d; }
      @media (min-width: 1520px) {
        .page-footer .footer-menu__link {
          font-size: 15px;
          line-height: 1; } }
  .page-footer .footer-copy__inner {
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media (min-width: 956px) {
      .page-footer .footer-copy__inner {
        flex-direction: row;
        order: 2;
        width: auto; } }
  .page-footer .footer-copy__link {
    display: block;
    padding-bottom: 12px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #8b8b8d;
    text-decoration: none; }
    .page-footer .footer-copy__link:hover, .page-footer .footer-copy__link:focus {
      color: #8b8b8d; }
    @media (min-width: 956px) {
      .page-footer .footer-copy__link {
        margin-right: 16px; } }
    @media (min-width: 956px) {
      .page-footer .footer-copy__link:nth-child(1) {
        order: 2; } }
    @media (min-width: 956px) {
      .page-footer .footer-copy__link:nth-child(2) {
        order: 1; } }
  @media (min-width: 956px) {
    .page-footer .footer-copy__text {
      margin-right: 16px;
      order: 1; } }
  .page-footer .footer-copy__text p {
    display: block;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #8b8b8d; }
  .page-footer .link-callback {
    display: none;
    padding-bottom: 3px;
    border-bottom: 1px solid #8b8b8d;
    font-size: 13px;
    line-height: 18px;
    color: #8b8b8d;
    text-decoration: none; }
    @media (min-width: 956px) {
      .page-footer .link-callback {
        display: inline-block; } }
  .page-footer .footer-review__img {
    display: block;
    width: 126px;
    height: 112px;
    margin-bottom: 12px;
    background-image: url(../img/review-min.svg);
    background-size: 100%;
    background-repeat: no-repeat; }
    @media (min-width: 1280px) {
      .page-footer .footer-review__img {
        width: 248px;
        height: 44px;
        background-image: url(../img/review-max.svg); } }
  .page-footer .footer-review__link {
    display: inline-block;
    padding-bottom: 4px;
    border-bottom: 1px solid #493e31;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #493e31;
    text-decoration: none; }
    @media (min-width: 1280px) {
      .page-footer .footer-review__link {
        margin-left: 55px; } }
    @media (min-width: 1520px) {
      .page-footer .footer-review__link {
        font-size: 15px;
        line-height: 1; } }

.prices {
  background-color: #fff; }
  @media (min-width: 656px) {
    .prices .inner {
      max-width: 608px; } }
  @media (min-width: 956px) {
    .prices .inner {
      min-width: 754px; } }
  @media (min-width: 1280px) {
    .prices .inner {
      max-width: 996px; } }
  @media (min-width: 1520px) {
    .prices .inner {
      max-width: 1182px; } }
  .prices h2 {
    margin-bottom: 32px;
    font-size: 38px;
    line-height: 1;
    color: #2a251d; }
    @media (min-width: 656px) {
      .prices h2 {
        max-width: 530px; } }
    @media (min-width: 956px) {
      .prices h2 {
        font-size: 52px;
        max-width: 600px; } }
    @media (min-width: 1280px) {
      .prices h2 {
        margin-bottom: 40px;
        max-width: 100%; } }
    @media (min-width: 1520px) {
      .prices h2 {
        font-size: 64px;
        max-width: 820px; } }
  .prices__list {
    padding-left: 0;
    margin-bottom: 32px;
    list-style: none; }
    @media (min-width: 956px) {
      .prices__list {
        margin-bottom: 40px; } }
  .prices__title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #2a251d; }
    @media (min-width: 656px) {
      .prices__title {
        max-width: 373px; } }
    @media (min-width: 956px) {
      .prices__title {
        max-width: 498px; } }
    @media (min-width: 1280px) {
      .prices__title {
        max-width: 673px; } }
  .prices__value {
    display: flex;
    align-items: baseline;
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    color: #493e31; }
    @media (min-width: 956px) {
      .prices__value {
        font-size: 24px; } }
    @media (min-width: 1520px) {
      .prices__value {
        font-size: 30px; } }
    .prices__value span {
      margin-right: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      text-decoration-line: line-through;
      color: #8b8b8d; }
  .prices__item {
    padding: 24px 16px;
    background-color: white;
    position: relative;
    align-items: baseline; }
    @media (min-width: 656px) {
      .prices__item {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
    .prices__item--head {
      display: none;
      padding-top: 0;
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 12px; }
      .prices__item--head .prices__title, .prices__item--head .prices__value {
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #8b8b8d; }
    .prices__item:nth-child(even) {
      background-color: #fbf8f4; }
  .prices .tooltip {
    cursor: pointer;
    margin-left: 12px; }
    .prices .tooltip:hover .tooltip__desc {
      visibility: visible;
      opacity: 1;
      width: 172px;
      padding: 16px;
      background-color: #fff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
      border: 1px solid #fff; }
      .prices .tooltip:hover .tooltip__desc:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 4px;
        border-style: solid;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
        border-color: #ffffff transparent transparent transparent; }
    .prices .tooltip__icon {
      width: 20px;
      height: 26px;
      background-image: url(../img/tooltip.svg);
      background-size: 20px 26px;
      background-repeat: no-repeat; }
    .prices .tooltip__desc {
      visibility: hidden;
      position: absolute;
      z-index: 1;
      bottom: 64px;
      right: 0;
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #493e31;
      text-align: left; }
      @media (min-width: 656px) {
        .prices .tooltip__desc {
          bottom: 44px; } }
      @media (min-width: 956px) {
        .prices .tooltip__desc {
          right: 0px;
          bottom: 72px; } }
  .prices .a-btn {
    height: 44px; }
    @media (min-width: 656px) {
      .prices .a-btn {
        width: 179px;
        margin: auto; } }

.promo {
  background-color: #c4b097;
  color: white; }
  @media (min-width: 1520px) {
    .promo {
      max-width: 1560px;
      margin: auto; } }
  @media (min-width: 956px) {
    .promo__body {
      padding-left: 8.4%; } }
  @media (min-width: 1520px) {
    .promo__body {
      padding-left: 169px; } }
  .promo__inner {
    padding-top: 32px;
    padding-bottom: 80px;
    background-image: url(../img/pr-bg-sm.svg);
    background-size: 305px 241px;
    background-repeat: no-repeat;
    background-position: 100% 100%; }
    @media (min-width: 656px) {
      .promo__inner {
        padding-bottom: 56px;
        background-image: url(../img/pr-bg.svg);
        background-size: 421px 315px; } }
    @media (min-width: 956px) {
      .promo__inner {
        background-size: 497px 356px;
        background-position: 100% 100%; } }
    @media (min-width: 1520px) {
      .promo__inner {
        padding-bottom: 96px;
        margin: 0;
        max-width: 100%; } }
  @media (min-width: 1520px) {
    .promo .inner {
      margin: 0;
      max-width: 100%; } }
  @media (min-width: 1520px) {
    .promo .inner-breadcrumbs {
      padding-left: 48px; } }
  .promo .breadcrumbs {
    margin-bottom: 56px; }
    @media (min-width: 656px) {
      .promo .breadcrumbs {
        margin-bottom: 38px; } }
    @media (min-width: 1280px) {
      .promo .breadcrumbs {
        margin-bottom: 68px; } }
    @media (min-width: 1520px) {
      .promo .breadcrumbs {
        margin-bottom: 78px; } }
  .promo h1 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px; }
    @media (min-width: 956px) {
      .promo h1 {
        max-width: 367px;
        font-size: 72px;
        line-height: 66px; } }
    @media (min-width: 1280px) {
      .promo h1 {
        max-width: 487px; } }
    @media (min-width: 1520px) {
      .promo h1 {
        max-width: 580px;
        font-size: 80px;
        line-height: 74px; } }
  .promo .subtitle {
    max-width: 294px;
    padding-top: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px; }
    @media (min-width: 956px) {
      .promo .subtitle {
        max-width: 487px;
        padding-top: 24px;
        font-size: 20px; } }
    @media (min-width: 1280px) {
      .promo .subtitle {
        padding-top: 20px; } }
    @media (min-width: 1520px) {
      .promo .subtitle {
        max-width: 579px;
        padding-top: 24px; } }
  .promo .a-btn {
    width: 157px;
    height: 44px;
    margin-top: 24px; }
    @media (min-width: 956px) {
      .promo .a-btn {
        margin-top: 40px; } }
    @media (min-width: 1520px) {
      .promo .a-btn {
        margin-top: 32px; } }
  .promo .page__title {
    position: relative; }
  .promo__back {
    display: none; }
    @media (min-width: 956px) {
      .promo__back {
        display: block;
        position: absolute;
        top: 21%;
        left: -9%;
        width: 22px;
        height: 15px;
        background-image: url(../img/back.svg);
        background-size: 22px 15px;
        background-repeat: no-repeat; } }
  .promo--simple .promo__inner {
    background-image: none; }
  .promo--service {
    background-image: url(../img/bg-pr-ser-min.svg);
    background-size: 320px 273px;
    background-repeat: no-repeat;
    background-position: 0 0; }
    @media (min-width: 956px) {
      .promo--service {
        background-image: url(../img/bg-pr-ser.svg);
        background-size: 661px 435px; } }
    .promo--service .promo__inner {
      padding-bottom: 280px;
      background-image: url(../img/service.png);
      background-size: 280px 256px;
      background-repeat: no-repeat;
      background-position: 0 100%; }
      @media (min-width: 656px) {
        .promo--service .promo__inner {
          padding-bottom: 56px;
          background-size: 345px 311px;
          background-position: 100% 100%; } }
      @media (min-width: 956px) {
        .promo--service .promo__inner {
          background-size: 468px 454px; } }
      @media (min-width: 1280px) {
        .promo--service .promo__inner {
          padding-top: 56px;
          padding-bottom: 80px;
          background-size: 587px 540px; } }
      @media (min-width: 1520px) {
        .promo--service .promo__inner {
          padding-bottom: 96px;
          background-size: 700px 640px; } }
    @media (min-width: 1280px) {
      .promo--service .breadcrumbs {
        margin-bottom: 164px; } }
    @media (min-width: 1520px) {
      .promo--service .breadcrumbs {
        margin-bottom: 244px; } }

.promo-doctor {
  padding-top: 81px;
  background-color: #fbf8f4; }
  @media (min-width: 956px) {
    .promo-doctor {
      padding-top: 81.65px; } }
  @media (min-width: 1520px) {
    .promo-doctor {
      max-width: 1520px;
      margin: auto; } }
  .promo-doctor__inner {
    position: relative; }
    @media (min-width: 1280px) {
      .promo-doctor__inner {
        padding-top: 56px;
        display: flex;
        max-width: 89.4%;
        margin: 0 0 0 10.6%;
        background: linear-gradient(to right, #fbf8f4 50%, #c4b097 50% 100%); } }
    @media (min-width: 1520px) {
      .promo-doctor__inner {
        max-width: 1472px;
        margin: 0 0 0 142px; } }
  .promo-doctor__column:nth-child(1) {
    background-color: #fbf8f4; }
    @media (min-width: 956px) {
      .promo-doctor__column:nth-child(1) {
        padding-bottom: 56px; } }
    @media (min-width: 1280px) {
      .promo-doctor__column:nth-child(1) {
        width: 50%; } }
    .promo-doctor__column:nth-child(1) .promo-doctor__wrapper {
      max-width: 87.5%;
      margin: auto; }
      @media (min-width: 656px) {
        .promo-doctor__column:nth-child(1) .promo-doctor__wrapper {
          max-width: 92.7%;
          display: flex; } }
      @media (min-width: 956px) {
        .promo-doctor__column:nth-child(1) .promo-doctor__wrapper {
          max-width: 89.4%;
          margin: 0 0 0 10.6%; } }
      @media (min-width: 1280px) {
        .promo-doctor__column:nth-child(1) .promo-doctor__wrapper {
          max-width: 100%;
          margin: 0; } }
  .promo-doctor__column:nth-child(2) {
    background-color: #c4b097;
    background-image: url(../img/quotes-min.svg);
    background-repeat: no-repeat;
    background-size: 102px 64px;
    background-position: 4% 24px; }
    @media (min-width: 956px) {
      .promo-doctor__column:nth-child(2) {
        background-position: 9% 24px; } }
    @media (min-width: 1280px) {
      .promo-doctor__column:nth-child(2) {
        padding-left: 216px;
        background-image: url(../img/quotes.svg);
        background-repeat: no-repeat;
        background-size: 159px 101px;
        background-position: 50% 35%;
        width: 50%; } }
    @media (min-width: 1520px) {
      .promo-doctor__column:nth-child(2) {
        padding-left: 252px;
        background-image: url(../img/quotes.svg);
        background-repeat: no-repeat;
        background-size: 159px 101px;
        background-position: 50% 30%; } }
    .promo-doctor__column:nth-child(2) .promo-doctor__wrapper {
      max-width: 87.5%;
      margin: auto; }
      @media (min-width: 656px) {
        .promo-doctor__column:nth-child(2) .promo-doctor__wrapper {
          max-width: 92.7%; } }
      @media (min-width: 956px) {
        .promo-doctor__column:nth-child(2) .promo-doctor__wrapper {
          max-width: 89.4%;
          margin: 0 0 0 10.6%; } }
      @media (min-width: 1280px) {
        .promo-doctor__column:nth-child(2) .promo-doctor__wrapper {
          max-width: 100%;
          margin: 0; } }
  @media (min-width: 1280px) {
    .promo-doctor__body {
      padding-bottom: 130px; } }
  @media (min-width: 1520px) {
    .promo-doctor__body {
      padding-bottom: 182px; } }
  @media (min-width: 656px) {
    .promo-doctor__left {
      width: 50%; } }
  @media (min-width: 956px) {
    .promo-doctor__left {
      width: 100%; } }
  @media (min-width: 656px) {
    .promo-doctor__right {
      width: 50%; } }
  @media (min-width: 956px) {
    .promo-doctor__right {
      position: absolute;
      width: auto;
      right: 3%;
      bottom: 0; } }
  @media (min-width: 1280px) {
    .promo-doctor__right {
      right: 36%; } }
  .promo-doctor .page__title {
    margin-top: 56px; }
    @media (min-width: 656px) {
      .promo-doctor .page__title {
        margin-top: 38px; } }
    @media (min-width: 1280px) {
      .promo-doctor .page__title {
        margin-top: 74px; } }
    @media (min-width: 656px) {
      .promo-doctor .page__title {
        margin-top: 78px; } }
  .promo-doctor h1 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px; }
    @media (min-width: 656px) {
      .promo-doctor h1 {
        max-width: 294px; } }
    @media (min-width: 956px) {
      .promo-doctor h1 {
        max-width: 367px;
        font-size: 72px;
        line-height: 66px; } }
    @media (min-width: 1280px) {
      .promo-doctor h1 {
        max-width: 487px; } }
    @media (min-width: 1520px) {
      .promo-doctor h1 {
        max-width: 580px;
        font-size: 80px;
        line-height: 74px; } }
  .promo-doctor .subtitle {
    max-width: 294px;
    padding-top: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px; }
    @media (min-width: 956px) {
      .promo-doctor .subtitle {
        max-width: 487px;
        padding-top: 24px;
        font-size: 20px; } }
    @media (min-width: 1280px) {
      .promo-doctor .subtitle {
        padding-top: 20px; } }
    @media (min-width: 1520px) {
      .promo-doctor .subtitle {
        max-width: 579px;
        padding-top: 24px; } }
  .promo-doctor .a-btn {
    width: 195px;
    height: 44px;
    margin-top: 24px; }
    @media (min-width: 956px) {
      .promo-doctor .a-btn {
        margin-top: 40px; } }
    @media (min-width: 1520px) {
      .promo-doctor .a-btn {
        margin-top: 32px; } }
  .promo-doctor .page__title {
    position: relative; }
  .promo-doctor__back {
    display: none; }
    @media (min-width: 956px) {
      .promo-doctor__back {
        display: block;
        position: absolute;
        top: 21%;
        left: -9%;
        width: 22px;
        height: 15px;
        background-image: url(../img/back.svg);
        background-size: 22px 15px;
        background-repeat: no-repeat; } }
  .promo-doctor__img {
    padding-top: 24px; }
    @media (min-width: 656px) {
      .promo-doctor__img {
        display: flex;
        justify-content: flex-end; } }
    .promo-doctor__img img {
      display: block;
      margin: auto; }
      @media (min-width: 656px) {
        .promo-doctor__img img {
          width: 215px;
          height: 262px;
          margin: 0; } }
      @media (min-width: 956px) {
        .promo-doctor__img img {
          width: 367px;
          height: 449px; } }
      @media (min-width: 1280px) {
        .promo-doctor__img img {
          width: 384px;
          height: 470px; } }
      @media (min-width: 1520px) {
        .promo-doctor__img img {
          width: 458px;
          height: 560px; } }
  .promo-doctor .promo-quote {
    margin-bottom: 0;
    padding-top: 52px;
    padding-bottom: 32px;
    font-size: 18px;
    line-height: 26px;
    color: white; }
    @media (min-width: 656px) {
      .promo-doctor .promo-quote {
        padding-top: 44px;
        max-width: 530px; } }
    @media (min-width: 956px) {
      .promo-doctor .promo-quote {
        font-size: 20px;
        line-height: 26px;
        max-width: 367px; } }
    @media (min-width: 1280px) {
      .promo-doctor .promo-quote {
        padding-top: 192px;
        max-width: 282px; } }
    @media (min-width: 1520px) {
      .promo-doctor .promo-quote {
        max-width: 339px; } }

.promo-slider {
  position: relative;
  background: linear-gradient(180.68deg, #e6e8e7 0.96%, #e2e3e5 99.79%); }
  @media (min-width: 1520px) {
    .promo-slider {
      margin: auto;
      max-width: 1520px; } }
  .promo-slider .slick-dots {
    padding-left: 0;
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    bottom: 1%;
    text-align: center; }
    @media (min-width: 656px) {
      .promo-slider .slick-dots {
        bottom: 3%; } }
    .promo-slider .slick-dots li {
      list-style: none;
      display: inline-block; }
    .promo-slider .slick-dots button {
      width: 16px;
      height: 16px;
      background-color: transparent;
      background-image: url(../img/dot.svg);
      background-size: 16px 16px;
      background-repeat: no-repeat;
      font-size: 0;
      border: none; }
    .promo-slider .slick-dots .slick-active button {
      background-image: url(../img/dot-active.svg); }
  .promo-slider .promo-slide {
    background: linear-gradient(180.68deg, #E6E8E7 0.96%, #E2E3E5 99.79%); }
    .promo-slider .promo-slide__inner {
      padding-top: 56px;
      max-width: 87.5%;
      margin: auto; }
      @media (min-width: 656px) {
        .promo-slider .promo-slide__inner {
          display: flex;
          justify-content: space-between;
          padding-top: 40px;
          max-width: 92.7%; } }
      @media (min-width: 956px) {
        .promo-slider .promo-slide__inner {
          padding-top: 0;
          margin: 0 0 0 10.3%;
          max-width: 89.7%; } }
      @media (min-width: 1280px) {
        .promo-slider .promo-slide__inner {
          margin: 0 0 0 11.1%;
          max-width: 88.9%; } }
      @media (min-width: 1520px) {
        .promo-slider .promo-slide__inner {
          margin: 0 0 0 169px;
          max-width: 100%; } }
    .promo-slider .promo-slide__title {
      font-family: "KudryashevDisplay-Sans";
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 44px;
      line-height: 44px;
      color: #2a251d;
      min-height: 90px; }
      @media (min-width: 956px) {
        .promo-slider .promo-slide__title {
          margin-bottom: 24px;
          font-size: 64px;
          line-height: 60px;
          max-width: 367px; } }
      @media (min-width: 1280px) {
        .promo-slider .promo-slide__title {
          max-width: 486px; } }
      @media (min-width: 1520px) {
        .promo-slider .promo-slide__title {
          font-size: 80px;
          line-height: 74px;
          max-width: 486px; } }
    .promo-slider .promo-slide .subtitle {
      margin-bottom: 24px;
      min-height: 60px;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      color: #493e31; }
      @media (min-width: 956px) {
        .promo-slider .promo-slide .subtitle {
          margin-bottom: 32px;
          font-size: 20px;
          line-height: 26px;
          max-width: 367px; } }
      @media (min-width: 1280px) {
        .promo-slider .promo-slide .subtitle {
          max-width: 384px; } }
      @media (min-width: 1520px) {
        .promo-slider .promo-slide .subtitle {
          max-width: 458px; } }
    .promo-slider .promo-slide__link {
      margin-bottom: 24px;
      display: flex;
      align-items: end;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      color: #2a251d;
      text-decoration: none; }
      @media (min-width: 656px) {
        .promo-slider .promo-slide__link {
          margin-bottom: 0; } }
      .promo-slider .promo-slide__link:after {
        content: "";
        display: flex;
        width: 24px;
        height: 18px;
        margin-left: 12px;
        background-image: url(../img/link.svg);
        background-repeat: no-repeat;
        background-size: 24px 18px; }
    .promo-slider .promo-slide img {
      margin: auto;
      display: block; }
      @media (min-width: 656px) {
        .promo-slider .promo-slide img {
          margin: 0;
          width: 294px;
          height: 336px; } }
      @media (min-width: 956px) {
        .promo-slider .promo-slide img {
          width: 468px;
          height: 450px; } }
      @media (min-width: 1280px) {
        .promo-slider .promo-slide img {
          width: 628px;
          height: 540px; } }
      @media (min-width: 1520px) {
        .promo-slider .promo-slide img {
          width: 700px;
          height: 640px; } }
    @media (min-width: 656px) {
      .promo-slider .promo-slide__column {
        width: 50%; } }
    @media (min-width: 656px) {
      .promo-slider .promo-slide__column:first-child {
        padding-top: 80px;
        padding-bottom: 56px; } }
    @media (min-width: 956px) {
      .promo-slider .promo-slide__column:first-child {
        padding-top: 172px; } }
    @media (min-width: 1280px) {
      .promo-slider .promo-slide__column:first-child {
        padding-top: 224px;
        padding-bottom: 82px; } }
    @media (min-width: 1520px) {
      .promo-slider .promo-slide__column:first-child {
        padding-top: 294px;
        padding-bottom: 96px; } }
    @media (min-width: 656px) {
      .promo-slider .promo-slide__column:last-child {
        display: flex;
        justify-content: flex-end; } }

.promotions .promotion {
  margin-bottom: 24px;
  flex-basis: 280px;
  width: 280px; }
  @media (min-width: 656px) {
    .promotions .promotion {
      margin-right: 24px;
      flex-basis: 294px;
      width: 294px; } }
  @media (min-width: 956px) {
    .promotions .promotion {
      flex-basis: 367px;
      width: 367px; } }
  @media (min-width: 1280px) {
    .promotions .promotion {
      flex-basis: 486px;
      width: 486px; } }
  @media (min-width: 1520px) {
    .promotions .promotion {
      flex-basis: 579px;
      width: 579px; } }
  @media (min-width: 656px) {
    .promotions .promotion:nth-child(2n) {
      margin-right: 0; } }

.promotions__inner {
  flex-direction: column; }
  @media (min-width: 656px) {
    .promotions__inner {
      flex-direction: row;
      justify-content: center;
      max-width: 612px; } }
  @media (min-width: 956px) {
    .promotions__inner {
      max-width: 758px; } }
  @media (min-width: 1280px) {
    .promotions__inner {
      max-width: 996px; } }
  @media (min-width: 1520px) {
    .promotions__inner {
      max-width: 1182px; } }

@media (min-width: 1520px) {
  .promotions-slider {
    max-width: 1520px;
    margin: auto; } }

.promotions-slider .promotion {
  display: block;
  width: 280px; }
  @media (min-width: 656px) {
    .promotions-slider .promotion {
      width: 294px; } }
  @media (min-width: 956px) {
    .promotions-slider .promotion {
      width: 367px; } }
  @media (min-width: 1280px) {
    .promotions-slider .promotion {
      width: 486px; } }
  @media (min-width: 1520px) {
    .promotions-slider .promotion {
      width: 579px; } }

.promotions-slider .promotions-arrows.section-arrows {
  display: flex;
  justify-content: center;
  margin-top: 40px; }
  @media (min-width: 656px) {
    .promotions-slider .promotions-arrows.section-arrows {
      margin-top: 32px; } }
  @media (min-width: 956px) {
    .promotions-slider .promotions-arrows.section-arrows {
      display: none; } }

.promotions-slider__inner {
  max-width: 87.5%;
  margin: auto; }
  @media (min-width: 656px) {
    .promotions-slider__inner {
      max-width: 92.7%; } }
  @media (min-width: 956px) {
    .promotions-slider__inner {
      max-width: 89.4%;
      margin: 0 0 0 10.6%; } }
  @media (min-width: 1280px) {
    .promotions-slider__inner {
      max-width: 89.4%;
      margin: 0 0 0 10.6%; } }
  @media (min-width: 1520px) {
    .promotions-slider__inner {
      max-width: 1472px;
      margin: 0 0 0 142px; } }

.promotions-slider__wrapper {
  width: 100%; }
  @media (min-width: 956px) {
    .promotions-slider__wrapper {
      padding-right: 11.9%; } }
  @media (min-width: 1280px) {
    .promotions-slider__wrapper {
      padding-right: 12.4%; } }
  @media (min-width: 1520px) {
    .promotions-slider__wrapper {
      padding-right: 169px; } }

@media (min-width: 1280px) {
  .promotions-slider .section-header {
    margin-bottom: 32px; } }

@media (min-width: 1520px) {
  .promotions-slider .section-header {
    margin-bottom: 40px; } }

.promotions-slider .p-slider__item {
  margin-right: 20px; }
  @media (min-width: 1280px) {
    .promotions-slider .p-slider__item {
      margin-right: 24px; } }

.review {
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(42, 37, 29, 0.1);
  color: #493e31; }
  @media (min-width: 956px) {
    .review {
      padding-top: 40px; } }
  @media (min-width: 1280px) {
    .review {
      padding-bottom: 40px; } }
  .review:nth-child(1) {
    border-top: 1px solid rgba(42, 37, 29, 0.1); }
  .review__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #493e31; }
    @media (min-width: 656px) {
      .review__body {
        padding-left: 18px;
        max-width: 510px; } }
    @media (min-width: 956px) {
      .review__body {
        padding-left: 0;
        max-width: 676px; } }
    @media (min-width: 1280px) {
      .review__body {
        max-width: 793px; } }
    @media (min-width: 1520px) {
      .review__body {
        max-width: 820px; } }
  .review__meta {
    display: flex;
    align-items: baseline;
    padding-top: 16px; }
    @media (min-width: 656px) {
      .review__meta {
        padding-top: 32px;
        padding-left: 18px; } }
    @media (min-width: 956px) {
      .review__meta {
        padding-top: 16px;
        padding-left: 0; } }
    @media (min-width: 1280px) {
      .review__meta {
        padding-top: 24px; } }
  .review__name {
    margin-right: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    color: #493e31; }
  .review__date {
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: #493e31; }

.reviews__list {
  margin-bottom: 8px; }
  @media (min-width: 956px) {
    .reviews__list {
      margin-bottom: 12px; } }
  @media (min-width: 1280px) {
    .reviews__list {
      margin-bottom: 16px; } }

@media (min-width: 656px) {
  .reviews__inner {
    max-width: 608px; } }

@media (min-width: 956px) {
  .reviews__inner {
    max-width: 754px; } }

@media (min-width: 1280px) {
  .reviews__inner {
    max-width: 998px; } }

@media (min-width: 1520px) {
  .reviews__inner {
    max-width: 1182px; } }

.reviews .reviews-pagination {
  display: flex;
  justify-content: center; }
  @media (min-width: 956px) {
    .reviews .reviews-pagination {
      justify-content: flex-start; } }
  .reviews .reviews-pagination__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 44px;
    width: 42px;
    height: 42px;
    font-size: 15px;
    line-height: 1;
    text-decoration: none;
    color: #8b8b8d;
    border: 2px solid transparent;
    border-radius: 50%; }
  .reviews .reviews-pagination__link--active {
    color: #2a251d;
    border: 2px solid #b8232d; }

@media (min-width: 1520px) {
  .reviews-slider {
    max-width: 1520px;
    margin: auto; } }

.reviews-slider__inner {
  max-width: 87.5%;
  margin: auto; }
  @media (min-width: 656px) {
    .reviews-slider__inner {
      max-width: 92.7%; } }
  @media (min-width: 956px) {
    .reviews-slider__inner {
      max-width: 89.4%;
      margin: 0 0 0 10.6%; } }
  @media (min-width: 1280px) {
    .reviews-slider__inner {
      max-width: 89.4%;
      margin: 0 0 0 10.6%; } }
  @media (min-width: 1520px) {
    .reviews-slider__inner {
      max-width: 1472px;
      margin: 0 0 0 142px; } }

.reviews-slider__wrapper {
  width: 100%; }
  @media (min-width: 956px) {
    .reviews-slider__wrapper {
      padding-right: 11.9%; } }
  @media (min-width: 1280px) {
    .reviews-slider__wrapper {
      padding-right: 12.4%; } }
  @media (min-width: 1520px) {
    .reviews-slider__wrapper {
      padding-right: 169px; } }

.reviews-slider .slick-dots {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding-left: 0; }
  @media (min-width: 956px) {
    .reviews-slider .slick-dots {
      justify-content: flex-start; } }
  .reviews-slider .slick-dots li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 44px;
    width: 42px;
    height: 42px;
    border: 2px solid transparent;
    border-radius: 50%; }
    .reviews-slider .slick-dots li a {
      font-size: 15px;
      line-height: 1;
      text-decoration: none;
      color: #8b8b8d; }
  .reviews-slider .slick-dots .slick-active {
    border: 2px solid #b8232d; }
    .reviews-slider .slick-dots .slick-active a {
      color: #2a251d; }

.services {
  background-color: #c4b097; }
  @media (min-width: 956px) {
    .services {
      background-color: transparent; } }
  @media (min-width: 1520px) {
    .services {
      margin: auto;
      padding-left: 48px;
      width: 1520px;
      max-width: 1520px; } }
  .services .services-arrows.section-arrows {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
    @media (min-width: 656px) {
      .services .services-arrows.section-arrows {
        margin-top: 32px; } }
    @media (min-width: 956px) {
      .services .services-arrows.section-arrows {
        display: none; } }
  .services__inner {
    max-width: 87.5%;
    margin: auto;
    padding-top: 48px;
    padding-bottom: 48px; }
    @media (min-width: 656px) {
      .services__inner {
        max-width: 92.7%; } }
    @media (min-width: 956px) {
      .services__inner {
        max-width: 100%;
        background-color: #c4b097;
        max-width: 97.5%;
        margin: 0 0 0 2.5%;
        padding-left: 8%; } }
    @media (min-width: 1280px) {
      .services__inner {
        max-width: 96.9%;
        margin: 0 0 0 3.1%;
        padding-left: 7.9%; } }
    @media (min-width: 1520px) {
      .services__inner {
        max-width: 1472px;
        width: 1472px;
        margin: 0; } }
  .services__wrapper {
    width: 100%; }
    @media (min-width: 956px) {
      .services__wrapper {
        padding-right: 10.6%; } }
    @media (min-width: 1280px) {
      .services__wrapper {
        padding-right: 11.1%; } }
  .services h2 {
    color: white; }
  @media (min-width: 1280px) {
    .services .section-header {
      margin-bottom: 40px; } }
  @media (min-width: 1280px) {
    .services .section-header {
      margin-bottom: 48px; } }
  .services .service-slider__item {
    margin-right: 16px; }
    @media (min-width: 656px) {
      .services .service-slider__item {
        margin-right: 20px; } }
    @media (min-width: 1280px) {
      .services .service-slider__item {
        margin-right: 24px; } }
  .services .service-list {
    display: flex;
    flex-wrap: wrap; }
    .services .service-list .service {
      margin-bottom: 24px; }
      @media (min-width: 656px) {
        .services .service-list .service {
          width: 180px;
          margin-bottom: 0; } }
      @media (min-width: 956px) {
        .services .service-list .service {
          width: 212px; } }
      @media (min-width: 1280px) {
        .services .service-list .service {
          width: 282px; } }
      @media (min-width: 1520px) {
        .services .service-list .service {
          width: 338px; } }
    .services .service-list__item {
      margin-right: 16px; }
      .services .service-list__item:last-child {
        margin-right: 0; }
      @media (min-width: 656px) {
        .services .service-list__item {
          margin-right: 20px; } }
      @media (min-width: 1280px) {
        .services .service-list__item {
          margin-right: 24px; } }

.site-panel {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px; }
  @media (min-width: 956px) {
    .site-panel {
      display: none; } }
  .site-panel__inner {
    display: flex; }
  .site-panel__btn {
    width: 50%;
    color: white; }
    .site-panel__btn:first-child {
      background-color: #b8232d; }
    .site-panel__btn:last-child {
      background-color: #493e31; }
    .site-panel__btn:hover {
      color: white; }

.slider-photo {
  position: relative; }
  @media (min-width: 1600px) {
    .slider-photo {
      max-width: 1424px;
      margin: auto; } }
  .slider-photo .slider-item {
    max-width: 367px; }
    @media (min-width: 800px) {
      .slider-photo .slider-item {
        max-width: 486px; } }
    @media (min-width: 956px) {
      .slider-photo .slider-item {
        max-width: 367px; } }
    @media (min-width: 1280px) {
      .slider-photo .slider-item {
        max-width: 486px; } }
    @media (min-width: 1520px) {
      .slider-photo .slider-item {
        max-width: 579px; } }
  @media (min-width: 956px) {
    .slider-photo__inner {
      max-width: 100%;
      margin: 0;
      padding-left: 11.1%; } }
  @media (min-width: 1520px) {
    .slider-photo__inner {
      padding-left: 168px; } }
  .slider-photo .slick-slide {
    text-align: center; }
    @media (min-width: 656px) {
      .slider-photo .slick-slide {
        margin: 0 20px 0 0; } }
  .slider-photo .slick-arrow {
    background-color: transparent;
    border: none;
    cursor: pointer; }
  .slider-photo__arrows {
    padding-top: 24px;
    text-align: center; }
    @media (min-width: 656px) {
      .slider-photo__arrows {
        padding-top: 32px; } }
    @media (min-width: 956px) {
      .slider-photo__arrows {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 43%;
        left: 9%;
        padding-top: 0;
        width: 796px; } }
    @media (min-width: 1280px) {
      .slider-photo__arrows {
        width: 1049px; } }
    @media (min-width: 1520px) {
      .slider-photo__arrows {
        width: 1234px; } }
  .slider-photo__prev {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    background-image: url(../img/photos/left.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat; }
    @media (min-width: 1280px) {
      .slider-photo__prev {
        width: 48px;
        height: 48px;
        margin-right: 0;
        background-size: 48px 48px; } }
  .slider-photo__next {
    width: 40px;
    height: 40px;
    background-image: url(../img/photos/right.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat; }
    @media (min-width: 1280px) {
      .slider-photo__next {
        width: 48px;
        height: 48px;
        margin-right: 0;
        background-size: 48px 48px;
        right: 0; } }

@media (min-width: 1280px) {
  .start__inner {
    display: flex;
    min-height: 100vh; } }

@media (min-width: 1520px) {
  .start__inner {
    max-width: 1520px;
    margin: auto; } }

.start__column:nth-child(1) {
  position: relative;
  padding-top: 72px;
  padding-bottom: 449px;
  background: linear-gradient(222.08deg, #E1E3E1 34.29%, #E3E3E3 115.33%); }
  @media (min-width: 656px) {
    .start__column:nth-child(1) {
      padding-bottom: 0;
      padding-top: 0; } }
  @media (min-width: 1280px) {
    .start__column:nth-child(1) {
      width: 66.9%; } }
  @media (min-width: 1280px) {
    .start__column:nth-child(1) .inner {
      max-width: 100%;
      padding-left: 40px;
      margin: 0; } }

.start__column:nth-child(2) {
  padding-top: 48px;
  padding-bottom: 32px; }
  @media (min-width: 656px) {
    .start__column:nth-child(2) {
      padding-top: 52px;
      padding-bottom: 52px; } }
  @media (min-width: 956px) {
    .start__column:nth-child(2) {
      padding-top: 44px;
      padding-bottom: 32px; } }
  @media (min-width: 1280px) {
    .start__column:nth-child(2) {
      width: 33.1%; } }
  @media (min-width: 1280px) {
    .start__column:nth-child(2) .inner {
      max-width: 100%;
      padding-right: 40px;
      padding-left: 32px;
      margin: 0; } }

@media (min-width: 656px) {
  .start .start-promo {
    display: flex;
    justify-content: space-between; } }

.start .start-promo__column:nth-child(1) {
  padding-top: 54px; }
  @media (min-width: 656px) {
    .start .start-promo__column:nth-child(1) {
      padding-top: 52px;
      max-width: 294px; } }
  @media (min-width: 956px) {
    .start .start-promo__column:nth-child(1) {
      padding-top: 90px;
      max-width: 488px; } }
  @media (min-width: 1280px) {
    .start .start-promo__column:nth-child(1) {
      padding-top: 430px; } }
  @media (min-width: 1520px) {
    .start .start-promo__column:nth-child(1) {
      max-width: 579px; } }

.start .start-promo__address {
  margin-bottom: 22px;
  display: inline-block;
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  color: #B6232D;
  letter-spacing: .05em;
  text-transform: uppercase;
  background-color: #B39A7A; }
  @media (min-width: 956px) {
    .start .start-promo__address {
      margin-bottom: 26px; } }

.start .start-promo__title {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 32px;
  line-height: 34px;
  color: #2B251D; }
  @media (min-width: 656px) {
    .start .start-promo__title {
      margin-bottom: 16px; } }
  @media (min-width: 956px) {
    .start .start-promo__title {
      font-size: 36px;
      line-height: 46px;
      margin-bottom: 20px; } }
  @media (min-width: 1520px) {
    .start .start-promo__title {
      margin-bottom: 24px;
      font-size: 44px;
      line-height: 48px; } }

.start .start-promo__desc {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #493E31; }
  @media (min-width: 956px) {
    .start .start-promo__desc {
      font-size: 20px;
      max-width: 384px; } }
  @media (min-width: 1520px) {
    .start .start-promo__desc {
      max-width: 459px; } }

.start .start-promo img {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  max-width: 280px; }
  @media (min-width: 656px) {
    .start .start-promo img {
      position: static;
      max-width: 256px; } }
  @media (min-width: 1280px) {
    .start .start-promo img {
      position: absolute;
      right: 0;
      left: auto;
      max-width: 404px; } }
  @media (min-width: 1520px) {
    .start .start-promo img {
      max-width: 475px; } }

@media (min-width: 656px) {
  .start .start-list {
    display: flex;
    flex-wrap: wrap; } }

@media (min-width: 1280px) {
  .start .start-list {
    flex-direction: column; } }

.start .start-clinic {
  margin-bottom: 83px; }
  @media (min-width: 656px) {
    .start .start-clinic {
      max-width: 294px;
      margin-right: 20px;
      margin-bottom: 107px; } }
  @media (min-width: 956px) {
    .start .start-clinic {
      margin-bottom: 0;
      max-width: 288px; } }
  @media (min-width: 1280px) {
    .start .start-clinic {
      margin-right: 0;
      margin-bottom: 66px;
      max-width: 352px; } }
  @media (min-width: 1520px) {
    .start .start-clinic {
      max-width: 425px; } }
  @media (min-width: 656px) {
    .start .start-clinic:nth-child(2) {
      margin-right: 0; } }
  @media (min-width: 956px) {
    .start .start-clinic:nth-child(2) {
      margin-right: 20px; } }
  @media (min-width: 1280px) {
    .start .start-clinic:nth-child(2) {
      margin-right: 0; } }
  .start .start-clinic:last-child {
    margin-bottom: 0; }
    @media (min-width: 956px) {
      .start .start-clinic:last-child {
        margin-right: 0; } }
  .start .start-clinic__address {
    margin-bottom: 22px;
    display: inline-block;
    font-size: 12px;
    font-weight: 900;
    line-height: 16px;
    color: #B6232D;
    letter-spacing: .05em;
    text-transform: uppercase;
    background-color: #B39A7A; }
  .start .start-clinic h2 {
    margin-bottom: 16px;
    font-family: "Golos UI";
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #2B251D; }
    @media (min-width: 956px) {
      .start .start-clinic h2 {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 28px; } }
    @media (min-width: 1520px) {
      .start .start-clinic h2 {
        margin-bottom: 16px;
        font-size: 30px;
        line-height: 34px; } }
  .start .start-clinic__desc {
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #493E31; }
    @media (min-width: 1280px) {
      .start .start-clinic__desc {
        margin-bottom: 46px; } }
    @media (min-width: 1520px) {
      .start .start-clinic__desc {
        margin-bottom: 44px; } }
  .start .start-clinic__link {
    display: flex;
    align-items: end;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: #2B251D;
    text-decoration: none; }
    .start .start-clinic__link:after {
      content: "";
      display: flex;
      width: 24px;
      height: 18px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px; }

@media (min-width: 1520px) {
  .submenu {
    margin: auto;
    padding-left: 48px;
    width: 1520px;
    max-width: 1520px; } }

.submenu__inner {
  max-width: 87.5%;
  margin: auto;
  margin-bottom: 32px; }
  @media (min-width: 656px) {
    .submenu__inner {
      max-width: 92.7%; } }
  @media (min-width: 956px) {
    .submenu__inner {
      max-width: 100%;
      max-width: 97.5%;
      margin: 0 0 40px 2.5%;
      padding-left: 8%; } }
  @media (min-width: 1280px) {
    .submenu__inner {
      max-width: 96.9%;
      margin: 0 0 40px 3.1%;
      padding-left: 7.9%; } }
  @media (min-width: 1520px) {
    .submenu__inner {
      max-width: 1472px;
      width: 1472px;
      margin: 0 0 40px 0; } }

.submenu__link {
  padding-bottom: 16px;
  display: block;
  white-space: nowrap;
  margin-right: 36px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: #2a251d;
  text-decoration: none;
  cursor: pointer; }
  @media (min-width: 1520px) {
    .submenu__link {
      font-size: 16px;
      margin-right: 44px; } }
  .submenu__link--active {
    color: #b8232d; }
    .submenu__link--active:after {
      margin-top: 15px;
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #b8232d; }

.submenu__list {
  display: flex;
  min-width: 100%;
  overflow-x: auto;
  border-bottom: 1px solid rgba(42, 37, 29, 0.1); }
  .submenu__list::-webkit-scrollbar {
    width: 5px; }

.text {
  background-color: white; }
  .text h2 {
    margin-bottom: 20px;
    padding-left: 12px;
    font-size: 38px;
    line-height: 1;
    color: #2a251d; }
    @media (min-width: 956px) {
      .text h2 {
        margin-bottom: 32px;
        padding-left: 20px;
        font-size: 52px; } }
    @media (min-width: 1280px) {
      .text h2 {
        padding-left: 24px; } }
    @media (min-width: 1520px) {
      .text h2 {
        margin-bottom: 24px;
        font-size: 64px; } }
  .text a {
    color: #2a251d; }
  .text ul li {
    font-size: 16px;
    line-height: 22px;
    color: #493e31; }
  .text .text__body {
    margin-bottom: 20px; }
  .text h3 {
    font-weight: bold; }
  @media (min-width: 956px) {
    .text__inner {
      padding-left: 6%;
      padding-right: 6%; } }
  @media (min-width: 1520px) {
    .text__inner {
      padding-left: 6.2%;
      padding-right: 6.2%; } }
  .text__body {
    padding-left: 12px; }
    @media (min-width: 956px) {
      .text__body {
        padding-left: 20px; } }
    @media (min-width: 1280px) {
      .text__body {
        padding-left: 24px; } }
  .text .subtitle {
    display: flex;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    color: #2a251d; }
    .text .subtitle:before {
      content: "";
      margin-right: 8px;
      display: flex;
      width: 4px;
      background: #b8232d; }
      @media (min-width: 956px) {
        .text .subtitle:before {
          margin-right: 16px; } }
      @media (min-width: 1280px) {
        .text .subtitle:before {
          margin-right: 20px; } }
    @media (min-width: 956px) {
      .text .subtitle {
        font-size: 20px;
        line-height: 26px; } }
    .text .subtitle span {
      width: 95.8%; }
  .text p {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    color: #493e31; }
    .text p:last-child {
      margin-bottom: 0; }
  @media (min-width: 656px) {
    .text .subtitle, .text p {
      max-width: 530px; } }
  @media (min-width: 956px) {
    .text .subtitle, .text p {
      max-width: 599px; } }
  @media (min-width: 1280px) {
    .text .subtitle, .text p {
      max-width: 690px; } }
  @media (min-width: 1520px) {
    .text .subtitle, .text p {
      max-width: 820px; } }
  .text--page h1 {
    line-height: 1;
    padding-left: 12px;
    max-width: 76%; }
    @media (min-width: 956px) {
      .text--page h1 {
        padding-left: 20px; } }
    @media (min-width: 1280px) {
      .text--page h1 {
        padding-left: 24px; } }
  .text--page h2 {
    margin-top: 20px;
    max-width: 76%; }
  .text--page img {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 12px; }
    @media (min-width: 956px) {
      .text--page img {
        margin-left: 20px; } }
    @media (min-width: 1280px) {
      .text--page img {
        margin-left: 24px; } }
  .text--page ul {
    margin-bottom: 12px;
    padding-left: 12px; }
    @media (min-width: 1280px) {
      .text--page ul {
        padding-left: 18px; } }
    .text--page ul ul {
      padding-top: 14px;
      padding-bottom: 0;
      margin-bottom: 0; }
    .text--page ul li {
      margin-bottom: 12px;
      color: #493e31; }
      @media (min-width: 656px) {
        .text--page ul li {
          max-width: 530px; } }
      @media (min-width: 956px) {
        .text--page ul li {
          max-width: 599px; } }
      @media (min-width: 1280px) {
        .text--page ul li {
          max-width: 690px; } }
      @media (min-width: 1520px) {
        .text--page ul li {
          max-width: 820px; } }

.text-picture {
  padding-bottom: 24px;
  background-color: white;
  background-image: url(../img/mt-bg.svg);
  background-repeat: no-repeat;
  background-size: 269px 326px;
  background-position: 71% 41%; }
  @media (min-width: 1280px) {
    .text-picture {
      padding-bottom: 36px;
      background-size: 322px 390px;
      background-position: 60% 100%; } }
  @media (min-width: 1520px) {
    .text-picture {
      padding-bottom: 114px; } }
  @media (min-width: 956px) {
    .text-picture__column:nth-child(1) {
      width: 58.5%;
      margin-right: 2.6%; } }
  @media (min-width: 1280px) {
    .text-picture__column:nth-child(1) {
      width: 46.3%;
      margin-right: 2.5%; } }
  @media (min-width: 1520px) {
    .text-picture__column:nth-child(1) {
      width: 49%;
      margin-right: 1.9%; } }
  .text-picture__column:nth-child(2) {
    display: none; }
    @media (min-width: 956px) {
      .text-picture__column:nth-child(2) {
        display: block;
        width: 38.9%; } }
    @media (min-width: 1280px) {
      .text-picture__column:nth-child(2) {
        width: 48.8%; } }
    @media (min-width: 1520px) {
      .text-picture__column:nth-child(2) {
        width: 49.1%; } }
  @media (min-width: 956px) {
    .text-picture__inner, .text-picture .inner {
      padding-left: 6%;
      padding-right: 6%; } }
  @media (min-width: 1520px) {
    .text-picture__inner, .text-picture .inner {
      padding-left: 6.2%;
      padding-right: 6.2%; } }
  .text-picture h2 {
    margin-bottom: 20px;
    padding-left: 12px;
    font-size: 38px;
    line-height: 1;
    color: #2a251d; }
    @media (min-width: 956px) {
      .text-picture h2 {
        margin-bottom: 32px;
        padding-left: 20px;
        font-size: 52px; } }
    @media (min-width: 1280px) {
      .text-picture h2 {
        padding-left: 24px; } }
    @media (min-width: 1520px) {
      .text-picture h2 {
        margin-bottom: 24px;
        font-size: 64px; } }
  .text-picture__body {
    padding-left: 12px; }
    @media (min-width: 956px) {
      .text-picture__body {
        padding-left: 20px; } }
    @media (min-width: 1280px) {
      .text-picture__body {
        padding-left: 24px; } }
  .text-picture .subtitle {
    display: flex;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    color: #2a251d; }
    .text-picture .subtitle:before {
      content: "";
      margin-right: 8px;
      display: flex;
      width: 4px;
      background: #b8232d; }
      @media (min-width: 956px) {
        .text-picture .subtitle:before {
          margin-right: 16px; } }
      @media (min-width: 1280px) {
        .text-picture .subtitle:before {
          margin-right: 20px; } }
    @media (min-width: 956px) {
      .text-picture .subtitle {
        font-size: 20px;
        line-height: 26px; } }
    .text-picture .subtitle span {
      width: 95.8%; }
  .text-picture p {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    color: #493e31; }
  @media (min-width: 656px) {
    .text-picture .subtitle, .text-picture p {
      max-width: 530px; } }
  @media (min-width: 956px) {
    .text-picture .subtitle, .text-picture p {
      max-width: 100%; } }
  .text-picture__link {
    display: flex;
    align-items: end;
    margin-top: 32px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: #2a251d;
    text-decoration: none; }
    .text-picture__link:after {
      content: "";
      display: flex;
      width: 24px;
      height: 24px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px; }

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
