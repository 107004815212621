// В этом файле должны быть стили для БЭМ-блока numbers, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.numbers {

  $block-name:                &; // #{$block-name}__element
  padding-bottom: 32px;

  @media (min-width: ($screen-md)) {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  @media (min-width: ($screen-xxl)) {
    padding-bottom: 44px;
  }

  .inner {
    @media (min-width: ($screen-lg)) {
      padding-left: 8.4%;
      padding-right: 8.4%;
    }

    @media (min-width: $screen-xxl) {
      padding-left: 121px;
      padding-right: 121px;
    }
  }

  .number {
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid $color-black-text-tr;
    list-style: none;
    &__value {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      color: $color-red;

      @media (min-width: ($screen-md)) {
        margin-bottom: 12px;
      }

      @media (min-width: ($screen-lg)) {
        margin-bottom: 16px;
        font-size: 24px;
      }

      @media (min-width: ($screen-xl)) {
        font-size: 24px;
      }

      @media (min-width: ($screen-xxl)) {
        font-size: 32px;
      }

      b {
        font-size: 58px;
        line-height: 1;

        @media (min-width: ($screen-lg)) {
          font-size: 72px;
        }

        @media (min-width: ($screen-xl)) {
          font-size: 88px;
        }

        @media (min-width: ($screen-xxl)) {
          font-size: 100px;
        }
      }
    }
    &__name {
      max-width: 218px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: $color-gray-text;

      @media (min-width: ($screen-lg)) {
        max-width: 225px;
      }

      @media (min-width: ($screen-xxl)) {
        font-size: 18px;
        max-width: 308px;
      }
    }

    &:last-child {
      margin-right: 0;

      @media (min-width: ($screen-md)) {
        border-right: none;
        padding-right: 0;
      }
    }

    &:first-child {
      @media (min-width: ($screen-md)) {
        padding-left: 0;
      }
    }

    @media (min-width: ($screen-md)) {
      padding-top: 0;
      padding-right: 26px;
      padding-bottom: 0;
      padding-left: 26px;
      border-right: 1px solid $color-black-text-tr;
      border-bottom: 0;
    }

    @media (min-width: ($screen-lg)) {
      padding-right: 48px;
      padding-left: 48px;
    }

    @media (min-width: ($screen-xl)) {
      padding-right: 58px;
      padding-left: 58px;
    }

    @media (min-width: ($screen-xxl)) {
      padding-right: 78px;
      padding-left: 78px;
    }
  }

  &__inner {
    margin-bottom: 0;
    padding-left: 0;

    @media (min-width: ($screen-md)) {
      display: flex;
    }
  }
}
