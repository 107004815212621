// В этом файле должны быть стили для БЭМ-блока review, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.review {

  $block-name:                &; // #{$block-name}__element
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid $color-black-text-tr;
  color: $color-black-light-text;

  @media (min-width: $screen-lg) {
    padding-top: 40px;
  }

  @media (min-width: $screen-xl) {
    padding-bottom: 40px;
  }

  &:nth-child(1) {
    border-top: 1px solid $color-black-text-tr;
  }

  &__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $color-black-light-text;

    @media (min-width: $screen-md) {
      padding-left: 18px;
      max-width: 510px;
    }

    @media (min-width: $screen-lg) {
      padding-left: 0;
      max-width: 676px;
    }

    @media (min-width: $screen-xl) {
      max-width: 793px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 820px;
    }
  }

  &__meta {
    display: flex;
    align-items: baseline;
    padding-top: 16px;

    @media (min-width: $screen-md) {
      padding-top: 32px;
      padding-left: 18px;
    }

    @media (min-width: $screen-lg) {
      padding-top: 16px;
      padding-left: 0;
    }

    @media (min-width: $screen-xl) {
      padding-top: 24px;
    }
  }

  &__name {
    margin-right: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    color: $color-black-light-text;
  }

  &__date {
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: $color-black-light-text;
  }
}
