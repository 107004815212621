// В этом файле должны быть стили для БЭМ-блока promotion, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.promotion {

  $block-name:                &; // #{$block-name}__element
  position: relative;
  display: block;
  width: 100%;
  padding: 24px 24px 32px;
  background-color: $color-khaki;
  color: $white;
  text-decoration: none;

  &:hover, &:focus {
    color: $white;
  }

  @media (min-width: $screen-lg) {
    min-height: 272px;
    padding: 0;
    background-image: url(../img/bg-promotion-min.svg);
    background-size: 155px 272px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }

  @media (min-width: $screen-xl) {
    min-height: 364px;
    background-image: url(../img/bg-promotion-xl.svg);
    background-size: 282px 364px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }

  @media (min-width: $screen-xxl) {
    background-image: url(../img/bg-promotion-xxl.svg);
    background-size: 337px 434px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    min-height: 434px;
  }

  &__img {
    display: none;

    @media (min-width: $screen-lg) {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  img {
    @media (min-width: $screen-lg) {
      display: block;
      width: 155px;
      height: 272px;
    }
    @media (min-width: $screen-xl) {
      width: 282px;
      height: 364px;
    }
    @media (min-width: $screen-xxl) {
      width: 336px;
      height: 434px;
    }
  }

  &__body {
    max-width: 232px;

    @media (min-width: $screen-md) {
      max-width: 246px
    }

    @media (min-width: $screen-lg) {
      padding: 32px 0 32px 24px;
    }

    @media (min-width: $screen-xl) {
      max-width: 290px;
      padding: 52px 0 52px 40px;
    }
  }

  &__type {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    @media (min-width: $screen-xl) {
      margin-bottom: 24px;
    }
  }

  &__group {

    @media (min-width: $screen-lg) {
      margin-bottom: 32px;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 94px;
    }
    @media (min-width: $screen-xxl) {
      min-height: 143px;
      margin-bottom: 146px;
    }
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    min-height: 50px;

    @media (min-width: $screen-xl) {
      margin-bottom: 16px;
    }

    @media (min-width: $screen-xxl) {
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 30px;
    }
  }

  .subtitle {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $color-khaki-light;
    min-height: 129px;
  }

  &__link {
    display: flex;
    align-items: end;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    text-decoration: none;

    &:after {
      content: "";
      display: flex;
      width: 24px;
      height: 18px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px;
    }
  }
}
