// В этом файле должны быть стили для БЭМ-блока promotions, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.promotions {

  $block-name:                &; // #{$block-name}__element

  .promotion {
    margin-bottom: 24px;
    flex-basis: 280px;
    width: 280px;

    @media (min-width: $screen-md) {
      margin-right: 24px;
      flex-basis: 294px;
      width: 294px;
    }

    @media (min-width: $screen-lg) {
      flex-basis: 367px;
      width: 367px;
    }

    @media (min-width: $screen-xl) {
      flex-basis: 486px;
      width: 486px;
    }

    @media (min-width: $screen-xxl) {
      flex-basis: 579px;
      width: 579px;
    }

    &:nth-child(2n) {
      @media (min-width: $screen-md) {
        margin-right: 0;
      }
    }
  }

  &__inner {
    flex-direction: column;

    @media (min-width: $screen-md) {
      flex-direction: row;
      justify-content: center;
      max-width: 612px;
    }

    @media (min-width: $screen-lg) {
      max-width: 758px;
    }

    @media (min-width: $screen-xl) {
      max-width: 996px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1182px;
    }
  }
}
