@font-face {
    font-family: 'KudryashevDisplay';
    src: url("../fonts/KudryashevDisplay.woff2") format("woff2"),
            url("../fonts/KudryashevDisplay.woff") format("woff");
}

@font-face {
    font-family: 'KudryashevDisplay-Sans';
    src: url("../fonts/KudryashevDisplay-Sans.woff2") format("woff2"),
            url("../fonts/KudryashevDisplay-Sans.woff") format("woff");
}

@font-face {
    font-family: 'Golos UI';
    src: url('../fonts/Golosuimedium.woff2') format('woff2'), url('../fonts/Golosuimedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Golos UI';
    src: url('../fonts/Golosuibold.woff2') format('woff2'), url('../fonts/Golosuibold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Golos UI';
    src: url('../fonts/Golosui.woff2') format('woff2'), url('../fonts/Golosui.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
