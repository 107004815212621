// В этом файле должны быть стили для БЭМ-блока footer-icons, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.footer-icons {

  $block-name:                &; // #{$block-name}__element
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  padding-left: 0;

  @media (min-width: $screen-xl) {
    justify-content: flex-end;
  }

  &__item {
    margin-right: 8px;
    list-style: none;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: $screen-lg) {
      margin-right: 12px;
    }
  }

  a {
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px;

    @media (min-width: $screen-lg) {
      width: 32px;
      height: 32px;
      background-size: 32px;
    }
  }
}
