// В этом файле должны быть стили для БЭМ-блока site-panel, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.site-panel {

  $block-name:                &; // #{$block-name}__element
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;

  @media (min-width: $screen-lg) {
    display: none;
  }

  &__inner {
    display: flex;
  }

  &__btn {
    width: 50%;
    color: $white;

    &:first-child {
      background-color: $color-red;
    }

    &:last-child {
      background-color: $color-black-light-text; 
    }

    &:hover {
      color: $white;
    }
  }
}
