// В этом файле должны быть стили для БЭМ-блока promo, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.promo {

  $block-name:                &; // #{$block-name}__element

  background-color: $color-khaki;
  color: $white;

  @media (min-width: ($screen-xxl)) {
    max-width: 1560px;
    margin: auto;
    
  }

  &__body {
    @media (min-width: ($screen-lg)) {
      padding-left: 8.4%;
    }
    @media (min-width: ($screen-xxl)) {
      padding-left: 169px;
    }
  }

  &__inner {
    padding-top: 32px;
    padding-bottom: 80px;
    background-image: url(../img/pr-bg-sm.svg);
    background-size: 305px 241px;
    background-repeat: no-repeat;
    background-position: 100% 100%;

    @media (min-width: ($screen-md)) {
      padding-bottom: 56px;
      background-image: url(../img/pr-bg.svg);
      background-size: 421px 315px;
    }

    @media (min-width: ($screen-lg)) {
      background-size: 497px 356px;
      background-position: 100% 100%;
    }

    @media (min-width: ($screen-xxl)) {
      padding-bottom: 96px;
      margin: 0;
      max-width: 100%;
    }
  }

  .inner {
    @media (min-width: ($screen-xxl)) {
      margin: 0;
      max-width: 100%;
    }
  }

  .inner-breadcrumbs {
    @media (min-width: ($screen-xxl)) {
      padding-left: 48px;
    }
  }

  .breadcrumbs {
    margin-bottom: 56px;

    @media (min-width: ($screen-md)) {
      margin-bottom: 38px;
    }

    @media (min-width: ($screen-xl)) {
      margin-bottom: 68px;
    }

    @media (min-width: ($screen-xxl)) {
      margin-bottom: 78px;
    }
  }

  h1 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;

    @media (min-width: ($screen-lg)) {
      max-width: 367px;
      font-size: 72px;
      line-height: 66px;
    }

    @media (min-width: ($screen-xl)) {
      max-width: 487px;
    }

    @media (min-width: ($screen-xxl)) {
      max-width: 580px;
      font-size: 80px;
      line-height: 74px;
    }
  }

  .subtitle {
    max-width: 294px;
    padding-top: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;

    @media (min-width: ($screen-lg)) {
      max-width: 487px;
      padding-top: 24px;
      font-size: 20px;
    }

    @media (min-width: ($screen-xl)) {
      padding-top: 20px;
    }

    @media (min-width: ($screen-xxl)) {
      max-width: 579px;
      padding-top: 24px; 
    }
  }

  .a-btn {
    width: 157px;
    height: 44px;
    margin-top: 24px;

    @media (min-width: ($screen-lg)) {
      margin-top: 40px;
    }

    @media (min-width: ($screen-xxl)) {
      margin-top: 32px; 
    }
  }

  .page__title {
    position: relative;
  }

  &__back {
    display: none;
    @media (min-width: ($screen-lg)) {
      display: block;
      position: absolute;
      top: 21%;
      left: -9%;
      width: 22px;
      height: 15px;
      background-image: url(../img/back.svg);
      background-size: 22px 15px;
      background-repeat: no-repeat;
    }
  }

  &--simple {
    .promo__inner {
      background-image: none;
    }
  }

  &--service {
      background-image: url(../img/bg-pr-ser-min.svg);
      background-size: 320px 273px;
      background-repeat: no-repeat;
      background-position: 0 0;

      @media (min-width: ($screen-lg)) {
        background-image: url(../img/bg-pr-ser.svg);
        background-size: 661px 435px;
      }
    .promo__inner {
      padding-bottom: 280px;
      background-image: url(../img/service.png);
      background-size: 280px 256px;
      background-repeat: no-repeat;
      background-position: 0 100%;

      @media (min-width: ($screen-md)) {
        padding-bottom: 56px;
        background-size: 345px 311px;
        background-position: 100% 100%;
      }

      @media (min-width: ($screen-lg)) {
        background-size: 468px 454px;
      }

      @media (min-width: ($screen-xl)) {
        padding-top: 56px;
        padding-bottom: 80px;
        background-size: 587px 540px;
      }

      @media (min-width: ($screen-xxl)) {
        padding-bottom: 96px;
        background-size: 700px 640px;
      }
    }

    .breadcrumbs {
      @media (min-width: ($screen-xl)) {
        margin-bottom: 164px;
      }

      @media (min-width: ($screen-xxl)) {
        margin-bottom: 244px;
      }
    }
  }
}
