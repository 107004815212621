// В этом файле должны быть стили для БЭМ-блока text, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.text {

  $block-name:                &; // #{$block-name}__element
  background-color: $body-bg;

  h2 {
    margin-bottom: 20px;
    padding-left: 12px;
    font-size: 38px;
    line-height: 1;
    color: $color-black-text;

    @media (min-width: $screen-lg) {
      margin-bottom: 32px;
      padding-left: 20px;
      font-size: 52px;
    }

    @media (min-width: $screen-xl) {
      padding-left: 24px;
    }

    @media (min-width: $screen-xxl) {
      margin-bottom: 24px;
      font-size: 64px;
    }
  }

  a {
    color: $color-black-text;
  }

  ul li {
      font-size: 16px;
      line-height: 22px;
      color: #493e31;
  }

  .text__body {
      margin-bottom: 20px;
  }

  h3 {
      font-weight: bold;
  }

  &__inner {
    @media (min-width: $screen-lg) {
        padding-left: 6%;
        padding-right: 6%;
    }

    @media (min-width: $screen-xxl) {
        padding-left: 6.2%;
        padding-right: 6.2%;
    }
  }

  &__body {
    padding-left: 12px;

    @media (min-width: $screen-lg) {
      padding-left: 20px;
    }

    @media (min-width: $screen-xl) {
      padding-left: 24px;
    }
  }

  .subtitle {
    display: flex;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    color: $color-black-text;

    &:before {
      content: "";
      margin-right: 8px;
      display: flex;
      width: 4px;
      background: $color-red;

      @media (min-width: $screen-lg) {
        margin-right: 16px;
      }

      @media (min-width: $screen-xl) {
        margin-right: 20px;
      }
    }

    @media (min-width: $screen-lg) {
      font-size: 20px;
      line-height: 26px;
    }

    span {
      width: 95.8%;
    }
  }

  p {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    color: $color-black-light-text;

    &:last-child {
        margin-bottom: 0;
    }
  }

  .subtitle, p {
    @media (min-width: $screen-md) {
      max-width: 530px;
    }

    @media (min-width: $screen-lg) {
      max-width: 599px;
    }

    @media (min-width: $screen-xl) {
      max-width: 690px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 820px;
    }
  }

  &--page {
    h1 {
      line-height: 1;
      padding-left: 12px;
      max-width: 76%;

      @media (min-width: $screen-lg) {
        padding-left: 20px;
      }

      @media (min-width: $screen-xl) {
        padding-left: 24px;
      }
    }

    h2 {
      margin-top: 20px;
      max-width: 76%;
    }

    img {
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: 12px;

      @media (min-width: $screen-lg) {
        margin-left: 20px;
      }

      @media (min-width: $screen-xl) {
        margin-left: 24px;
      }
    }

    ul {
      margin-bottom: 12px;
      padding-left: 12px;
      @media (min-width: $screen-xl) {
        padding-left: 18px;
      }
      ul {
        padding-top: 14px;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      li {
        margin-bottom: 12px;
        color: #493e31;
        @media (min-width: $screen-md) {
          max-width: 530px;
        }

        @media (min-width: $screen-lg) {
          max-width: 599px;
        }

        @media (min-width: $screen-xl) {
          max-width: 690px;
        }

        @media (min-width: $screen-xxl) {
          max-width: 820px;
        }
      }
    }
  }
}
