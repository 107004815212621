.vk {
  background-image: url(../img/vk.svg);
}

.inst {
  background-image: url(../img/inst.svg);
}

.fb {
  background-image: url(../img/fb.svg);
}