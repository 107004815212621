// В этом файле должны быть стили для БЭМ-блока start, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.start {

  $block-name:                &; // #{$block-name}__element

  &__inner {
    @media (min-width: $screen-xl) {
      display: flex;
      min-height: 100vh;
    }
    @media (min-width: $screen-xxl) {
      max-width: 1520px;
      margin: auto;
    }
  }

  &__column {
    &:nth-child(1) {
      position: relative;
      padding-top: 72px;
      padding-bottom: 449px;
      background: linear-gradient(222.08deg, #E1E3E1 34.29%, #E3E3E3 115.33%);

      @media (min-width: $screen-md) {
        padding-bottom: 0;
        padding-top: 0;
      }

      @media (min-width: $screen-xl) {
        width: 66.9%;
      }

      .inner {
        @media (min-width: $screen-xl) {
          max-width: 100%;
          padding-left: 40px;
          margin: 0;
        }
      }
    }

    &:nth-child(2) {
      padding-top: 48px;
      padding-bottom: 32px;

      @media (min-width: $screen-md) {
        padding-top: 52px;
        padding-bottom: 52px;
      }

      @media (min-width: $screen-lg) {
        padding-top: 44px;
        padding-bottom: 32px;
      }

      @media (min-width: $screen-xl) {
        width: 33.1%;
      }

      .inner {
        @media (min-width: $screen-xl) {
          max-width: 100%;
          padding-right: 40px;
          padding-left: 32px;
          margin: 0;
        }
      }
    }
  }

  .start-promo {
    @media (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
    }

    &__column {
      &:nth-child(1) {
        padding-top: 54px;
        @media (min-width: $screen-md) {
          padding-top: 52px;
          max-width: 294px;
        }
        @media (min-width: $screen-lg) {
          padding-top: 90px;
          max-width: 488px;
        }
        @media (min-width: $screen-xl) {
          padding-top: 430px;
        }
        @media (min-width: $screen-xxl) {
          max-width: 579px;
        }
      }
    }

    &__address {
      margin-bottom: 22px;
      display: inline-block;
      font-size: 12px;
      font-weight: 900;
      line-height: 16px;
      color: #B6232D;
      letter-spacing: .05em;
      text-transform: uppercase;
      background-color: #B39A7A;

      @media (min-width: $screen-lg) {
        margin-bottom: 26px;
      }
    }

    &__title {
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 32px;
      line-height: 34px;
      color: #2B251D;

      @media (min-width: $screen-md) {
        margin-bottom: 16px;
      }

      @media (min-width: $screen-lg) {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 20px;
      }

      @media (min-width: $screen-xxl) {
        margin-bottom: 24px;
        font-size: 44px;
        line-height: 48px;
      }
    }

    &__desc {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      color: #493E31;

      @media (min-width: $screen-lg) {
        font-size: 20px;
        max-width: 384px;
      }

      @media (min-width: $screen-xxl) {
        max-width: 459px;
      }
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      max-width: 280px;

      @media (min-width: $screen-md) {
        position: static;
        max-width: 256px;
      }

      @media (min-width: $screen-xl) {
        position: absolute;
        right: 0;
        left: auto;
        max-width: 404px;
      }

      @media (min-width: $screen-xxl) {
        max-width: 475px;
      }
    }
  }

  .start-list {
    @media (min-width: $screen-md) {
      display: flex;
      flex-wrap: wrap;
    }

    @media (min-width: $screen-xl) {
      flex-direction: column;
    }
  }

  .start-clinic {
    margin-bottom: 83px;


    @media (min-width: $screen-md) {
      max-width: 294px;
      margin-right: 20px;
      margin-bottom: 107px;
    }

    @media (min-width: $screen-lg) {
      margin-bottom: 0;
      max-width: 288px;
    }

    @media (min-width: $screen-xl) {
      margin-right: 0;
      margin-bottom: 66px;
      max-width: 352px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 425px;
    }



    &:nth-child(2) {
      @media (min-width: $screen-md) {
        margin-right: 0;
      }
      @media (min-width: $screen-lg) {
        margin-right: 20px;
      }
      @media (min-width: $screen-xl) {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
      @media (min-width: $screen-lg) {
        margin-right: 0;
      }
    }

    &__address {
      margin-bottom: 22px;
      display: inline-block;
      font-size: 12px;
      font-weight: 900;
      line-height: 16px;
      color: #B6232D;
      letter-spacing: .05em;
      text-transform: uppercase;
      background-color: #B39A7A;
    }

    h2 {
      margin-bottom: 16px;
      font-family: $font-family;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #2B251D;

      @media (min-width: $screen-lg) {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 28px;
      }

      @media (min-width: $screen-xxl) {
        margin-bottom: 16px;
        font-size: 30px;
        line-height: 34px;
      }
    }

    &__desc {
      margin-bottom: 32px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #493E31;

      @media (min-width: $screen-xl) {
        margin-bottom: 46px;
      }

      @media (min-width: $screen-xxl) {
        margin-bottom: 44px;
      }
    }

    &__link {
      display: flex;
      align-items: end;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      color: #2B251D;
      text-decoration: none;

      &:after {
        content: "";
        display: flex;
        width: 24px;
        height: 18px;
        margin-left: 12px;
        background-image: url(../img/link.svg);
        background-repeat: no-repeat;
        background-size: 24px 18px;
      }
    }
  }
}
