// В этом файле должны быть стили для БЭМ-блока reviews, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.reviews {

  $block-name:                &; // #{$block-name}__element

  &__list {
    margin-bottom: 8px;

    @media (min-width: $screen-lg) {
      margin-bottom: 12px;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 16px;
    }
  }

  &__inner {
    @media (min-width: $screen-md) {
      max-width: 608px;
    }

    @media (min-width: $screen-lg) {
      max-width: 754px;
    }

    @media (min-width: $screen-xl) {
      max-width: 998px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1182px;
    }
  }

  .reviews-pagination {
    display: flex;
    justify-content: center;

    @media (min-width: $screen-lg) {
      justify-content: flex-start;
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 44px;
      width: 42px;
      height: 42px;
      font-size: 15px;
      line-height: 1;
      text-decoration: none;
      color: $color-gray-text;
      border: 2px solid transparent;
      border-radius: 50%;
    }

    &__link--active {
      color: $color-black-text;
      border: 2px solid $color-red;
    }
  }
}
