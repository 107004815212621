// В этом файле должны быть стили для БЭМ-блока promo-doctor, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.promo-doctor {

  $block-name:                &; // #{$block-name}__element
  padding-top: 81px;
  background-color: #fbf8f4;

  @media (min-width: $screen-lg) {
    padding-top: 81.65px;
  }

  @media (min-width: $screen-xxl) {
    max-width: 1520px;
    margin: auto;
  }

  &__inner {
    position: relative;

    @media (min-width: $screen-xl) {
      padding-top: 56px;
      display: flex;
      max-width: 89.4%;
      margin: 0 0 0 10.6%;
      background: linear-gradient(to right, $color-khaki-light 50%, $color-khaki 50% 100%);
    }

    @media (min-width: $screen-xxl) {
      max-width: 1472px;
      margin: 0 0 0 142px;
    }
  }

  &__column {
    &:nth-child(1) {
      background-color: $color-khaki-light;

      @media (min-width: ($screen-lg)) {
        padding-bottom: 56px;
      }

      @media (min-width: ($screen-xl)) {
        width: 50%;
      }

      .promo-doctor__wrapper {
        max-width: $container-sm;
        margin: auto;

        @media (min-width: ($screen-md)) {
          max-width: $container-md;
          display: flex;
        }

        @media (min-width: ($screen-lg)) {
          max-width: 89.4%;
          margin: 0 0 0 10.6%;
        }

        @media (min-width: $screen-xl) {
          max-width: 100%;
          margin: 0;
        }
      }
    }
    &:nth-child(2) {
      background-color: $color-khaki;
      background-image: url(../img/quotes-min.svg);
      background-repeat: no-repeat;
      background-size: 102px 64px;
      background-position: 4% 24px;
      @media (min-width: $screen-lg) {
        background-position: 9% 24px;
      }
      @media (min-width: $screen-xl) {
        padding-left: 216px;
        background-image: url(../img/quotes.svg);
        background-repeat: no-repeat;
        background-size: 159px 101px;
        background-position: 50% 35%;
        width: 50%;
      }
      @media (min-width: $screen-xxl) {
        padding-left: 252px;
        background-image: url(../img/quotes.svg);
        background-repeat: no-repeat;
        background-size: 159px 101px;
        background-position: 50% 30%;
      }

      .promo-doctor__wrapper {
        max-width: $container-sm;
        margin: auto;

        @media (min-width: ($screen-md)) {
          max-width: $container-md;
        }

        @media (min-width: ($screen-lg)) {
          max-width: 89.4%;
          margin: 0 0 0 10.6%;
        }

        @media (min-width: $screen-xl) {
          max-width: 100%;
          margin: 0;
        }
      }
    }
  }

  &__body {
    @media (min-width: ($screen-xl)) {
      padding-bottom: 130px;
    }
    @media (min-width: ($screen-xxl)) {
      padding-bottom: 182px;
    }
  }

  &__left {
    @media (min-width: ($screen-md)) {
      width: 50%;
    }

    @media (min-width: ($screen-lg)) {
      width: 100%;
    }
  }

  &__right {
    @media (min-width: ($screen-md)) {
      width: 50%;
    }

    @media (min-width: ($screen-lg)) {
      position: absolute;
      width: auto;
      right: 3%;
      bottom: 0;
    }

    @media (min-width: ($screen-xl)) {
      right: 36%;
    }
  }

  .page__title {
    margin-top: 56px;

    @media (min-width: ($screen-md)) {
      margin-top: 38px;
    }

    @media (min-width: ($screen-xl)) {
      margin-top: 74px;
    }

    @media (min-width: ($screen-md)) {
      margin-top: 78px;
    }
  }

  h1 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;

    @media (min-width: ($screen-md)) {
      max-width: 294px;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 367px;
      font-size: 72px;
      line-height: 66px;
    }

    @media (min-width: ($screen-xl)) {
      max-width: 487px;
    }

    @media (min-width: ($screen-xxl)) {
      max-width: 580px;
      font-size: 80px;
      line-height: 74px;
    }
  }

  .subtitle {
    max-width: 294px;
    padding-top: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;

    @media (min-width: ($screen-lg)) {
      max-width: 487px;
      padding-top: 24px;
      font-size: 20px;
    }

    @media (min-width: ($screen-xl)) {
      padding-top: 20px;
    }

    @media (min-width: ($screen-xxl)) {
      max-width: 579px;
      padding-top: 24px; 
    }
  }

  .a-btn {
    width: 195px;
    height: 44px;
    margin-top: 24px;

    @media (min-width: ($screen-lg)) {
      margin-top: 40px;
    }

    @media (min-width: ($screen-xxl)) {
      margin-top: 32px; 
    }
  }

  .page__title {
    position: relative;
  }

  &__back {
    display: none;
    @media (min-width: ($screen-lg)) {
      display: block;
      position: absolute;
      top: 21%;
      left: -9%;
      width: 22px;
      height: 15px;
      background-image: url(../img/back.svg);
      background-size: 22px 15px;
      background-repeat: no-repeat;
    }
  }

  &__img {
    padding-top: 24px;

    @media (min-width: ($screen-md)) {
      display: flex;
      justify-content: flex-end;
    }    

    img {
      display: block;
      margin: auto;

      @media (min-width: ($screen-md)) {
        width: 215px;
        height: 262px;
        margin: 0;
      }

      @media (min-width: ($screen-lg)) {
        width: 367px;
        height: 449px;
      }

      @media (min-width: ($screen-xl)) {
        width: 384px;
        height: 470px;
      }

      @media (min-width: ($screen-xxl)) {
        width: 458px;
        height: 560px;
      }
    }
  }

  .promo-quote {
    margin-bottom: 0;
    padding-top: 52px;
    padding-bottom: 32px;
    font-size: 18px;
    line-height: 26px;
    color: $white;

    @media (min-width: ($screen-md)) {
      padding-top: 44px;
      max-width: 530px;
    }

    @media (min-width: ($screen-lg)) {
      font-size: 20px;
      line-height: 26px;
      max-width: 367px;
    }

    @media (min-width: ($screen-xl)) {
      padding-top: 192px;
      max-width: 282px;
    }

    @media (min-width: ($screen-xxl)) {
      max-width: 339px;
    }
  }
}
