// В этом файле должны быть стили для БЭМ-блока submenu, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.submenu {

  $block-name:                &; // #{$block-name}__element

  @media (min-width: $screen-xxl) {
    margin: auto;
    padding-left: 48px;
    width: 1520px;
    max-width: 1520px;
  }

  &__inner {
    max-width: $container-sm;
    margin: auto;
    margin-bottom: 32px;

    @media (min-width: ($screen-md)) {
      max-width: $container-md;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 100%;
      max-width: 97.5%;
      margin: 0 0 40px 2.5%;
      padding-left: 8%;
    }

    @media (min-width: $screen-xl) {
      max-width: 96.9%;
      margin: 0 0 40px 3.1%;
      padding-left: 7.9%;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1472px;
      width: 1472px;
      margin: 0 0 40px 0;
    }
  }

  &__link {
    padding-bottom: 16px;
    display: block;
    white-space: nowrap;
    margin-right: 36px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: $color-black-text;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: $screen-xxl) {
      font-size: 16px;
      margin-right: 44px;
    }

    &--active {
      color: $color-red;
      &:after {
        margin-top: 15px;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $color-red;
      }
    }
  }

  &__list {
    display: flex;
    min-width: 100%;
    overflow-x: auto;
    border-bottom: 1px solid hsla(34, 19%, 14%, 0.1);

    &::-webkit-scrollbar {
      width: 5px;
    }
  }
}
