// В этом файле должны быть стили для БЭМ-блока slider-photo, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.slider-photo {

  $block-name:                &; // #{$block-name}__element
  position: relative;

  @media (min-width: ('1600px')) {
    max-width: 1424px;
    margin: auto;
  }

  .slider-item {
    max-width: 367px;

    @media (min-width: ('800px')) {
      max-width: 486px;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 367px;
    }

    @media (min-width: ($screen-xl)) {
      max-width: 486px;
    }

    @media (min-width: ($screen-xxl)) {
      max-width: 579px;
    }
  }

  &__inner {
    @media (min-width: ($screen-lg)) {
      max-width: 100%;
      margin: 0;
      padding-left: 11.1%;
    }
    @media (min-width: ($screen-xxl)) {
      padding-left: 168px;
    }
  }

  .slick-slide {
    text-align: center;
    @media (min-width: ($screen-md)) {
      margin: 0 20px 0 0;
    } 
  }

  .slick-arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__arrows {
    padding-top: 24px;
    text-align: center;

    @media (min-width: ($screen-md)) {
      padding-top: 32px;
    }

    @media (min-width: ($screen-lg)) {
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 43%;
      left: 9%;
      padding-top: 0;
      width: 796px;
    }

    @media (min-width: ($screen-xl)) {
      width: 1049px;
    }

    @media (min-width: ($screen-xxl)) {
      width: 1234px;
    }
  }

  &__prev {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    background-image: url(../img/photos/left.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;

    @media (min-width: ($screen-xl)) {
      width: 48px;
      height: 48px;
      margin-right: 0;
      background-size: 48px 48px;
    }
  }

  &__next {
    width: 40px;
    height: 40px;
    background-image: url(../img/photos/right.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;

    @media (min-width: ($screen-xl)) {
      width: 48px;
      height: 48px;
      margin-right: 0;
      background-size: 48px 48px;
      right: 0;
    }
  }
}
