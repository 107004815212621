// В этом файле должны быть стили для БЭМ-блока close, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.close {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  padding: 0;
  background: transparent;
  user-select: none;
  cursor: pointer;

  span {
    position: relative;
    display: block;
    width: 60%;
    height: $toggler-part-height;
    font-size: 0;
    color: transparent;
    background: transparent;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $toggler-part-height;
      background: $gray-dark;
      transform-origin: 50% 50%;
    }

    &:before {
      transform: rotate3d(0, 0, 1, 45deg);
    }

    &:after {
      transform: rotate3d(0, 0, 1, -45deg);
    }
  }
}
