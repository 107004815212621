// В этом файле должны быть стили для БЭМ-блока text-picture, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.text-picture {

  $block-name:                &; // #{$block-name}__element
  padding-bottom: 24px;
  background-color: $body-bg;
  background-image: url(../img/mt-bg.svg);
  background-repeat: no-repeat;
  background-size: 269px 326px;
  background-position: 71% 41%;

  @media (min-width: $screen-xl) {
    padding-bottom: 36px;
    background-size: 322px 390px;
    background-position: 60% 100%;
  }

  @media (min-width: $screen-xxl) {
    padding-bottom: 114px;
  }

  &__column {
    &:nth-child(1) {
      @media (min-width: $screen-lg) {
        width: 58.5%;
        margin-right: 2.6%;
      }

      @media (min-width: $screen-xl) {
        width: 46.3%;
        margin-right: 2.5%;
      }

      @media (min-width: $screen-xxl) {
        width: 49%;
        margin-right: 1.9%;
      }
    }

    &:nth-child(2) {
      display: none;

      @media (min-width: $screen-lg) {
        display: block;
        width: 38.9%;
      }

      @media (min-width: $screen-xl) {
        width: 48.8%;
      }

      @media (min-width: $screen-xxl) {
        width: 49.1%;
      }
    }
  }

  &__inner, .inner {
    @media (min-width: $screen-lg) {
      padding-left: 6%;
      padding-right: 6%;
    }

    @media (min-width: $screen-xxl) {
      padding-left: 6.2%;
      padding-right: 6.2%;
    }
  }

  h2 {
    margin-bottom: 20px;
    padding-left: 12px;
    font-size: 38px;
    line-height: 1;
    color: $color-black-text;

    @media (min-width: $screen-lg) {
      margin-bottom: 32px;
      padding-left: 20px;
      font-size: 52px;
    }

    @media (min-width: $screen-xl) {
      padding-left: 24px;
    }

    @media (min-width: $screen-xxl) {
      margin-bottom: 24px;
      font-size: 64px;
    }
  }

  &__body {
    padding-left: 12px;

    @media (min-width: $screen-lg) {
      padding-left: 20px;
    }

    @media (min-width: $screen-xl) {
      padding-left: 24px;
    }
  }

  .subtitle {
    display: flex;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    color: $color-black-text;

    &:before {
      content: "";
      margin-right: 8px;
      display: flex;
      width: 4px;
      background: $color-red;

      @media (min-width: $screen-lg) {
        margin-right: 16px;
      }

      @media (min-width: $screen-xl) {
        margin-right: 20px;
      }
    }

    @media (min-width: $screen-lg) {
      font-size: 20px;
      line-height: 26px;
    }

    span {
      width: 95.8%;
    }
  }

  p {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    color: $color-black-light-text;
  }

  .subtitle, p {
    @media (min-width: $screen-md) {
      max-width: 530px;
    }

    @media (min-width: $screen-lg) {
      max-width: 100%;
    }
  }

  &__link {
    display: flex;
    align-items: end;
    margin-top: 32px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: $color-black-text;
    text-decoration: none;

    &:after {
      content: "";
      display: flex;
      width: 24px;
      height: 24px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px;
    }
  }
}
