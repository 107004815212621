// В этом файле должны быть стили для БЭМ-блока m-header, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.m-header {

  $block-name:                &; // #{$block-name}__element
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: $white;
  box-shadow: 13px 12px 40px rgba(0, 0, 0, .07);

  @media (min-width: $screen-lg) {
    display: none;
  }

  &-top {
    &-list {
      display: none;
    }
    &__inner {
      border-bottom: 1px solid rgba(43, 37, 29, .1);
    }
    
    &__clinic {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      cursor: pointer;
      color: $color-black-text;

      &:after {
        display: flex;
        content: '';
        background-image: url(../img/arrow-down.svg);
        width: 24px;
        height: 24px;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        @media (min-width: $screen-lg) {
          display: none;
        }
      }

      &--active {
        &:after {
          background-image: url(../img/arrow-up.svg);
        }
      }
    }

    &__item {
      margin-bottom: 8px;
      display: block;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-gray-text;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-list {
      padding: 16px 0;
    }    
  }

  &-body {
    padding-top: 12px;
    padding-bottom: 12px;

    &__inner {
      align-items: center;
      justify-content: space-between;
    }
  }

  &-burger {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/m-burger.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    &--active {
      background-image: url(../img/header/m-burger-active.svg);
    }
  }

  &-phone {
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(../img/header/phone.svg);
    background-size: 32px 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &-logo {
    img {
      width: 80px;
      height: 24px;
    }
  }

  .m-nav {
    display: none;
    overflow-y: auto;

    &-services {
      margin-bottom: 8px;
      &__list {
        display: none;
      }

      &__title {
        padding-top: 12px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(43, 37, 29, .1);
        font-weight: 500;
        font-size: 15px;
        line-height: 1;
        cursor: pointer;
        color: $color-black-text;
        text-decoration: none;

        &:first-child {
          border-top: 1px solid rgba(43, 37, 29, .1);
        }

        &:after {
          display: flex;
          content: '';
          background-image: url(../img/arrow-down.svg);
          width: 24px;
          height: 24px;
          background-position: 50% 50%;
          background-repeat: no-repeat;

          @media (min-width: $screen-lg) {
            display: none;
          }
        }

        &--active {
          &:after {
            background-image: url(../img/arrow-up.svg);
          }
        }
      }

      li {
        list-style: none;
      }

      &__link {
        display: block;
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color-black-text;
        text-decoration: none;

        &--sale {
          margin-top: 8px;
          display: flex;
          align-items: center;
          font-size: 15px;
          &:before {
            display: flex;
            content: "";
            margin-right: 8px;
            background-image: url(../img/header/i-sale.svg);
            background-size: 10px 11px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            flex-basis: 10px;
            width: 10px;
            height: 11px;
          }
        }
      }

      &__list {
        padding-top: 8px;
        padding-bottom: 20px;
      }

      ul {
        padding-left: 0;
        margin-bottom: 0;
      }
    }

    &-menu {
      padding-top: 32px;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(43, 37, 29, 0.1);

      ul {
        padding-left: 0;
        margin-bottom: 0;
      }

      li {
        display: block;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__link {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: $color-black-text;
        text-decoration: none;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;

      &__column {
        &:nth-child(1) {
          display: flex;
        }
      }

      .m-nav-search {
        display: block;
        margin-right: 12px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background-image: url(../img/header/i-search.svg);
        background-size: 32px 32px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }

      .m-nav-version {
        display: block;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background-image: url(../img/header/i-vision.svg);
        background-size: 32px 32px;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        @media (min-width: $screen-lg) {
          display: none;
        }
      }

      .m-nav-phone {
        font-size: 15px;
        font-weight: bold;
        line-height: 1;
        color: $color-black-text;
        text-decoration: none;
      }
    }
  }
}
