// В этом файле должны быть стили для БЭМ-блока order, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.order {

  $block-name:                &; // #{$block-name}__element
  background-color: $color-khaki;
  color: $white;
  background-image: url(../img/order-bg.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 320px 273px;

  @media (min-width: $screen-lg) {
    background-color: transparent;
    background-image: none;
  }

  @media (min-width: $screen-xxl) {
    margin: auto;
    padding-left: 48px;
    width: 1520px;
  }

  &__inner {
    padding-top: 64px;
    padding-bottom: 72px;

    @media (min-width: $screen-md) {
      display: flex;
    }

    @media (min-width: $screen-lg) {
      max-width: 97.5%;
      margin: 0 0 0 2.5%;
      padding-left: 8%;
      padding-right: 10.6%;
      background-color: $color-khaki;
      background-image: url(../img/order-bg-max.svg);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 639px 372px;
    }

    @media (min-width: $screen-xl) {
      flex-direction: column;
      padding-bottom: 80px;
      max-width: 96.9%;
      margin: 0 0 0 3.1%;
      padding-left: 7.9%;
      padding-right: 11.1%;
    }

    @media (min-width: $screen-xxl) {
      padding-bottom: 88px;
      max-width: 1472px;
      width: 1472px;
      margin: 0; 
    }
  }

  &__column {
    @media (min-width: $screen-md) {
      width: 48.4%;
    }

    &:nth-child(1) {
      @media (min-width: $screen-md) {
        margin-right: 3.2%;
      }

      @media (min-width: $screen-lg) {
        margin-bottom: 36px;
        width: 50%;
        max-width: 589px;
        margin-right: 10%;
      }

      @media (min-width: $screen-xl) {
        width: 100%;
        margin-right: 0;
      }

      @media (min-width: $screen-xxl) {
        max-width: 700px;
        margin-bottom: 44px;
      }
    }

    &:nth-child(2) {
      @media (min-width: $screen-md) {
        padding-top: 44px;
      }
      @media (min-width: $screen-lg) {
        padding-top: 55px;
        width: 39%;
      }
      @media (min-width: $screen-xl) {
        padding-top: 0;
        width: 100%;
      }
    }
  }

  &__sale {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 87px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    line-height: 0;
    background-color: $color-khaki-dark;
    color: $color-red;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    @media (min-width: $screen-lg) {
      margin-bottom: 28px;
    }
  }

  &__title {
    font-family: $font-family--heading-sans;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;
    color: $white;

    @media (min-width: ($screen-lg)) {
      margin-bottom: 16px;
      font-size: 52px;
      line-height: 1;
    }

    @media (min-width: ($screen-xxl)) {
      margin-bottom: 12px;
      font-size: 64px;
      line-height: 1; 
    }
  }

  .subtitle {
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: $white;

    @media (min-width: $screen-md) {
      margin-bottom: 0;
    }

    @media (min-width: ($screen-lg)) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (min-width: ($screen-xxl)) {
      max-width: 579px;
    }
  }

  @-webkit-keyframes autofill {
    to {
      color: inherit;
      background: transparent !important;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  &-form {
    &__inner {
      @media (min-width: $screen-xl) {
        display: flex;
        align-items: baseline;
        margin-bottom: 24px;
      }
    }

    &__column {
      @media (min-width: $screen-xl) {
        flex-basis: 260px;
        width: 260px;
      }

      @media (min-width: $screen-xxl) {
        flex-basis: 320px;
        width: 320px;
      }
    }
    &__group {
      position: relative;
      margin-bottom: 28px;

      @media (min-width: $screen-xl) {
        margin-bottom: 0;
        margin-right: 24px;
      }

      label {
        position:absolute;
        pointer-events:none;
        left: 0;
        bottom: 12px;
        transition:0.2s ease all;
        font-weight: 500;
        font-size: 18px;
        line-height: 1;
        color: $color-background;
      }
    }

    &__buttons {
      width: 100%;
      margin-bottom: 20px;
      padding-top: 18px;

      @media (min-width: $screen-xl) {
        margin-bottom: 0;
        padding-top: 0;
      }
    }

    &__button {
      display: block;
      margin: auto;
      width: 280px;
      height: 44px;
      border: none;
      color: $white;

      @media (min-width: $screen-md) {
        margin: 0;
        width: 294px;
      }

      @media (min-width: $screen-xl) {
        width: 128px;
      }
    }

    &__info {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-background;
    }

    &__input-text {
      display: block;
      font-family: $font-family;
      width: 100%;
      padding: 12px;
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: $color-background;
      border: 0;
      border-bottom: 2px solid hsla(0, 0%, 100%, .7);

      &:focus {
        outline: none;
      }
    }

    &__input-text:focus ~ label, &__input-text:valid ~ label {
      color: $color-background;
      font-size: 12px;
      top:-5px;
      span {
        color: $color-background;
      }
    }
  }
}
