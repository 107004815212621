// В этом файле должны быть стили для БЭМ-блока breadcrumbs, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.breadcrumbs {

  $block-name:                &; // #{$block-name}__element
  display: flex;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    list-style: none;

    &:after {
      content: "";
      display: flex;
      width: 4px;
      height: 4px;
      margin-right: 8px;
      margin-left: 8px;
      background-image: url(../img/bc-white.svg);
      background-repeat: no-repeat;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      text-decoration: none;
      color: $white;
    }

    span {
      color: hsla(0, 0%, 100%, 0.7);
    }
  }

  &--black {

    .breadcrumbs__item {
      &:after {
        background-image: url(../img/bc-black.svg);
      }
    }
    a {
      color: $color-black-text;
    }

    span {
      color: $color-gray-text;
    }
  }
}
