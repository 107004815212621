// В этом файле должны быть стили для БЭМ-блока promotions-slider, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.promotions-slider {

  $block-name:                &; // #{$block-name}__element

  @media (min-width: ($screen-xxl)) {
    max-width: 1520px;
    margin: auto;
  }

  .promotion {
    display: block;
    width: 280px;

    @media (min-width: ($screen-md)) {
      width: 294px;
    }

    @media (min-width: ($screen-lg)) {
      width: 367px;
    }

    @media (min-width: ($screen-xl)) {
      width: 486px;
    }

    @media (min-width: ($screen-xxl)) {
      width: 579px;
    }
  }

  .promotions-arrows.section-arrows {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    @media (min-width: ($screen-md)) {
      margin-top: 32px;
    }

    @media (min-width: ($screen-lg)) {
      display: none;
    }
  }

  &__inner {
    max-width: $container-sm;
    margin: auto;

    @media (min-width: ($screen-md)) {
      max-width: $container-md;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 89.4%;
      margin: 0 0 0 10.6%;
    }

    @media (min-width: $screen-xl) {
      max-width: 89.4%;
      margin: 0 0 0 10.6%;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1472px;
      margin: 0 0 0 142px; 
    }
  }

  &__wrapper {
    width: 100%;
    @media (min-width: ($screen-lg)) {
      padding-right: 11.9%;
    }

    @media (min-width: $screen-xl) {
      padding-right: 12.4%;
    }

    @media (min-width: $screen-xxl) {
      padding-right: 169px;
    }
  }

  .section-header {
    @media (min-width: $screen-xl) {
      margin-bottom: 32px;
    }
    @media (min-width: $screen-xxl) {
      margin-bottom: 40px;
    }
  }

  .p-slider {
    &__item {
      margin-right: 20px;

      @media (min-width: $screen-xl) {
        margin-right: 24px;
      }
    }
  }
}
