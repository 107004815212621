// В этом файле должны быть стили для БЭМ-блока reviews-slider, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.reviews-slider {

  $block-name:                &; // #{$block-name}__element

  @media (min-width: ($screen-xxl)) {
    max-width: 1520px;
    margin: auto;
  }


  &__inner {
    max-width: $container-sm;
    margin: auto;

    @media (min-width: ($screen-md)) {
      max-width: $container-md;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 89.4%;
      margin: 0 0 0 10.6%;
    }

    @media (min-width: $screen-xl) {
      max-width: 89.4%;
      margin: 0 0 0 10.6%;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1472px;
      margin: 0 0 0 142px; 
    }
  }

  &__wrapper {
    width: 100%;
    @media (min-width: ($screen-lg)) {
      padding-right: 11.9%;
    }

    @media (min-width: $screen-xl) {
      padding-right: 12.4%;
    }

    @media (min-width: $screen-xxl) {
      padding-right: 169px;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    padding-left: 0;

    @media (min-width: $screen-lg) {
      justify-content: flex-start;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 44px;
      width: 42px;
      height: 42px;
      border: 2px solid transparent;
      border-radius: 50%;
      a {
        font-size: 15px;
        line-height: 1;
        text-decoration: none;
        color: $color-gray-text;
      }
    }

    .slick-active {
      border: 2px solid $color-red;
      a {
        color: $color-black-text;
      }
    }
  } 
}
