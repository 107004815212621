@mixin inner-flex {
  display: flex;
  flex-wrap: wrap;
  max-width: $container-sm;
  margin: auto;

  @media (min-width: ($screen-md)) {
    max-width: $container-md;
  }

  @media (min-width: ($screen-lg)) {
    max-width: $container-lg;
  }

  @media (min-width: ($screen-xl)) {
    max-width: $container-xl;
  }

  @media (min-width: ($screen-xxl)) {
    max-width: $container-xxl;
  }
}

@mixin inner {
  max-width: $container-sm;
  margin: auto;

  @media (min-width: ($screen-md)) {
    max-width: $container-md;
  }

  @media (min-width: ($screen-lg)) {
    max-width: $container-lg;
  }

  @media (min-width: ($screen-xl)) {
    max-width: $container-xl;
  }

  @media (min-width: ($screen-xxl)) {
    max-width: $container-xxl;
  }
}