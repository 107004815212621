// В этом файле должны быть стили для БЭМ-блока documents, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.documents {

  $block-name:                &; // #{$block-name}__element
  background-color: #fff;

  .inner {
    @media (min-width: $screen-md) {
      max-width: 608px;
    }

    @media (min-width: ($screen-lg)) {
      min-width: 754px;
    }

    @media (min-width: $screen-xl) {
      max-width: 996px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1182px;
    }
  }

  h2 {
    margin-bottom: 32px;
    font-size: 38px;
    line-height: 1;
    color: $color-black-text;

    @media (min-width: $screen-md) {
      max-width: 530px
    }

    @media (min-width: $screen-lg) {
      font-size: 52px;
      max-width: 600px
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 40px;
      max-width: 100%;
    }

    @media (min-width: $screen-xxl) {
      font-size: 64px;
      max-width: 820px;
    }
  }

  &__list {
    padding-left: 0;
    margin-bottom: 32px;
    list-style: none;

    @media (min-width: $screen-lg) {
      margin-bottom: 40px;
    }
  }

  &__item {
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid $color-black-text-tr;
    border-bottom: 1px solid $color-black-text-tr;

    @media (min-width: $screen-lg) {
      max-width: 754px;
    }

    @media (min-width: $screen-xl) {
      max-width: 100%;
    }
  }

  &__desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $color-black-text;

    @media (min-width: $screen-md) {
      max-width: 450px;
    }

    @media (min-width: $screen-lg) {
      max-width: 574px;
    }

    @media (min-width: $screen-xl) {
      max-width: 792px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 940px;
    }
  }

  &__link {
    display: flex;
    text-decoration: none;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 58px;
    min-width: 58px;
    height: 64px;
    margin-right: 16px;
    background-color: $color-khaki;

    @media (min-width: $screen-md) {
      margin-right: 22px;
    }

    @media (min-width: $screen-lg) {
      flex-basis: 78px;
      width: 78px;
      height: 88px;
      margin-right: 24px;
    }

    @media (min-width: $screen-xxl) {
      flex-basis: 96px;
      width: 96px;
      height: 108px;
    }

    img {
      width: 34px;
      height: 14px;

      @media (min-width: $screen-lg) {
        width: 46px;
        height: 18px;
      }

      @media (min-width: $screen-xxl) {
        width: 57px;
        height: 22px;
      }
    }
  }

  .a-btn {
    height: 44px;

    @media (min-width: $screen-md) {
      width: 139px;
      margin: auto;
    }
  }
}
