// В этом файле должны быть стили для БЭМ-блока service, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.service {

  $block-name:                &; // #{$block-name}__element
  display: block;
  width: 206px;
  padding: 16px;
  background-color: $color-khaki-light;
  box-shadow: 13px 12px 40px rgba(0, 0, 0, 0.07);
  background-image: url(../img/service-bg.svg);
  background-size: 132px 184px;
  background-repeat: no-repeat;
  background-position: 100% 0;
  text-decoration: none;

  @media (min-width: $screen-md) {
    width: 216px;
  }

  @media (min-width: $screen-lg) {
    width: 212px;
  }

  @media (min-width: $screen-xl) {
    padding: 32px;
    width: 282px;
    background-image: url(../img/service-bg-max.svg);
    background-size: 218px 232px;
  }

  @media (min-width: $screen-xxl) {
    width: 338px;
  }

  &__title {
    margin-bottom: 4px;
    min-height: 47px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-red;
    hyphens: auto;

    @media (min-width: $screen-lg) {
      margin-bottom: 6px;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 8px;
    }

    @media (min-width: $screen-xxl) {
      min-height: 50px;
      font-weight: 500;
      line-height: 1;
      font-size: 26px;
    }
  }

  &__count {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: $color-gray-text;
    min-height: 18px;
  }

  &__header {
    margin-bottom: 70px;

    @media (min-width: $screen-md) {
      margin-bottom: 79px;
    }

    @media (min-width: $screen-lg) {
      margin-bottom: 73px;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 118px;
    }

    @media (min-width: $screen-xxl) {
      margin-bottom: 162px;
    }
  }

  &__link {
    display: flex;
    align-items: end;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: $color-black-text;
    text-decoration: none;

    @media (min-width: $screen-xxl) {
      font-size: 16px;
    }

    &:after {
      content: "";
      display: flex;
      width: 24px;
      height: 18px;
      margin-left: 12px;
      background-image: url(../img/link.svg);
      background-repeat: no-repeat;
      background-size: 24px 18px;
    }
  }
}
