// В этом файле должны быть стили для БЭМ-блока inner, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.inner {

  $block-name:                &; // #{$block-name}__element
  @include inner;
  margin: 0 auto;

  &-flex {
    @include inner-flex;   
  }

  &-page {
    padding-top: 81px;

    @media (min-width: $screen-lg) {
      padding-top: 81.65px;
    }
  }

  &-section {
    margin-bottom: 56px;

    @media (min-width: $screen-lg) {
      margin-bottom: 64px;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 80px;
    }

    @media (min-width: $screen-xxl) {
      margin-bottom: 88px;
    }
  }
}
