// В этом файле должны быть стили для БЭМ-блока contact, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.contact {

  $block-name:                &; // #{$block-name}__element

  @media (min-width: ($screen-xxl)) {
    max-width: 1560px;
    margin: auto;
  }

  .inner-breadcrumbs {
    @media (min-width: ($screen-xxl)) {
      padding-left: 169px;
    }
  }

  &__inner {
    @media (min-width: ($screen-xl)) {
      display: flex;
    }

    @media (min-width: ($screen-xxl)) {
      margin: 0;
      max-width: 100%;
    }
  }

  .breadcrumbs {
    margin-bottom: 56px;

    @media (min-width: ($screen-md)) {
      margin-bottom: 38px;
    }

    @media (min-width: ($screen-xl)) {
      margin-bottom: 68px;
    }

    @media (min-width: ($screen-xxl)) {
      margin-bottom: 78px;
    }
  }

  h1 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 44px;
    line-height: 44px;

    @media (min-width: ($screen-lg)) {
      max-width: 367px;
      font-size: 72px;
      line-height: 66px;
    }

    @media (min-width: ($screen-xl)) {
      max-width: 487px;
    }

    @media (min-width: ($screen-xxl)) {
      max-width: 580px;
      font-size: 80px;
      line-height: 74px;
    }
  }

  .page__title {
    position: relative;
    margin-bottom: 32px;
  }

  &__address {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $color-black-light-text;

    @media (min-width: $screen-lg) {
      font-size: 24px;
    }
  }

  &__time {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: $color-gray-text;
  }

  .map {
    width: 100%;
    height: 320px;
    background-image: url(../img/map-sm.png);
    background-size: contain;
    background-repeat: no-repeat;

    @media (min-width: $screen-md) {
      background-image: url(../img/map-md.png);
      height: 416px;
    }

    @media (min-width: $screen-lg) {
      background-image: url(../img/map-lg.png);
      height: 536px;
    }

    @media (min-width: $screen-xl) {
      background-image: url(../img/map-xl.png);
      height: 645px;
      min-width: 730px;
    }

    @media (min-width: $screen-xl) {
      background-image: url(../img/map-xxl.png);
      height: 645px;
    }

    &--ametist {
      background-image: url(../img/ametist/map-a-sm.png);

      @media (min-width: $screen-md) {
        background-image: url(../img/ametist/map-a-md.png);
      }

      @media (min-width: $screen-lg) {
        background-image: url(../img/ametist/map-a-lg.png);
      }

      @media (min-width: $screen-xl) {
        background-image: url(../img/ametist/map-a-xl.png);
      }

      @media (min-width: $screen-xl) {
        background-image: url(../img/ametist/map-a-xxl.png);
      }
    }

    &--rubin {
      background-image: url(../img/map-r-sm.png);

      @media (min-width: $screen-md) {
        background-image: url(../img/map-r-md.png);
      }

      @media (min-width: $screen-lg) {
        background-image: url(../img/map-r-lg.png);
      }

      @media (min-width: $screen-xl) {
        background-image: url(../img/map-r-xl.png);
      }

      @media (min-width: $screen-xl) {
        background-image: url(../img/map-r-xxl.png);
      }
    }
  }

  &__column {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .contact-list {
    border-bottom: 1px solid hsla(34, 19%, 14%, .1);

    @media (min-width: $screen-lg) {
      display: flex;
    }

    @media (min-width: $screen-xl) {
      flex-direction: column;
    }
    &__column {
      padding-bottom: 32px;

      @media (min-width: ($screen-lg)) {
        padding-bottom: 40px;
      }

      &:nth-child(1) {
        margin-right: 97px;
      }
    }
  }

  &__email {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: $color-gray-text;
    text-decoration: none;
  }

  &__phone {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $color-black-light-text;
    text-decoration: none;

    @media (min-width: $screen-lg) {
      font-size: 24px;
    }

    &-wrapper {
      display: flex;
      align-items: baseline;
      margin-bottom: 12px;
    }
  }

  &__back {
    display: none;
    @media (min-width: ($screen-lg)) {
      display: block;
      position: absolute;
      top: 21%;
      left: -9%;
      width: 22px;
      height: 15px;
      background-image: url(../img/back.svg);
      background-size: 22px 15px;
      background-repeat: no-repeat;
    }
  }

  &__wrapper {
    @media (min-width: ($screen-lg)) {
      padding-left: 8.4%;
    }
    @media (min-width: ($screen-xl)) {
      padding-right: 102px;
    }
    @media (min-width: ($screen-xxl)) {
      padding-left: 169px;
    }
  }

  .qr {
    padding-top: 32px;

    @media (min-width: ($screen-lg)) {
      padding-top: 40px;
    }
  }

  &__whatsapp {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    background-image: url(../img/w.svg);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    margin-left: 22px;

    &:before {
      content: '';
      display: flex;
      width: 1px;
      height: 15px;
      margin-left: 12px;
      margin-right: 12px;
      background-color: hsla(34, 19%, 14%, .1);
    }
  }
}
