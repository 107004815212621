// В этом файле должны быть стили для БЭМ-блока doctors-slider, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.doctors-slider {

  $block-name:                &; // #{$block-name}__element

  @media (min-width: ($screen-xxl)) {
    max-width: 1520px;
    margin: auto;
  }

  

  &__inner {
    max-width: $container-sm;
    margin: auto;

    @media (min-width: ($screen-md)) {
      max-width: $container-md;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 89.4%;
      margin: 0 0 0 10.6%;
    }

    @media (min-width: $screen-xl) {
      max-width: 89.4%;
      margin: 0 0 0 10.6%;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1472px;
      margin: 0 0 0 142px; 
    }
  }

  &__wrapper {
    width: 100%;
    @media (min-width: ($screen-lg)) {
      padding-right: 11.9%;
    }

    @media (min-width: $screen-xl) {
      padding-right: 12.4%;
    }

    @media (min-width: $screen-xxl) {
      padding-right: 169px;
    }
  }

  .slick-slide {
    margin-right: 16px;
    width: 206px;

    @media (min-width: $screen-md) {
      margin-right: 24px;
      width: 216px; 
    }

    @media (min-width: $screen-lg) {
      width: 212px; 
    }

    @media (min-width: $screen-xl) {
      width: 282px; 
    }

    @media (min-width: $screen-xxl) {
      width: 338px; 
    }
  }
}
