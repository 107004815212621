// В этом файле должны быть стили для БЭМ-блока page-footer, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.page-footer {

  $block-name:                &; // #{$block-name}__element
  padding-top: 40px;
  background-color: $color-background;
  background-image: url(../img/footer-bg.svg);
  background-size: 120px 116px;
  background-position: 100% 100%;
  background-repeat: no-repeat;

  @media (min-width: $screen-lg) {
    background-size: 178px 174px;
  }

  @media (min-width: $screen-xxl) {
    padding-top: 32px;
  }

  &__header {
    padding-bottom: 24px;

    @media (min-width: $screen-md) {
      padding-bottom: 32px;
    }

    @media (min-width: $screen-xl) {
      padding-bottom: 30px;
    }
  }

  .p-f-header {

    &__column {
      width: 100%;

      &:nth-child(1) {
        @media (min-width: $screen-md) {
          width: 48.3%;
          margin-right: 3.4%;
        }

        @media (min-width: $screen-lg) {
          width: 43.1%;
          margin-right: 7.9%;
        }

        @media (min-width: $screen-xl) {
          width: 40.5%;
          margin-right: 2%;
        }

        @media (min-width: $screen-xxl) {
          width: 33.9%;
          margin-right: 8.4%;
        }
      }

      &:nth-child(2) {
        @media (min-width: $screen-md) {
          width: 30.2%;
          margin-right: 3.4%;
        }

        @media (min-width: $screen-lg) {
          width: 25.5%;
          margin-right: 10.1%;
        }

        @media (min-width: $screen-xl) {
          width: 40.5%;
          margin-right: 2%;
        }

        @media (min-width: $screen-xxl) {
          width: 32.2%;
          margin-right: 10.1%;
        }
      }

      &:nth-child(3) {
        margin-bottom: 24px;
        @media (min-width: $screen-md) {
          display: flex;
          justify-content: center;
          order: 4;
          width: 100%;
          margin-bottom: 0;
          padding-top: 32px;
        }

        @media (min-width: $screen-lg) {
          display: none;
        }
      }

      &:nth-child(4) {
        @media (min-width: $screen-md) {
          width: 14.7%;
          order: 3;
        }

        @media (min-width: $screen-lg) {
          width: 13.4%;
        }

        @media (min-width: $screen-xl) {
          width: 15%;
        }

        @media (min-width: $screen-xxl) {
          width: 15.2%;
        }
      }
    }
  }

  .p-f-body {
    margin-bottom: 32px;

    @media (min-width: $screen-lg) {
      margin-bottom: 20px;
      padding: 40px 0;
      border-top: 1px solid rgba(43, 37, 29, 0.1);
      border-bottom: 1px solid rgba(43, 37, 29, 0.1);
    }

    &__column {
      width: 100%;
      border-bottom: 1px solid rgba(43, 37, 29, .1);

      @media (min-width: $screen-lg) {
        border-bottom: 0;
      }

      &:nth-child(1) {
        border-top: 1px solid rgba(43, 37, 29, .1);
        @media (min-width: $screen-lg) {
          width: 41.9%;
          margin-right: 9%;
          border-top: 0;
        }

        @media (min-width: $screen-xl) {
          width: 34%;
          margin-right: 8.5%;
        }

        @media (min-width: $screen-xxl) {
          width: 36.2%;
          margin-right: 6%;
        }
      }

      &:nth-child(2) {
        @media (min-width: $screen-lg) {
          width: 31.8%;
          margin-right: 4.3%;
          border-top: 0;
        }

        @media (min-width: $screen-xl) {
          width: 23.5%;
          margin-right: 13.5%;
        }

        @media (min-width: $screen-xxl) {
          width: 33.9%;
          margin-right: 6.3%;
        }
      }

      &:nth-child(3) {
        display: none;

        @media (min-width: $screen-lg) {
          display: block;
          width: 13%;
        }

        @media (min-width: $screen-xl) {
          width: 20.5%;
        }

        @media (min-width: $screen-xxl) {
          width: 17.6%;
        }
      }
    }
  }


  &__address {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: $color-black-light-text;

    @media (min-width: $screen-md) {
      text-align: left;
    }

    @media (min-width: $screen-lg) {
      margin-bottom: 4px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__time {
    margin-bottom: 24px;
    font-size: $font-size-p3;
    font-weight: 500;
    line-height: $line-height-p3;
    text-align: center;
    color: $color-gray-text;

    @media (min-width: $screen-md) {
      text-align: left;
      margin-bottom: 0;
    }
  }

  &__phone {
    display: block;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    color: $color-black-light-text;

    @media (min-width: $screen-md) {
      text-align: left;
    }

    @media (min-width: $screen-lg) {
      margin-bottom: 4px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  .a-btn {
    margin: auto;
    width: 280px;

    @media (min-width: $screen-md) {
      width: 294px;
      margin: 0;
    }
  }

  .btn-callback {
    margin: 0 auto 16px;
    @media (min-width: $screen-md) {
      margin: 0 3.4% 0 0;
    }
  }

  .footer-menu {
    display: none;
    padding-bottom: 24px;

    @media (min-width: $screen-lg) {
      display: flex;
    }

    ul {
      margin-bottom: 0;
      padding-left: 0;

      @media (min-width: $screen-lg) {
        margin-right: 24px;
      }

      @media (min-width: $screen-xl) {
        margin-right: 42px;
      }

      @media (min-width: $screen-xxl) {
        margin-right: 79px;
      }
    }

    li {
      padding-bottom: 12px;
      list-style: none;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: $font-size-caps;
      font-weight: 700;
      line-height: $line-height-caps;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      cursor: pointer;
      color: $color-black-light-text;

      @media (min-width: $screen-lg) {
        padding-top: 0;
        padding-bottom: 20px;
        color: $color-gray-text;
      }

      &:after {
        display: flex;
        content: '';
        background-image: url(../img/arrow-down.svg);
        width: 24px;
        height: 24px;
        background-size: 24px;
        background-position: 50%;

        @media (min-width: $screen-lg) {
          display: none;
        }
      }

      &--active {
        &:after {
          background-image: url(../img/arrow-up.svg);
        }
      }
    }

    &__link {
      display: block;
      font-size: $font-size-nav2;
      font-weight: 500;
      line-height: $line-height-nav2;
      color: $color-black-light-text;
      text-decoration: none;

      &:hover, &:focus {
        color: hsla(240, 1%, 55%, 1);
      }

      @media (min-width: $screen-xxl) {
        font-size: 15px;
        line-height: 1;
      }
    }
  }

  .footer-copy {
    &__inner {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: $screen-lg) {
        flex-direction: row;
        order: 2;
        width: auto;
      }
    }
    &__link {
      display: block;
      padding-bottom: 12px;
      font-size: $font-size-p3;
      font-weight: 500;
      line-height: $line-height-p3;
      color: $color-gray-text;
      text-decoration: none;

      &:hover, &:focus {
        color: hsla(240, 1%, 55%, 1);
      }

      @media (min-width: $screen-lg) {
        margin-right: 16px;
      }

      &:nth-child(1) {
        @media (min-width: $screen-lg) {
          order: 2;
        }
      }

      &:nth-child(2) {
        @media (min-width: $screen-lg) {
          order: 1;
        }
      }
    }

    &__text {
      @media (min-width: $screen-lg) {
        margin-right: 16px;
        order: 1;
      }
      p {
        display: block;
        font-size: $font-size-p3;
        font-weight: 500;
        line-height: $line-height-p3;
        color: $color-gray-text;
      }
    }
  }

  .link-callback {
    display: none;
    padding-bottom: 3px;
    border-bottom: 1px solid $color-gray-text;
    font-size: $font-size-p3;
    line-height: $line-height-p3;
    color: $color-gray-text;
    text-decoration: none;

    
    @media (min-width: $screen-lg) {
      display: inline-block;
    }    
  }

  .footer-review {
    &__img {
      display: block;
      width: 126px;
      height: 112px;
      margin-bottom: 12px;
      background-image: url(../img/review-min.svg);
      background-size: 100%;
      background-repeat: no-repeat;

      @media (min-width: $screen-xl) {
        width: 248px;
        height: 44px;
        background-image: url(../img/review-max.svg);
      } 
    }

    &__link {
      display: inline-block;
      padding-bottom: 4px;
      border-bottom: 1px solid $color-black-light-text;
      font-size: $font-size-p2;
      font-weight: 500;
      line-height: $line-height-p2;
      color: $color-black-light-text;
      text-decoration: none;

      @media (min-width: $screen-xl) {
        margin-left: 55px;
      }

      @media (min-width: $screen-xxl) {
          font-size: 15px;
          line-height: 1;
      }
    }
  }
}
