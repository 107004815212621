// В этом файле должны быть стили для БЭМ-блока services, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.services {

  $block-name:                &; // #{$block-name}__element
  background-color: $color-khaki;

  @media (min-width: ($screen-lg)) {
    background-color: transparent;
  }

  @media (min-width: $screen-xxl) {
    margin: auto;
    padding-left: 48px;
    width: 1520px;
    max-width: 1520px;
  }

  .services-arrows.section-arrows {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    @media (min-width: ($screen-md)) {
      margin-top: 32px;
    }

    @media (min-width: ($screen-lg)) {
      display: none;
    }
  }

  &__inner {
    max-width: $container-sm;
    margin: auto;
    padding-top: 48px;
    padding-bottom: 48px;

    @media (min-width: ($screen-md)) {
      max-width: $container-md;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 100%;
      background-color: $color-khaki;
      max-width: 97.5%;
      margin: 0 0 0 2.5%;
      padding-left: 8%;
    }

    @media (min-width: $screen-xl) {
      max-width: 96.9%;
      margin: 0 0 0 3.1%;
      padding-left: 7.9%;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1472px;
      width: 1472px;
      margin: 0; 
    }
  }

  &__wrapper {
    width: 100%;
    @media (min-width: ($screen-lg)) {
      padding-right: 10.6%;
    }

    @media (min-width: $screen-xl) {
      padding-right: 11.1%;
    }
  }

  h2 {
    color: $white;
  }

  .section-header {
    @media (min-width: $screen-xl) {
      margin-bottom: 40px;
    }
    @media (min-width: $screen-xl) {
      margin-bottom: 48px;
    }
  }

  .service-slider {
    &__item {
      margin-right: 16px;

      @media (min-width: $screen-md) {
        margin-right: 20px;
      }

      @media (min-width: $screen-xl) {
        margin-right: 24px;
      }
    }
  }

  .service-list {
    display: flex;
    flex-wrap: wrap;

    .service {
      margin-bottom: 24px;
      @media (min-width: $screen-md) {
        width: 180px;
        margin-bottom: 0;
      }
      @media (min-width: $screen-lg) {
        width: 212px;
      }
      @media (min-width: $screen-xl) {
        width: 282px;
      }

      @media (min-width: $screen-xxl) {
        width: 338px;
      }
    }

    &__item {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      @media (min-width: $screen-md) {
        margin-right: 20px;
      }

      @media (min-width: $screen-xl) {
        margin-right: 24px;
      }
    }
  }
}
