// В этом файле должны быть стили для БЭМ-блока prices, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.prices {

  $block-name:                &; // #{$block-name}__element
  background-color: #fff;

  .inner {
    @media (min-width: $screen-md) {
      max-width: 608px;
    }

    @media (min-width: ($screen-lg)) {
      min-width: 754px;
    }

    @media (min-width: $screen-xl) {
      max-width: 996px;
    }

    @media (min-width: $screen-xxl) {
      max-width: 1182px;
    }
  }

  h2 {
    margin-bottom: 32px;
    font-size: 38px;
    line-height: 1;
    color: $color-black-text;

    @media (min-width: $screen-md) {
      max-width: 530px
    }

    @media (min-width: $screen-lg) {
      font-size: 52px;
      max-width: 600px
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 40px;
      max-width: 100%;
    }

    @media (min-width: $screen-xxl) {
      font-size: 64px;
      max-width: 820px;
    }
  }

  &__list {
    padding-left: 0;
    margin-bottom: 32px;
    list-style: none;

    @media (min-width: $screen-lg) {
      margin-bottom: 40px;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $color-black-text;

    @media (min-width: $screen-md) {
      max-width: 373px;
    }

    @media (min-width: $screen-lg) {
      max-width: 498px;
    }

    @media (min-width: $screen-xl) {
      max-width: 673px;
    }
  }

  &__value {
    display: flex;
    align-items: baseline;
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    color: $color-black-light-text;

    @media (min-width: $screen-lg) {
      font-size: 24px;
    }

    @media (min-width: $screen-xxl) {
      font-size: 30px;
    }

    span {
      margin-right: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      text-decoration-line: line-through;
      color: $color-gray-text;
    }
  }

  &__item {
    padding: 24px 16px;
    background-color: $white;
    position: relative;
    align-items: baseline;

    @media (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--head {
      display: none;
      padding-top: 0;
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 12px;

      .prices__title, .prices__value {
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $color-gray-text;
      }
    }

    &:nth-child(even) {
      background-color: $color-khaki-light;
    }
  }

  .tooltip {
    cursor: pointer;
    margin-left: 12px;

    &:hover {
      .tooltip__desc {
        visibility: visible;
        opacity: 1;
        width: 172px;
        padding: 16px;
        background-color: #fff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
        border: 1px solid #fff;

        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          border-width: 4px;
          border-style: solid;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
          border-color: #ffffff transparent transparent transparent;
        }
      }
    }

    &__icon {
      width: 20px;
      height: 26px;
      background-image: url(../img/tooltip.svg);
      background-size: 20px 26px;
      background-repeat: no-repeat;
    }

    &__desc {
      visibility: hidden;
      position: absolute;
      z-index: 1;
      bottom: 64px;
      right: 0;
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: $color-black-light-text;
      text-align: left;

      @media (min-width: ($screen-md)) {
        bottom: 44px;
      }

      @media (min-width: ($screen-lg)) {
        right: 0px;
        bottom: 72px;
      }
    }
  }

  .a-btn {
    height: 44px;

    @media (min-width: $screen-md) {
      width: 179px;
      margin: auto;
    }
  }
}
