// В этом файле должны быть стили для БЭМ-блока features, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.features {

  $block-name:                &; // #{$block-name}__element
  position: relative;

  @media (min-width: $screen-lg) {
    background: linear-gradient(to top, $white 40%, $white 40% 40%), linear-gradient(to right,$white 40%, $color-khaki 40% 100%);
    background-size: 100% 20%, 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 100%, 0 0;
  }

  @media (min-width: $screen-xxl) {
    max-width: 1520px;
    margin: auto;
    background: linear-gradient(to top, $white 40%, $white 40% 40%), linear-gradient(to right,$white 34%, $color-khaki 34% 100%);
    background-size: 100% 20%, 99.9% 99.9%;
    background-repeat: no-repeat;
    background-position: 0 100%, 0 0;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 1;
    color: $color-black-text;

    @media (min-width: $screen-lg) {
      font-size: 52px;
      max-width: 484px
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 40px;
      max-width: 100%;
    }

    @media (min-width: $screen-xxl) {
      font-size: 64px;
      max-width: 579px;
    }
  }

  &__inner {
    @media (min-width: $screen-lg) {
      display: flex;
      justify-content: center;
      position: relative;
    }
  }


  &__header {
    max-width: $container-sm;
    margin: auto;

    @media (min-width: ($screen-md)) {
      max-width: $container-md;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 78.9%;
    }
  }

  &__column {

    &:nth-child(1) {
      padding-bottom: 32px;

      @media (min-width: $screen-lg) {
        flex-basis: 212px;
        width: 212px;
        margin-right: 20px;
      }

      @media (min-width: $screen-xl) {
        flex-basis: 282px;
        width: 282px;
        margin-right: 24px;
      }

      @media (min-width: $screen-xxl) {
        flex-basis: 338px;
        width: 338px;
        margin-right: 24px;
      }
    }

    &:nth-child(2) {
      padding-top: 56px;
      background: linear-gradient(to bottom, $color-khaki 50%, $color-khaki-light 50% 100%);

      @media (min-width: ($screen-md)) {
        padding-top: 38px;
        background: linear-gradient(to bottom, $color-khaki 30%, $color-khaki-light 30% 100%);
      }

      @media (min-width: ($screen-lg)) {
        background: none;
        padding-left: 78px;
      }

      @media (min-width: ($screen-xl)) {
        padding-left: 120px;
      }
    }
  }

  &__wrapper {
    max-width: $container-sm;
    margin: auto;

    @media (min-width: ($screen-md)) {
      max-width: $container-md;
    }

    @media (min-width: ($screen-lg)) {
      max-width: 100%;
      margin: 0;
    }
  }

  .subtitle {
    display: flex;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    color: $color-black-text;

    &:before {
      content: "";
      margin-right: 8px;
      display: flex;
      width: 4px;
      background: $color-red;

      @media (min-width: $screen-lg) {
        margin-right: 16px;
      }

      @media (min-width: $screen-xl) {
        margin-right: 20px;
      }
    }

    @media (min-width: $screen-lg) {
      font-size: 20px;
      line-height: 26px;
    }

    span {
      width: 95.8%;
    }
  }

  p {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    color: $color-black-light-text;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $color-red;
  }

  &__desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-gray-text;
  }

  &__icon {
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
    background-image: url(../img/f-icon.svg);
    background-size: 48px 48px;

    @media (min-width: ($screen-xxl)) {
      margin-bottom: 32px;
    }
  }

  &__item {
    margin-bottom: 20px;
    width: 280px;
    padding: 32px;
    background-color: $color-khaki-light;

    @media (min-width: ($screen-md)) {
      width: 294px;
      margin-right: 20px;
    }

    @media (min-width: ($screen-lg)) {
      width: 212px;
      padding: 20px;
    }

    @media (min-width: ($screen-xl)) {
      width: 282px;
      padding: 32px;
    }

    @media (min-width: ($screen-xxl)) {
      width: 338px;
      padding: 40px;
    }
  }

  &-list {
    @media (min-width: ($screen-md)) {
      display: flex;
    }

    &__column {
      &:nth-child(2) {
        margin-top: 24px;
      }
    }
  }
}
