// В этом файле должны быть стили для БЭМ-блока section, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.section {

  $block-name:                &; // #{$block-name}__element

  &-title {
    display: flex;
    align-items: baseline;

    &--long {
      flex-direction: column;

      @media (min-width: $screen-md) {
        flex-direction: row;
      }

      .section-header__more {
        margin-left: 0;

        @media (min-width: $screen-md) {
          margin-left: 18px;
        }

        @media (min-width: $screen-lg) {
          margin-left: 24px;
        }
      }
    }

    h2 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 38px;
      line-height: 1;
      color: $color-black-text;

      @media (min-width: $screen-lg) {
        font-size: 52px;
      }

      @media (min-width: $screen-xxl) {
        font-size: 64px;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 32px;
    &__more {
      display: flex;
      align-items: end;
      margin-top: 32px;
      margin-left: 18px;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      color: $color-black-text;
      text-decoration: none;

      @media (min-width: $screen-lg) {
        margin-left: 24px;
      }

      &:after {
        content: "";
        display: flex;
        width: 24px;
        height: 24px;
        margin-left: 12px;
        background-image: url(../img/link.svg);
        background-repeat: no-repeat;
        background-size: 24px 18px;
      }
    }
  }

  &-arrows {
    display: none;

    @media (min-width: $screen-lg) {
      display: flex;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .section-arrow__prev {
      width: 22px;
      height: 15px;
      margin-right: 20px;
      background-image: url(../img/arrow-prev.svg);
      background-size: 22px 15px;
      background-repeat: no-repeat;
    }

    .section-arrow__next {
      width: 22px;
      height: 15px;
      background-image: url(../img/arrow-next.svg);
      background-size: 22px 15px;
      background-repeat: no-repeat;
    }
  }
}
