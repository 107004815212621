// В этом файле должны быть стили для БЭМ-блока search, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

.search {

  $block-name:                &; // #{$block-name}__element

  &-list {
    padding-left: 0;
    margin-bottom: 0;
    li {
      list-style: none;
      margin-bottom: 24px;
    }

    a {
      text-decoration: none;
    }

    &__title {
      font-weight: 700;
      line-height: 1;
      text-decoration: underline;
      padding-bottom: 14px;
    }
  }
}
